import {useContext, useEffect, useState } from 'react'
import './my_shop_payments.scss';

import SelectPayment from './SelectPayment/select_payment';
import CreditView from './Credits/credits';


export default function MyShopPayments() {
  const [paymentSelected, setPaymentSelected] = useState( "" )


  if( paymentSelected == "credits")
    return <CreditView handleSelect={setPaymentSelected}/>

  return <SelectPayment handleSelect={setPaymentSelected}/>

  
}

