import React from 'react'
import './circle_image.scss';

export default function CircleImage({size, borderColor, className, src}) {
  return (
    <div className={`rounded-img ${size} border-${borderColor} ${className}` }>
      <img src={src} className='img-fluid-h'></img>
    </div>
  );
}

