import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../auth/AuthContextProvider"
import { withRouter } from "react-router-dom"

import "./navprofile.scss"
import { Dropdown, Nav } from "react-bootstrap"
import { googleLogout } from "@react-oauth/google"

function NavProfile({ history }) {
  const authContext = useContext(AuthContext)

  const signOut = () => {
    authContext.setGoogleUser(undefined)
    localStorage.removeItem("token")
    googleLogout()
  }
  const [onAppPage, setOnAppPage] = useState(false)

  history.listen((location, action) => {
    setOnAppPage(window.location.pathname === "/calendar")
  })

  return (
    <div id="nav-profile">
      {!onAppPage && (
        <Nav.Link eventKey="1" as={Link} to="/calendar" id="my-apps">
          Mis turnos
        </Nav.Link>
      )}
      <Dropdown id="profile" className="ms-auto">
        <Dropdown.Toggle id="dropdownToggle">
          <img
            alt="menu"
            src={authContext.googleUser && authContext.googleUser.profile_pic}
            width="40"
            height="40"
            className="rounded-circle"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu id="dropdownMenuProfile" align="start">
          <Nav.Link eventKey="1" as={Link} to="/credits">
            Mis créditos
          </Nav.Link>
          <Nav.Link eventKey="1" as={Link} to="/notification-method">
            Cambiar método de contacto
          </Nav.Link>
          <Nav.Link eventKey="1" as={Link} to="/delete-account">
            Eliminar cuenta
          </Nav.Link>
          <Dropdown.Divider />
          <Dropdown.Item onClick={signOut}>Cerrar Sesión</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default withRouter(NavProfile)
