import React from 'react'
import './cites_table.scss';

//Boostrap
import Stack from 'react-bootstrap/Stack';

//Components

import CiteDetail from '../CiteDetail/cite_detail';

export default function CitesTable({className, children}) {
  return (
    <div className={`cites-table bg-gray rounded p-2  overflow-auto ${className}`}>
        <Stack gap={2}>
          {children}        
        </Stack>
      </div>
  );
}

