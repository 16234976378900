import { useRef, useEffect } from "react"
import { fetchGetShops } from "../../requests/requests"
import { useContext } from "react"
import { AuthContext } from "./AuthContextProvider"
import { PlaceContext } from "../my_commerce/PlaceContextProvider"
import { GetUserPlaces } from "../utils/swr_callbacks"
import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google"
import { NotificationContainer, NotificationManager } from "react-notifications"

export function GoogleAuth({ automaticSignIn, isLoading }) {
  const authContext = useContext(AuthContext)

  const placeContext = useContext(PlaceContext)

  return (
    <GoogleLogin
     
      theme="filled_black"
      shape="pill"
      locale="es"
      // useOneTap={automaticSignIn}
      onSuccess={({ credential }) => {
        localStorage.setItem("token", credential)
        signInBackend(credential, authContext, placeContext, isLoading)
      }}
      onerror={() => {
        console.error("No se pudo iniciar sesión")
      }}
    />
  )
}

export function signInBackend(
  credential,
  authContext,
  placeContext,
  isLoading
) {
  try {
    fetch(process.env.REACT_APP_API_URL + "/auth/login-web-google/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_token: credential,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          NotificationManager.warning(
            "Vuelve a iniciar sesión.",
            "Sesión expirada",
            undefined,
            () => undefined
          )
        }
        isLoading && isLoading(false)
        return response.json()
      })
      .then(async (json) => {
        window.access_token = json.access_token
        authContext.setGoogleUser(json)

        const places = await getUserPlaces()
        placeContext.setValues(places[0], places)

        isLoading && isLoading(false)
      })
  } catch (e) {
    console.error(e)
  }
}

//My commerce
const getUserPlaces = async () => {
  const { places } = await fetchGetShops()

  const userPlaces = []
  for (const key in places) {
    //return only the value of the object
    userPlaces.push(places[key])
  }

  return userPlaces
}
