import { useState } from 'react'
import {useForm} from 'react-hook-form'

import {Overlay, Popover, Form, Button } from 'react-bootstrap'

import { FcPlus } from 'react-icons/fc';
import { GiSmartphone } from 'react-icons/gi';
import { HiPlusCircle } from 'react-icons/hi';
import { AiOutlineUser, AiOutlineMail, AiOutlineDelete, AiOutlineSave } from 'react-icons/ai';

import { fetchUpdateCredits } from '../../../../requests/requests';

export default function TooltipAddClient({data, domRef, disabled}){
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [value, setValue] = useState("");
    const {handleSubmit, register, getValues, formState: { errors }} = useForm()
    const [ showError, setShowError ] = useState( false )


    const onSubmit = (res) => {
        setShow( false )
        data.setCustomClient( res )
        data.setClientSelected( res )
        data.allClients.push( res )

    };

    function validateNumber(num) {
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return num.match(phoneno);
    }

    const changeValue = (event)=>{
        setValue(event.target.value)
    }

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };
    const handleHidde = ()=>{
        setShow( false )
    }
    return(
        <>
            { disabled 
            ? <HiPlusCircle size={25} fill="#f0f0f0"/>
            : <FcPlus role="button" size={25} onClick={handleClick}/>
            }
            <Overlay
                show={show}
                target={target}
                placement="left"
                container={domRef}
                rootClose={true}
                onHide={handleHidde}
            >
            <Popover id="popover-contained" className="w-100" >
                <Popover.Header  className="text-dark">Nuevo cliente</Popover.Header>
                <Popover.Body >
                    <Form id="new-client" 
                    className="w-100 d-flex flex-column gap-2" 
                    onSubmit={handleSubmit( onSubmit )}>
                        <div className="d-flex align-items-center gap-2">
                            <AiOutlineUser size={25}/>
                            <Form.Control
                            {...register("given_name", {required:true})}
                            required
                            type="text"
                            placeholder="Nombre completo"
                            className="general-border-radius w-100"
                            aria-describedby="Nombre completo"/>
                        </div>

                        
                        
                        <div className="d-flex align-items-center gap-2">
                            <AiOutlineMail size={25}/>
                            <Form.Control
                            {...register("email", 
                            {   required:true, 
                                pattern: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
                            })}
                            required
                            type="email"
                            placeholder="Email"
                            className="general-border-radius w-100"
                            aria-describedby="Email"
                            />
                        </div>

                        <div className="d-flex flex-column align-items-center gap-2">
                            <div className="d-flex align-items-center gap-2">
                                <GiSmartphone size={25}/>
                                <Form.Control
                                
                                {...register("phonenumber", 
                                {   
                                    required:true, 
                                    pattern: /^\(?\d{2}\)?[\s\.-]?\d{4}[\s\.-]?\d{4}$/
                                })}
                                required
                                type="tel"
                                placeholder="Celular"
                                className="general-border-radius w-100"
                                aria-describedby="Celular"
                                
                                />
                            </div>
                        </div>

                        {errors?.given_name?.type === "required" && <p className="text-danger m-0 pt-2">Ingresá un nombre</p>}
                        {errors?.email?.type === "pattern" && <p className="text-danger m-0 pt-2">Ingresá un email valido</p>}
                        {errors?.phonenumber?.type === "pattern" && <p className="text-danger m-0 pt-2">Ingresá un número de teléfono válido, sin anteponer el 15</p>}

                        
                        <Button
                        form="new-client"
                        type="submit"
                        variant="primary text-white w-100 general-border-radius mt-3">
                            <AiOutlineSave size={20}/>
                        </Button>
                        
                    </Form>
                </Popover.Body>
            </Popover>
            </Overlay>
        </>
    )
}