import { useState } from "react";
import { useForm } from "react-hook-form";

import { Overlay, Popover, Form, Button, InputGroup } from "react-bootstrap";

import { RiEditBoxLine } from "react-icons/ri";
import { AiOutlineSave, AiOutlineDelete } from "react-icons/ai";

import {
  fetchUpdateCredits,
  fetchDeleteCredits,
} from "../../../../requests/requests";

export default function TooltipEditCredits({ data, domRef }) {
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [value, setValue] = useState(data.current_credits);
  const { register, getValues } = useForm();
  const [showError, setShowError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const body = getValues();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowError(true);
    } else {
      if (body.current_credits <= data.credits) {
        setShowError(false);
        setValidated(true);
        fetchUpdateCredits(data.place.id, data.client, data.id, body).then(
          (res) => {
            setShow(false);
            data.setReload(true);
          }
        );
      } else if (body.current_credits == data.current_credits) {
        setShow(false);
      } else {
        setShowError(true);
      }
    }
  };

  const handleDelete = () => {
    if (window.confirm("¿Esta seguro que quiere eliminar los creditos?")) {
      fetchDeleteCredits(data.place.id, data.client, data.id).then((res) => {
        setShow(false);
        data.setReload(true);
      });
    }
  };

  const changeValue = (event) => {
    setValue(event.target.value);
  };

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  const handleHidde = () => {
    setShow(false);
  };
  return (
    <>
      <RiEditBoxLine size={20} onClick={handleClick} />
      <Overlay
        container={domRef}
        show={show}
        target={target}
        placement="left"
        rootClose={true}
        onHide={handleHidde}
      >
        <Popover id="popover-contained" className="w-50">
          <Popover.Header className="text-dark">Editar</Popover.Header>
          <Popover.Body>
            <Form
              id="update"
              className="w-100"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <InputGroup>
                <Form.Control
                  {...register("current_credits", {
                    min: 0,
                    max: data.credits,
                  })}
                  required
                  type="number"
                  min="0"
                  max={data.credits}
                  placeholder="Ingresa la cantidad"
                  className="general-border-radius w-100"
                  aria-describedby="Ingresa la cantidad"
                  value={value}
                  onChange={changeValue}
                />
                {showError && (
                  <span className="fs-7 text-danger">
                    No puede ser mayor al máximo de créditos: {data.credits}
                  </span>
                )}
              </InputGroup>
              <div className="d-flex gap-2">
                <Button
                  onClick={handleDelete}
                  variant="outline-secondary w-100 general-border-radius mt-3"
                >
                  <AiOutlineDelete size={20} />
                </Button>
                <Button
                  form="update"
                  type="submit"
                  variant="primary text-white w-100 general-border-radius mt-3"
                >
                  <AiOutlineSave size={20} />
                </Button>
              </div>
            </Form>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}
