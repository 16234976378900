import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { LoadingContext } from 'react-router-loading';
import { fetchPlaces } from '../../../requests/requests';
import FirebaseImage from '../../utils/FirebaseImage.jsx';
import GoToMaps, { useGoToMaps } from '../../utils/GoToMaps';
import { HorizontalMarkee } from '../../utils/Markees';
import NotFound from '../../utils/NotFound';
import Skeleton from '../../utils/Skeleton';
import useQuery from '../../utils/useQuery';
import Distance from './Distance';
import './placewservice.scss';

export default function PlacesWithServiceView(props) {
    const [searchMetadata, setSearchMetadata] = useState(undefined);
    const [places, setPlaces] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [waitingForLocation, setWaitingForLocation] = useState(true);
    const [error, setError] = useState(false);
    const [locationEnabled, setLocationEnabled] = useState(false);

    const [position, setPosition] = useState(undefined);
    const query = useQuery();
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        loadingContext.done();
        window.scrollTo(0, 0);
        if (!('geolocation' in navigator)) {
            fetchPlacesInRemote(undefined);
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setWaitingForLocation(false);
                    setPosition(position);
                    setLocationEnabled(true);
                    fetchPlacesWithLocation(position, 5);
                },
                () => {
                    setWaitingForLocation(false);
                    setLocationEnabled(false);
                    fetchPlacesInRemote(undefined);
                },
            );
        }
    }, []);

    const fetchPlacesWithLocation = (position, distance) => {
        const locationSearchParams = {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
            delta: distance * 1000,
        };
        fetchPlacesInRemote(locationSearchParams);
    };

    const fetchPlacesInRemote = (searchParams) => {
        if (!searchParams) {
            searchParams = {};
        }
        searchParams.job_type_id = query.get('job-type');
        fetchPlaces(searchParams)
            .then((res) => {
                setPlaces(res.content.places);

                setSearchMetadata({
                    category: res.content.category,
                    job_type: res.content.job_type,
                });
                setIsFetching(false);
            })
            .catch((err) => {
                setError(true);
                setIsFetching(false);
            });
    };

    const onDisableLocationClick = () => {
        setLocationEnabled(false);
        setIsFetching(true);
        fetchPlacesInRemote(undefined);
    };

    if (waitingForLocation) {
        return (
            <div className='mt-5 mx-5' id='containerCategoryView'>
                <div id='imgNeedLocation' className='mx-auto' />
                <div className='h5 mt-3 fw-bold'>
                    Necesitaremos de tu ubicacion para mostrarte los lugares más cercanos
                </div>
            </div>
        );
    }

    if (isFetching && !locationEnabled) {
        return <SkeletonPlaces />;
    }

    const onDistanceSelected = (distance) => {
        setIsFetching(true);
        fetchPlacesWithLocation(position, distance);
    };

    return (
        <div id='containerCategoryView'>
            {!error && (
                <>
                    {searchMetadata && (
                        <div className='h3 fw-bold text-uppercase mt-2'>
                            {searchMetadata.job_type.type}
                        </div>
                    )}
                    {locationEnabled && <SearchDistance onDistanceSelected={onDistanceSelected} />}
                    {isFetching && locationEnabled && <LoadingNearPlaces />}
                    {!isFetching && (
                        <PlacesView
                            placesProp={places}
                            locationEnabled={locationEnabled}
                            category={searchMetadata.category.id}
                            onDisableLocation={onDisableLocationClick}
                        />
                    )}
                </>
            )}
            {error && <NotFound />}
        </div>
    );
}

function SearchDistance({ onDistanceSelected }) {
    const [distance, setDistance] = useState(5);

    const onChange = (selectedDistance) => {
        setDistance(selectedDistance);
    };

    const onAfterChange = (selectedDistance) => {
        onDistanceSelected(selectedDistance);
    };

    return (
        <div id='distance-slider-holder' className='mx-auto'>
            <div className='row mb-1'>
                <div className='col p-0 m-0 text-primary '>Distancia de búsqueda</div>
                <div className='col-3 p-0 m-0 text-white text-end'>{`${distance} km.`}</div>
            </div>
            <Slider
                defaultValue={distance}
                min={5}
                max={100}
                step={5}
                onChange={onChange}
                onAfterChange={onAfterChange}
            />
        </div>
    );
}

function LoadingNearPlaces() {
    return (
        <div className='mt-2' id='holderLoadingNearPlaces'>
            <div className='h6 mt-2 fw-bold text-light' id='labelLodaingLocationPlaces'>
                Buscando lugares cercanos
            </div>
            <div id='imgLodaingLocationPlaces' className='' />
            <div className='radar' />
            <div className='radar' id='wave2' />
        </div>
    );
}

function SkeletonPlaces() {
    return (
        <div className='placeSkeletonContainer mx-auto px-3'>
            {[...Array(3)].map((e, i) => (
                <div className='placeSkeleton mx-auto row my-2' key={i}>
                    <div className='my-auto ms-2 col-3 px-0 placeSkeletonImg'>
                        <Skeleton />
                    </div>
                    <div className='col-5 placeSkeletonText'>
                        <Skeleton />
                    </div>
                </div>
            ))}
        </div>
    );
}

function PlacesView({ placesProp, locationEnabled, category, onDisableLocation }) {
    const history = useHistory();

    const goBack = () => {
        history.goBack();
    };

    if (placesProp && placesProp.length === 0) {
        return (
            <div id='containerPlaces' className='mx-auto px-3 pb-5'>
                <div className='h1 fw-bold mt-3'>(´･_･`)</div>
                <div className='h5 fw-light mb-5' id='notFoundLabel'>
                    Lo sentimos, no encontramos lugares {locationEnabled ? 'cercanos' : ''}{' '}
                    brindando el servicio seleccionado
                </div>

                {locationEnabled && (
                    <button
                        className='row mx-auto '
                        id='btnDisableLocation'
                        onClick={onDisableLocation}
                    >
                        <img id='disableLocationIcon' className='mx-auto' />
                        Deshabilitar la ubicación
                    </button>
                )}

                <button
                    className='row mx-auto text-center justify-content-center mt-2'
                    id='btnGoBack'
                    onClick={goBack}
                >
                    Volver
                </button>
            </div>
        );
    }

    return (
        <div id='containerPlaces' className='mx-auto px-3 pb-5'>
            {placesProp.map((place) => {
                return <Place key={place.id} place={place} locationEnabled={locationEnabled} />;
            })}
        </div>
    );
}

function Place({ place, locationEnabled }) {
    const [showTooltip, setShowTooltip] = useState(false);
    const goToMaps = useGoToMaps(place.latitude, place.longitude);
    const target = useRef(null);
    let history = useHistory();

    const setImgUrl = () => {};

    const redirectToPlacePage = () => {
        history.push(`/place/${place.id}`);
    };

    const onToggletoolTip = (e) => {
        e.stopPropagation();
        setShowTooltip(!showTooltip);
    };

    return (
        <div className='place my-2 mx-auto row' onClick={redirectToPlacePage}>
            <div className='col-3 my-auto btn pe-0 me-0'>
                <div className='placeImg'>
                    <FirebaseImage id={place.id} setImgUrl={setImgUrl} />
                </div>
            </div>
            <div className='col-5 my-auto text-start p-0 m-0'>
                <div
                    className='row businessName fw-bold ps-3 pe-0 fs-5  w-100'
                    onClick={redirectToPlacePage}
                >
                    <HorizontalMarkee text={place.businessname} />
                </div>
                <div className='row ps-1'>
                    <div className={locationEnabled ? 'col-1' : 'col-6 p-0 m-0 ms-2'}>
                        <GoToMaps
                            lat={place.latitude}
                            lon={place.longitude}
                            showCaption={!locationEnabled}
                        />
                    </div>
                    {locationEnabled && (
                        <div className='col my-auto' onClick={goToMaps}>
                            {<Distance lat={place.latitude} lon={place.longitude} />}
                        </div>
                    )}
                </div>
            </div>

            <div className='my-auto col-1'>
                {place.info && (
                    <>
                        <div id='tooltipInfo' ref={target} onClick={(e) => onToggletoolTip(e)} />
                        <Overlay target={target.current} show={showTooltip} placement='bottom'>
                            {(props) => (
                                <Tooltip {...props} id='overlay'>
                                    {place.info}
                                </Tooltip>
                            )}
                        </Overlay>
                    </>
                )}
            </div>
            {place.today && (
                <div className='appointmentToday col-3 my-auto' onClick={redirectToPlacePage}>
                    <div className='available mx-auto mb-2' />
                    ¡Turno Hoy!
                </div>
            )}
        </div>
    );
}
