
export const calendarDetails = (year, month, day) => {
	const current = { year, month, day };
	const prev = prevCalendar(year, month, day);
	const next = nextCalendar(year, month, day);
	return { prev, current, next };
};

const prevCalendar = (year, month, day) => {
	let prev = {};

	prev.year = year;
	prev.month = month - 1;
	prev.day = 1;
	if (prev.month < 0) {
		prev.year -= 1;
		prev.month = 11;
	}

	return prev;
};

const nextCalendar = (year, month, day) => {
	let next = {};

	next.year = year;
	next.month = month + 1;
	next.day = 1;
	if (next.month > 11) {
		next.year += 1;
		next.month = 0;
	}

	return next;
};
