import React from "react"
import "./day_select_label.scss"

import moment from "moment"
import "moment/locale/es"
import { BsCalendar2Check } from "react-icons/bs"
moment.locale("es")

export default function DaySelectLabel({ day }) {
  return (
    <h6 className="m-0 fw-bold">
      <span className="text-primary">
        <BsCalendar2Check size={25} /> {moment(day).format("LL")}
      </span>
    </h6>
  )
}
