import dateFormat from "dateformat"
import { FETCH, FETCH_UNAUTHORIZED } from "./http"

export function fetchPlace(placeId) {
  return FETCH_UNAUTHORIZED(`/api/place/${placeId}`, "GET")
}

export function fetchServices(placeId) {
  return FETCH(`/api/place/${placeId}/services`, "GET")
}

export function fetchPlaceJobs(placeId, services) {
  return FETCH(`/api/place/${placeId}/jobs`, "GET", { services: services })
}

export function fetchAvailableAppointments(
  placeId,
  jobId,
  services,
  selectedDate,
  selectedApps
) {
  let dateStr = dateFormat(selectedDate, "dd-mm-yyyy")
  let servicesStr = JSON.stringify(services)
  return FETCH(
    `/api/place/${placeId}/jobs/${jobId}/available-appointments/${dateStr}`,
    "POST",
    {
      services: servicesStr,
      selectedApps: selectedApps,
    }
  )
}

export function fetchNearAppointments(placeId, jobId, services, selectedApps) {
  let servicesStr = JSON.stringify(services)
  return FETCH(
    `/api/place/${placeId}/jobs/${jobId}/near-appointments`,
    "POST",
    {
      services: servicesStr,
      selectedApps: selectedApps,
    }
  )
}

export function bookAppointments(placeId, jobId, appointments, recaptchaValue) {
  return FETCH(`/api/place/${placeId}/jobs/${jobId}/appointments`, "POST", {
    captcha_token: recaptchaValue,
    job_id: jobId,
    appointments: appointments,
  })
}

export function fetchUserAppointments() {
  return FETCH("/api/appointments", "GET")
}

export function cancelAppointment(appId) {
  return FETCH(`/api/appointments`, "DELETE", { app_id: appId })
}

export function setNotificationMethod(method, data) {
  return FETCH(`/api/notification-method`, "POST", {
    method,
    contact_info: data,
  })
}

export function fetchCategories() {
  return FETCH_UNAUTHORIZED("/api/categories", "GET")
}

export function postContactSubmission(name, email, subject, message) {
  return FETCH_UNAUTHORIZED("/api/contact-submission", "POST", {
    name,
    email,
    subject,
    message,
  })
}

export function fetchJobTypesWithCategory(categoryId) {
  return FETCH_UNAUTHORIZED(`/api/categories/${categoryId}/job-types`, "GET")
}

export function fetchServicesOfJobType(categoryId, jobTypeId) {
  return FETCH_UNAUTHORIZED(
    `/api/categories/${categoryId}/job-types/${jobTypeId}/services`,
    "GET"
  )
}

export function fetchPlaces(searchParams) {
  return FETCH_UNAUTHORIZED(`/api/places`, "GET", searchParams)
}

export function verifyIsAdmin() {
  return FETCH("/admin/user", "GET")
}

export function getCronTasks() {
  return FETCH("/admin/cron-task", "GET")
}

export function startConTask(cronTask) {
  return FETCH(`/admin/cron-task/${cronTask}`, "POST")
}
export function haltConTask(cronTask) {
  return FETCH(`/admin/cron-task/${cronTask}`, "DELETE")
}

export function fetchStats() {
  return FETCH("/stats/stats", "GET")
}

export function fetchMessages() {
  return FETCH("/admin/messages", "GET")
}

export function deleteMessage(messageId) {
  return FETCH(`/admin/messages/${messageId}`, "DELETE")
}

export function fetchUsers(searchString) {
  const searchParams = { name: searchString }
  return FETCH("/admin/users", "GET", searchParams)
}

export function fetchUserDetails(user_id) {
  return FETCH(`/admin/users/${user_id}`, "GET")
}

export function postWhatsappCredits(job_id, credits) {
  const body = { credits }
  return FETCH(`/admin/jobs/${job_id}/whatsapp-credits`, "POST", body)
}
export function deleteWhatsappCredits(job_id) {
  return FETCH(`/admin/jobs/${job_id}/whatsapp-credits`, "DELETE")
}

/** MY COMMERCE FETCHS */
export function fetchGetCitesByClientID(employedID, clientID) {
  return FETCH(`/api/appointments/${employedID}/${clientID}`, "GET")
}
export function fetchGetShops() {
  return FETCH(`/android/profile/places/`, "GET")
}
export function fetchGetAllPlaceClients(placeID) {
  return FETCH(`/android/place/${placeID}/clients`, "GET")
}

export function fetchGetCitesOfMonth(employedID, month, year) {
  return FETCH(
    `/android/appointments/?job_id=${employedID}&date=2021-08-12&tz_offset=-003`,
    "GET"
  )
}

export function fetchGetCites() {
  return FETCH(`/android/appointments/`, "GET")
}

export function fetchGetCEmployeds(placeId) {
  return FETCH(`/android/jobs-in-place/?place_id=${placeId}`, "GET")
}

export function fetchGetClientsByEmployed(employedID) {
  return FETCH(`/android/clients-of-job/?job_id=${employedID}`, "GET")
}
export function fetchGetUserContactMethod(userID) {
  return FETCH(`/android/notification-method/?client_id=${userID}`, "GET")
}
export function fetchGetCiteByDate(employedID, date) {
  return FETCH(
    `/android/appointments/?job_id=${employedID}&date=${date}&tz_offset=-0300`,
    "GET"
  )
}
export function fetchGetClientCredits(placeID, clientID) {
  return FETCH(
    `/place-credits/places/${placeID}/clients/${clientID}/credits`,
    "GET"
  )
}
export function fetchGetPlaceCredits(placeID) {
  return FETCH(`/api/place/${placeID}/services`, "GET")
}
export function fetchGetServices(placeID) {
  return FETCH(`/api/place/${placeID}/services`, "GET")
}
export function fetchGetJobDayOff(employedID) {
  return FETCH(`/android/job/${employedID}/days-off`, "GET")
}
export function fetchPostAddCredits(placeID, clientID, body) {
  return FETCH(
    `/place-credits/places/${placeID}/clients/${clientID}/credits`,
    "POST",
    body
  )
}
export function fetchGetDaySchedules(employedID) {
  return FETCH(`/android/get-day-schedules/?job_id=${employedID}`, "GET")
}
export function fetchGetAllCitesCalendar(employedID) {
  return FETCH(`/android/place/get/cites/${employedID}`, "GET")
}

//Requests Modal add cite
export function fetchGetEmployedByService(placeID, serviceID) {
  return FETCH(`/api/place/${placeID}/jobs?services=${serviceID}`, "GET")
}

//Add cite
export function fetchPostCreateCite(body) {
  return FETCH("/android/new-anonymous-app/", "POST", body)
}

export function fetchGetLabels(placeID, jobID) {
  return FETCH(`/android/place/${placeID}/job/${jobID}/labels`, "GET")
}

export function fetchGetServiceInstances(jobID) {
  return FETCH(`/android/get-service-instances/?job_id=${jobID}`, "GET")
}
export function fetchDeleteCite(appID) {
  return FETCH("/android/drop-appointment/", "POST", { appointment_id: appID })
}

export function fetchGetFilterCitesByDate(jobID, clientID, dateStart, dateEnd) {
  return FETCH(
    `/api/appointments/${jobID}/${clientID}/${dateStart}/${dateEnd}`,
    "GET"
  )
}
export function fetchGetFilterCreditsByDate(
  placeID,
  clientID,
  dateStart,
  dateEnd
) {
  return FETCH(
    `/place-credits/places/${placeID}/clients/${clientID}/credits/${dateStart}/${dateEnd}`,
    "GET"
  )
}
export function fetchUpdateCredits(placeID, clientID, placeCreditID, body) {
  return FETCH(
    `/place-credits/places/${placeID}/clients/${clientID}/credits/${placeCreditID}`,
    "PUT",
    body
  )
}

export function fetchDeleteCredits(placeID, clientID, placeCreditID) {
  return FETCH(
    `/place-credits/places/${placeID}/clients/${clientID}/credits/${placeCreditID}`,
    "DELETE"
  )
}

export function fetchDeleteUser(userId) {
  return FETCH(`/auth/user/${userId}/`, "DELETE")
}
