import React, { useContext, useEffect, useState } from 'react';
import { LoadingContext } from 'react-router-loading';
import { verifyIsAdmin as verifyHasAccessToAdmin } from '../../requests/requests';
import { useIsLoggedIn } from '../auth/authHooks';
import LoginPage from '../auth/LoginPage';
import NotFound from '../utils/NotFound';

function AdminRoute(props) {
    const loadingContext = useContext(LoadingContext);
    const isLoggedIn = useIsLoggedIn();
    const [hasAccess, setHasAccess] = useState(false);

    useEffect(() => {
        loadingContext.done();
    }, []);

    const verifyAccess = () => {
        verifyHasAccessToAdmin()
            .then(() => {
                setHasAccess(true);
            })
            .catch(() => {
                setHasAccess(false);
            });
    };

    useEffect(() => {
        if (isLoggedIn) {
            verifyAccess();
        }
    }, [isLoggedIn]);

    if (!isLoggedIn) {
        return <LoginPage />;
    }

    if (!hasAccess) {
        return <NotFound />;
    }

    return (
        <div id='admin-route-container' className='mt-5 pt-5 mx-auto'>
            {props.children}
        </div>
    );
}

export default AdminRoute;
