import React from 'react';
import { useEffect } from 'react';
import { FETCH } from '../../requests/http';

import { LoadingContext } from 'react-router-loading';
import { useContext } from 'react';
import { useState } from 'react';

import './credits.scss';
import FirebaseImage from '../utils/FirebaseImage';
import { useHistory } from 'react-router';

function CreditsView() {
    const loadingContext = useContext(LoadingContext);
    const [credits, setCredits] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchCredits = () => {
            return FETCH('/place-credits/credits', 'GET');
        };

        fetchCredits()
            .then((res) => {
                window.scrollTo(0, 0);
                loadingContext.done();
                setCredits(res.data);
                setIsFetching(false);
            })
            .catch((err) => {
                console.log(err);
                setError(true);
                setIsFetching(false);
            });
    }, []);

    if (isFetching) {
        return <span>Fetching...</span>;
    }

    return (
        <div id='credits-page' className='page'>
            <span className='fw-bold h5 text-uppercase text-light'>Mis créditos</span>
            {credits.length > 0 && (
                <div id='credits-container' className='container-sm mx-auto'>
                    {credits.map((v, i) => {
                        return <Credits credits={v} key={i} />;
                    })}
                </div>
            )}
            {credits.length === 0 && <div className='mt-4'>No tienes créditos restantes</div>}
        </div>
    );
}

function Credits({ credits }) {
    const history = useHistory();

    const onClick = () => {
        history.push(`/place/${credits.place.id}`);
    };

    const formatCalendar = (calendar) => {
        let split = calendar.split('-');

        return `${split[2]} / ${split[1]}`;
    };

    return (
        <div className='credits p-3' onClick={onClick}>
            <div className='external-link'>
                <i class='fas fa-external-link-alt'></i>
            </div>
            <div class='row'>
                <div className='col'>
                    <div className='credits-place my-auto'>
                        <div className='credits-place-image my-auto mx-auto'>
                            <FirebaseImage id={credits.place.id} />
                        </div>
                        <span className='my-auto fw-bold text-uppercase mt-1'>
                            {credits.place.businessname}
                        </span>
                    </div>
                </div>
                <div className='col my-auto'>
                    <span className='info'>Disponibles</span>
                    <div className='current-credits-count-icon mx-auto' />
                    <div className='current-credits-count h5 fw-bold'>
                        {credits.current_credits}
                    </div>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='my-auto text-start mt-4'>
                    <span className='info me-3'>Vigencia del</span>
                    <span>
                        <i class='far fa-calendar me-2'></i>
                        {formatCalendar(credits.valid_from)}
                    </span>
                    <span className='mx-3 info'>al</span>
                    <span>
                        <i class='far fa-calendar me-2'></i>
                        {formatCalendar(credits.valid_until)}
                    </span>
                </div>

                <span className='mx-auto text-start info info-services mt-4'>Para usar en</span>
                <div className='credits-services row'>
                    <CreditsService services={credits.service_credits} />
                </div>
            </div>
        </div>
    );
}

function CreditsService({ services }) {
    return (
        <div className='services-wrapper'>
            {services.map((v, i) => {
                return (
                    <div className='service' key={i}>
                        {v.service.name}
                    </div>
                );
            })}
        </div>
    );
}

export default CreditsView;
