import React from "react";
import "./select_payment.scss";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import CircleImage from "../../../child_components/CircleImage/circle_image";

import LayoutParent from "../../../layouts/parent";

export default function SelectPayment({ handleSelect }) {
  return (
    <LayoutParent>
      <div className="py-2 d-flex flex-column text-center gap-2 fw-bold">
        <h4 className="title-pay">
          Seleccione el sistema de cobro a utilizar:
        </h4>

        <div className="d-flex flex-column gap-5">
          <div className="d-flex justify-content-center w-100">
            <button
              onClick={() => handleSelect("credits")}
              role="button"
              className="shadow-lg button-select-pay text-white  text-decoration-none d-flex bg-primary p-2 general-border-radius justify-content-center fw-bold align-items-center position-relative "
            >
              <span>Sistema de créditos</span>
              <div className="position-img">
                <CircleImage
                  className="bg-white img-pay"
                  size="xl"
                  borderColor="primary"
                  src="credits.jpg"
                />
              </div>
            </button>
          </div>

          {/* <a role="button" className="text-white  text-decoration-none d-flex bg-primary p-2 rounded-3 justify-content-center fw-bold align-items-center position-relative fs-1">
            <span>Mercado Pago</span>
            <div className="position-img">
              <CircleImage size="lg" borderColor="primary" src="credits.jpg"/>
            </div>
          </a> */}
        </div>
      </div>
    </LayoutParent>
  );
}
