import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { LoadingContext } from 'react-router-loading';
import { postContactSubmission } from '../../../requests/requests';

import './contact.scss';

export default function ContactUs() {
    const [errors, setErrors] = useState({});
    const [isFetching, setIsFetching] = useState(false);
    const [email, setEmail] = useState();
    const [subject, setSubject] = useState();
    const [name, setName] = useState();
    const [message, setMessage] = useState();
    const [hasSent, setHasSent] = useState(false);
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        loadingContext.done();
        window.scrollTo(0, 0);
    }, []);

    const onSubmit = () => {
        const newErrors = getAnyErrors();

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            sendData();
        }
    };

    const getAnyErrors = () => {
        const newErrors = {};
        if (isEmpty(email) || !isValidEmail()) {
            newErrors.email = true;
        }
        if (isEmpty(subject)) {
            newErrors.subject = true;
        }
        if (isEmpty(name)) {
            newErrors.name = true;
        }
        if (isEmpty(message)) {
            newErrors.message = true;
        }
        return newErrors;
    };

    const isValidEmail = () => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const isEmpty = (str) => {
        return !str || str.length === 0;
    };

    const sendData = () => {
        setIsFetching(true);
        postContactSubmission(name, email, subject, message).then(() => {
            setIsFetching(false);
            setHasSent(true);
        });
    };

    return (
        <div id='contactRoot'>
            <section className='mx-auto pb-5' id='contactUsContainer'>
                <div className='h1 text-center fw-bold text-uppercase'>¡Contactanos!</div>

                <p className='h5 mx-3'>
                    ¿Tenés alguna sugerencia o pregunta? Envianos tu mensaje y nos pondremos en
                    contacto a la brevedad.
                </p>

                <div className='row mt-4'>
                    <div className='col-md-7 mb-md-0 mb-5'>
                        <form id='contact-form' name='contact-form' action='mail.php' method='POST'>
                            <div className='row'>
                                <div className='col-md-6 position-relative mb-4'>
                                    <div className='form-input mb-0'>
                                        <label htmlFor='name' className=''>
                                            Nombre y Apellido
                                        </label>

                                        <input
                                            onChange={(e) => setName(e.target.value)}
                                            disabled={hasSent}
                                            type='text'
                                            id='name'
                                            name='name'
                                            className={
                                                `form-control ` + (errors.name ? 'withError' : '')
                                            }
                                        />
                                        {errors.name && (
                                            <label className='errorMsg'>
                                                Este campo es obligatorio
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className='col-md-6 mb-2 position-relative mb-4'>
                                    <div className='form-input mb-0'>
                                        <label htmlFor='email' className=''>
                                            Email
                                        </label>
                                        <input
                                            onChange={(e) => setEmail(e.target.value)}
                                            disabled={hasSent}
                                            type='text'
                                            id='email'
                                            name='email'
                                            className={
                                                `form-control ` + (errors.email ? 'withError' : '')
                                            }
                                        />{' '}
                                        {errors.email && (
                                            <label className='errorMsg'>
                                                Este campo es obligatorio
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12 position-relative mb-4'>
                                    <div className='form-input'>
                                        <label htmlFor='subject' className=''>
                                            Motivo
                                        </label>

                                        <input
                                            onChange={(e) => setSubject(e.target.value)}
                                            disabled={hasSent}
                                            type='text'
                                            id='subject'
                                            name='subject'
                                            className={
                                                `form-control ` +
                                                (errors.subject ? 'withError' : '')
                                            }
                                        />
                                        {errors.subject && (
                                            <label className='errorMsg'>
                                                Este campo es obligatorio
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12 position-relative mb-4'>
                                    <div className='form-input'>
                                        <label htmlFor='message'>Tu mensaje</label>
                                        <textarea
                                            onChange={(e) => setMessage(e.target.value)}
                                            disabled={hasSent}
                                            type='text'
                                            id='message'
                                            name='message'
                                            rows='2'
                                            className={
                                                `form-control ` +
                                                (errors.message ? 'withError' : '')
                                            }
                                        />{' '}
                                        {errors.message && (
                                            <label className='errorMsg'>
                                                Este campo es obligatorio
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className='text-center text-md-left mt-3'>
                            {!hasSent && (
                                <button id='btnSend' className='btn' onClick={onSubmit}>
                                    {isFetching && (
                                        <span
                                            className='spinner-border spinner-border-sm me-2'
                                            role='status'
                                            aria-hidden='true'
                                        />
                                    )}
                                    Enviar
                                </button>
                            )}
                            {hasSent &&
                                'Gracias por enviarnos el mensaje, nos pondremos en contacto a la brevedad.'}
                        </div>
                        <div className='status'></div>
                    </div>

                    <div className='col-md-5 text-center justify-content-center' id='contactInfo'>
                        <ul className='list-unstyled'>
                            <li>
                                <i className='fas fa-map-marker-alt fa-2x'></i>
                                <p>Disponible próximamente</p>
                            </li>

                            <li>
                                <i className='fas fa-phone mt-4 fa-2x'></i>
                                <p>Disponible próximamente</p>
                            </li>

                            <li>
                                <i className='fas fa-envelope mt-4 fa-2x'></i>
                                <p>turnos@yatenesturno.com.ar</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
}
