import { useEffect, useState } from 'react';
import { fetchServices } from '../../requests/requests';

import styles from './service-selection.module.scss';
import Skeleton from '../utils/Skeleton';

import { VerticalMarkee } from '../utils/Markees';

export default function ServiceSelection(props) {
    const [services, setServices] = useState(null);
    const [selectedServices, setSelectedServices] = useState(props.selectedServices);
    const [prices, setPrices] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const [error, setError] = useState(false);
    const [showCreditsBtn, setShowCreditsBtn] = useState(false);

    useEffect(() => {
        fetchServices(props.placeId)
            .then((res) => {
                setServices(res.content.services);
                setPrices(res.content.prices);
                setIsFetching(false);
            })
            .catch((err) => {
                setError(true);
            });
    }, []);

    useEffect(() => {
        if (selectedServices) {
            for (let i = 0; i < selectedServices.length; i++) {
                if (selectedServices[i].credits >= 0) {
                    setShowCreditsBtn(true);
                    return;
                }
            }
        }

        setShowCreditsBtn(false);
    }, [selectedServices]);

    const shouldShowBtnContinue = () => {
        return selectedServices.length > 0;
    };

    let addService = (service) => {
        let aux = selectedServices.slice();

        aux.push(service);

        setSelectedServices(aux);
    };

    let removeService = (serviceId) => {
        let index = -1;
        for (let i = 0; i < selectedServices.length; i++) {
            if (selectedServices[i].id === serviceId) {
                index = i;
                break;
            }
        }
        if (index > -1) {
            let aux = selectedServices.slice();

            aux.splice(index, 1);

            setSelectedServices(aux);
        }
    };

    if (error) {
        return <div>Se produjo un error, porfavor reinténtelo nuevamente</div>;
    }

    return (
        <div>
            <button
                className={
                    `${styles.btnContinue} ` +
                    (shouldShowBtnContinue() ? `${styles.active}` : `${styles.disabled}`)
                }
                disabled={!shouldShowBtnContinue()}
                onClick={() => {
                    props.onConfirm(selectedServices);
                }}
            >
                Continuar
                {showCreditsBtn && <span className={styles.creditsIcon} />}
            </button>

            {isFetching ? (
                <ServicesSkeleton />
            ) : (
                <div>
                    {Object.keys(services).length > 0 &&
                        Object.keys(services).map(function (jobType) {
                            return (
                                <div key={jobType}>
                                    <div
                                        className={`${styles.jobType} fw-bold text-light mx-auto text-start ps-3`}
                                    >
                                        {jobType}
                                    </div>
                                    <JobType
                                        services={services[jobType]}
                                        selectedServices={selectedServices}
                                        prices={prices}
                                        addService={addService}
                                        removeService={removeService}
                                    />
                                </div>
                            );
                        })}
                    {Object.keys(services).length === 0 && (
                        <div>Este lugar aún no provee servicios</div>
                    )}
                </div>
            )}
        </div>
    );
}

function JobType(props) {
    let onSelected = (checked, service) => {
        if (checked) {
            props.addService(service);
        } else {
            props.removeService(service.id);
        }
    };

    const isSelected = (service) => {
        for (let i = 0; i < props.selectedServices.length; i++) {
            if (props.selectedServices[i].id === service.id) {
                return true;
            }
        }
        return false;
    };

    return (
        <div className={`${styles.servicesContainer} m-auto`}>
            {props.services.map((service) => (
                <Service
                    key={service.id}
                    selected={isSelected(service)}
                    service={service}
                    price={props.prices[service.id]}
                    onSelected={onSelected}
                />
            ))}
        </div>
    );
}

function Service({ service, price, selected, onSelected }) {
    const [isSelected, setSelected] = useState(selected);

    function toggleSelected() {
        onSelected(!isSelected, service);
        setSelected(!isSelected);
    }

    return (
        <div
            onClick={() => toggleSelected()}
            key={service.id}
            className={`d-inline-block container-sm ${styles.service} text-center mt-2 mx-2 p-0`}
        >
            <div className='mb-1 text-end'>
                {service.emergency && <div className={`${styles.emergency} text-start`} />}
                {service.credits >= 0 ? (
                    <div className={styles.credits}>{service.credits}</div>
                ) : null}

                <button
                    key={service.id}
                    className={`${styles.checkBoxService} mt-1 me-1 ${
                        selected ? `${styles.serviceSelected}` : ''
                    } `}
                />
            </div>
            <div className={`p-0 px-1 ${styles.serviceName}`}>
                <VerticalMarkee text={service.name} />
            </div>
            <div className={`row ms-2 text-light ${styles.priceTag} p-0`}>
                <div className='my-auto col-1' />
                <div className='col text-start my-auto'>{price}</div>
            </div>
        </div>
    );
}

function ServicesSkeleton() {
    return (
        <div className={`${styles.serviceSkeletonContainer} m-auto`}>
            {[...Array(2)].map((e, i) => (
                <div key={i}>
                    <div className={`${styles.jobType} fw-bold text-light mx-auto text-start ps-3`}>
                        <div className={`${styles.serviceSkeletonText} mt-4`}>
                            <Skeleton />
                        </div>
                    </div>
                    {[...Array(3)].map((e, i) => (
                        <div
                            className={`${styles.serviceSkeleton} m-auto d-inline-block container-sm text-center mt-2 mx-2 p-0`}
                            key={i}
                        >
                            <div
                                className={`${styles.serviceSkeletonImg} text-end ms-auto me-2 mt-2`}
                            >
                                <Skeleton />
                            </div>
                            <div className={`${styles.serviceSkeletonText} mt-4 px-2`}>
                                <Skeleton />
                            </div>
                            <div className={`${styles.serviceSkeletonText} mt-1 px-4`}>
                                <Skeleton />
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
