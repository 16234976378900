import { useState, createContext } from 'react';
export const ClientContext = createContext({
    clientSelect: undefined,
    setClient: () => {},
});

export const ClientContextProvider = (props) => {
    
    const setClient = ( client ) => {
        setState( { ...state, clientSelect: client } )
    };


    const initState = {
        clientSelect: undefined,
        setClient,
    };
    
    const [state, setState] = useState(initState);
   
    return <ClientContext.Provider value={state}>{props.children}</ClientContext.Provider>;
};
