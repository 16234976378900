import { useState } from "react"
import "./modal_addcite.scss"

import { Button } from "react-bootstrap"
import StepOne from "./step_one"
import StepTwo from "./step_two"
import ResultCite from "./result_cite"

import { MdOutlineClose } from "react-icons/md"

export default function ModalAddCite({ data }) {
  const [step, setStep] = useState(1)
  const [sendData, setSendData] = useState(undefined)
  const [resultFecth, setResultFecth] = useState(undefined)

  const dataStepOne = {
    setStep,
    setSendData,
  }
  const dataStepTwo = {
    setStep,
    sendData,
    setResultFecth,
    setReload: data.setReload,
  }
  const preview = {
    setReload: data.setReload,
    setIsOpen: data.setIsOpen,
    setActionDay: data.setActionDay,
    setEmployedSelected: data.setEmployedSelected,
  }
  return (
    <div className="d-flex h-100 flex-column fw-bold">
      <div className="bg-primary p-2 text-center fw-bold fs-6 sticky-top d-flex align-items-center">
        <span className="flex-fill">Buscar turnos disponibles </span>
        <span role="button">
          <MdOutlineClose size={30} onClick={() => data.setIsOpen(false)} />
        </span>
      </div>

      <StepOne
        dataPreview={dataStepOne}
        className={step === 1 ? "d-block" : "d-none"}
      />
      <StepTwo
        dataPreview={dataStepTwo}
        className={step === 2 ? "d-block" : "d-none"}
      />
      <ResultCite
        resultFetch={resultFecth}
        data={preview}
        className={step === 3 ? "d-block" : "d-none"}
      />
    </div>
  )
}
