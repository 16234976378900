import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"

import reportWebVitals from "./reportWebVitals"
import HttpsRedirect from "react-https-redirect"
import { AuthContextProvider } from "./components/auth/AuthContextProvider"
import { PlaceContextProvider } from "./components/my_commerce/PlaceContextProvider"
import { ClientContextProvider } from "./components/my_commerce/ClientContextProvider"
import { GoogleOAuthProvider } from "@react-oauth/google"
import App from "./App"

ReactDOM.render(
  <React.StrictMode>
    <HttpsRedirect disabled={process.env.REACT_APP_DEBUG === "true"}>
      <AuthContextProvider>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <PlaceContextProvider>
            <ClientContextProvider>
              <App />
            </ClientContextProvider>
          </PlaceContextProvider>
        </GoogleOAuthProvider>
      </AuthContextProvider>
    </HttpsRedirect>
  </React.StrictMode>,
  document.getElementById("root")
)

reportWebVitals()
