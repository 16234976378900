import { useState } from "react";
import "./button_checkbox.scss";

import Modal from "react-modal";
import { Button, Form } from "react-bootstrap";

export default function ButtonCheckBox({ data }) {
  const [isCheck, setIsCheck] = useState(false);

  const handleCheck = (e) => {
    if (!isCheck)
      //Set service in array
      data.setUserSelectedServices([
        ...data.userSelectedServices,
        data.service.id,
      ]);
    //Drop
    else
      data.setUserSelectedServices(
        data.userSelectedServices.filter(
          (serviceID) => serviceID != e.target.id
        )
      );

    setIsCheck(!isCheck);
  };

  return (
    <Button
      variant="white"
      id={data.service.id}
      onClick={(e) => handleCheck(e)}
      className={`flex-fill shadow-sm general-border-radius p-2 fw-bold d-flex align-items-center gap-3 border ${
        isCheck ? "border-primary" : "border-light-gray"
      } justify-content-center`}
    >
      {data.service.name}
      <Form.Check
        checked={isCheck}
        readOnly
        type="checkbox"
        aria-label={data.service.name}
      />
    </Button>
  );
}
