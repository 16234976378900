import { useState, useContext, useEffect } from "react"
import "./modal_addcredits.scss"
import { PlaceContext } from "../../PlaceContextProvider"
import { useForm, useFieldArray } from "react-hook-form"
import Lottie from "lottie-react"
import config from "../../../../lottie/config.json"

import {
  Form,
  Row,
  Col,
  Container,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap"

import ButtonCheckBox from "../ButtonCheckBox/button_checkbox"
import SearchBar from "../SearchBar/search_bar"
import { MdOutlineClose } from "react-icons/md"

//Request
import { fetchPostAddCredits } from "../../../../requests/requests"
import { GetPlaceServices } from "../../../utils/swr_callbacks"
import { ops, success } from "../../utils/messages"

export default function ModalAddCredits({ data }) {
  const [userSelectedServices, setUserSelectedServices] = useState([])

  //React Hook Form
  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm()
  const { replace } = useFieldArray({ control, name: "services" })

  const { placeSelected } = useContext(PlaceContext)
  const { services, isLoading } = GetPlaceServices(placeSelected.id)

  const [searched, setSearched] = useState([])
  const [hasServicesUseCredits, setHasServicesUseCredits] = useState([])
  const [validated, setValidated] = useState(false)
  const [resultFetch, setResultFetch] = useState({
    status: false,
    cbk: () => {},
  })

  const handleSubmit = (event) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      setValue("services", userSelectedServices) //Insert services selected

      const body = getValues()

      fetchPostAddCredits(placeSelected.id, body.client, body)
        .then((res) => {
          const client = data.clients.filter(
            (client) => client.id == body.client
          )[0]

          setResultFetch({
            show: true,
            status: true,
            cbk: () =>
              success(
                `Cargaste ${body.credits} creditos a ${
                  client.given_name + " " + client.family_name
                }.`
              ),
          })
        })
        .catch((error) => {
          setResultFetch({
            show: true,
            status: false,
            cbk: () => ops(`Al parecer algo salio mal. Error( ${error} )`),
          })
        })
    }
    setValidated(true)
  }

  const closeModal = () => {
    const body = getValues()

    data.setIsOpen(false)
    if (resultFetch.status) {
      const client = data.clients.filter(
        (client) => client.id == body.client
      )[0]
      data.setClientSelected(client)
      data.setReload(true)
    }
  }

  useEffect(() => {
    //Load services in array
    const servicesUseCredits = services.filter(
      (service) => service.credits >= 0
    )
    setHasServicesUseCredits(servicesUseCredits)
    setSearched(servicesUseCredits)
  }, [isLoading])

  const dataSearchBar = {
    allServices: services,
    setSearched,
  }

  /***** renders */
  const servicesItems = searched.map((obj, key) => (
    <ButtonCheckBox
      key={key}
      data={{
        service: obj,
        userSelectedServices,
        setUserSelectedServices,
      }}
    />
  ))

  const clientsItem = data.clients.map((client, key) => (
    <option
      selected={data.clientSelected === client}
      value={client.id}
      key={client.id}
    >
      {client.given_name + " " + client.family_name}
    </option>
  ))

  if (resultFetch.show) {
    return (
      <div className="d-flex flex-column fw-bold h-100">
        <div className="bg-primary p-2 text-center fw-bold fs-4">
          Cargar nuevos Créditos
        </div>
        <div className="text-black text-center py-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          {resultFetch.cbk()}
          <Button
            variant="outline-primary general-border-radius w-auto"
            onClick={closeModal}
          >
            Cerrar
          </Button>
        </div>
      </div>
    )
  }

  if (!hasServicesUseCredits.length) {
    return (
      <div className="d-flex flex-column fw-bold h-100">
        <div className="bg-primary p-2 text-center fw-bold fs-6 sticky-top d-flex align-items-center">
          <span className="flex-fill text-center">Cargar créditos </span>
          <span role="button">
            <MdOutlineClose size={30} onClick={() => data.setIsOpen(false)} />
          </span>
        </div>
        <div className="text-black text-center py-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <div className="lottie">
            <Lottie animationData={config} loop={false} autoPlay={true} />
          </div>
          <h4>No ofreces ningun servicio que acepte créditos.</h4>
          <span>Crealos desde la aplicación.</span>
        </div>
      </div>
    )
  } else {
    return (
      <div className="d-flex flex-column fw-bold h-100">
        <div className="bg-primary p-2 text-center fw-bold fs-6 sticky-top d-flex align-items-center">
          <span className="flex-fill text-center">Cargar créditos </span>
          <span role="button">
            <MdOutlineClose size={30} onClick={() => data.setIsOpen(false)} />
          </span>
        </div>
        <div className="text-black text-center py-2 flex-fill">
          <Container fluid className=" d-flex flex-column gap-3">
            <Form
              id="hook-form"
              onSubmit={handleSubmit}
              noValidate
              validated={validated}
            >
              <Form.Group
                as={Row}
                className="mb-1 text-center justify-content-center "
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="auto">
                  Cliente
                </Form.Label>
                <Col sm="2">
                  <Form.Select
                    {...register("client", { required: true })}
                    size="sm"
                    aria-label="Default select example"
                    className="general-border-radius select text-center fw-light"
                  >
                    {clientsItem}
                  </Form.Select>
                </Col>
              </Form.Group>
              <hr></hr>

              {/* Input credits */}
              <Row>
                <Col xs={12} md={4} sm="auto">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>¿Cuantos créditos?</Form.Label>
                    <Form.Control
                      {...register("credits", { min: 1, max: 1000 })}
                      required
                      type="number"
                      min="0"
                      max="1000"
                      placeholder="Ingresa la cantidad"
                      className="general-border-radius"
                    />
                  </Form.Group>
                </Col>

                <Col xs={12} md={4}>
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Valido desde:</Form.Label>
                    <Form.Control
                      {...register("valid_from", { required: true })}
                      required
                      type="date"
                      placeholder="Ingresa la cantidad"
                      className="general-border-radius"
                    />
                  </Form.Group>
                </Col>

                <Col xs={12} md={4}>
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Valido hasta:</Form.Label>
                    <Form.Control
                      {...register("valid_until", { required: true })}
                      required
                      type="date"
                      placeholder="Ingresa la cantidad"
                      className="general-border-radius"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr></hr>

              {/* Select Services */}
              <Row className="gy-2">
                <Col xs={12}>
                  <h6 className="fs-5 m-0">¿En que servicios?</h6>
                </Col>
                <Col xs={12} className="justify-content-center">
                  <SearchBar
                    className="bg-white border  p-2 my-2 general-border-radius"
                    placeholder="Busca un servicio"
                    data={dataSearchBar}
                  />
                </Col>
              </Row>

              <div className="d-flex flex-wrap gap-3">
                {!isLoading ? servicesItems : <>Cargando...</>}
              </div>
            </Form>
          </Container>
        </div>

        <Button
          type="submit"
          form="hook-form"
          variant={!userSelectedServices.length ? "light" : "primary"}
          className="fs-4 fw-bold"
          disabled={!userSelectedServices.length}
        >
          Confirmar Créditos
        </Button>
      </div>
    )
  }
}
