import React from "react"
import "./Day.styles.scss"
import classnames from "classnames"
import Event from "./Event"
import { getElementHeight } from "../util/getElementHeight"
import { getDate } from "../util/date"
import { isPastEvent } from "../../../utils/EventUtils.js"
import classNames from "classnames"
import dateFormat from "dateformat"

export default class Day extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      eventNumber: 1,
    }
  }

  // componentDidMount() {
  //     this.shouldShowRemainder();
  // }

  // componentDidUpdate() {
  //     this.shouldShowRemainder();
  // }

  isCurrentDate() {
    const date = this.props.date.getTime()
    const currentDate = getDate(new Date()).getTime()
    return date === currentDate
  }

  isSelectedDay() {
    const date = this.props.date
    const selected = this.props.selectedDate
    const selectedDate = new Date(selected.year, selected.month, selected.day)

    return selectedDate.getTime() === date.getTime()
  }

  returnDayClassStyle() {
    let className = ["dayDate"]
    if (!this.props.current) {
      className.push("inactiveDay")
    }
    if (this.props.propsMyCommerce && !this.props.isActive)
      className.push("text-muted opacity-25")

    return classnames(className)
  }

  shouldShowRemainder() {
    const { events } = this.props
    if (Array.isArray(events) && events.length) {
      const dayCell = document.getElementById("dayCell")
      const dayCellHeight = getElementHeight(dayCell)

      const dayHeader = document.getElementById("dayHeader")
      const dayHeaderHeight = getElementHeight(dayHeader)

      const eventsList = document.getElementsByClassName("dayCellEvent")
      const singleEvent = eventsList[0]
      const singleEventHeight = getElementHeight(singleEvent)

      const remainingTextHeight = 16

      const eventGroupHeight =
        dayCellHeight - dayHeaderHeight - remainingTextHeight

      const numberOfEventsToDisplay = Math.floor(
        eventGroupHeight / singleEventHeight
      )

      this.setState({
        eventNumber: numberOfEventsToDisplay,
      })
    }
  }

  returnEventList() {
    const { events } = this.props
    if (Array.isArray(events) && events.length) {
      let displayEvents = events.slice(0, this.state.eventNumber)
      return (
        <div className={"dayCellEventWrapper"}>
          {this.returnEvents(displayEvents)}
        </div>
      )
    }
  }

  returnEvents(events) {
    return events.map((event) => {
      return (
        <Event
          height={16}
          inactive={!this.props.current}
          key={event.id}
          color={event.color}
          title={event.title}
          position={event.position}
          past={isPastEvent(event)}
        />
      )
    })
  }

  returnEventRemainder() {
    const { events } = this.props
    const { eventNumber } = this.state
    if (Array.isArray(events) && events.length > eventNumber) {
      const remainder = events.length - eventNumber
      return (
        <span
          className={"dayEventsRemaining text-center"}
        >{`${remainder} más...`}</span>
      )
    }
  }

  returnDayTextClass() {
    let className = ["dayText"]
    if (this.isCurrentDate()) {
      className.push("currentDay")
    }
    if (this.props.isDayOff) {
      className.push("text-decoration-line-through text-danger")
    }
    return classnames(className)
  }

  matchCite() {
    const citeDate = new Date(
      dateFormat(this.props.dataCite[0].datetime, "default", true)
    )
    const currentDate = new Date()

    if (
      citeDate.getTime() > currentDate.getTime() ||
      citeDate.getDay() == currentDate.getDay()
    )
      return (
        <span className="match-cite bg-primary">
          {this.props.dataCite[0].count}
        </span>
      )
    else
      return <span className="match-cite">{this.props.dataCite[0].count}</span>
  }

  render() {
    const dayCellClassNames = ["dayCell"]

    if (this.isSelectedDay()) {
      dayCellClassNames.push("selectedCell")
    }

    if (this.props.propsMyCommerce) {
      return (
        <div
          id="dayCell"
          className={classNames(dayCellClassNames)}
          onClick={this.props.onClickDay}
        >
          <div id="dayHeader" className={this.returnDayClassStyle()}>
            <div className={this.returnDayTextClass()}>
              <span>{this.props.date.getDate()}</span>
            </div>
          </div>
          {this.props.dataCite[0] && this.matchCite()}
        </div>
      )
    }

    return (
      <div
        id="dayCell"
        className={classNames(dayCellClassNames)}
        onClick={this.props.onClickDay}
      >
        <div id="dayHeader" className={this.returnDayClassStyle()}>
          <div className={this.returnDayTextClass()}>
            <span>{this.props.date.getDate()}</span>
          </div>
        </div>

        {this.returnEventList()}
        {this.returnEventRemainder()}
      </div>
    )
  }
}
