import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { fetchUsers } from '../../../../requests/requests';

export default function UsersView() {
    const [users, setUsers] = useState(undefined);
    const [error, setError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [nameQueryString, setNameQueryString] = useState(undefined);

    useEffect(() => {}, []);

    const onSearchClick = (e) => {
        e.preventDefault();
        if (nameQueryString && nameQueryString !== '') {
            setIsFetching(true);
            fetchUsers(nameQueryString)
                .then((res) => {
                    setUsers(res.content);
                    setIsFetching(false);
                })
                .catch((err) => {
                    setError(true);
                    setIsFetching(false);
                });
        }
    };

    if (error) {
        return <div>error</div>;
    }

    return (
        <div className='mt-2'>
            <Form onSubmit={(e) => onSearchClick(e)} className='row'>
                <Form.Control
                    type='string'
                    placeholder='nombre o apellido'
                    className='col me-3'
                    onChange={(e) => setNameQueryString(e.target.value)}
                />
                <Button
                    variant='primary'
                    className='mt-1 col-3 my-auto'
                    type='submit'
                    disabled={isFetching}
                >
                    {isFetching && (
                        <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                            className='me-2'
                        />
                    )}
                    Buscar
                </Button>
            </Form>

            {!isFetching && users && (
                <div id='usersContainer' className='mt-2'>
                    {users.map((user) => {
                        return <UserView key={user.id} user={user} />;
                    })}
                </div>
            )}
        </div>
    );
}

function UserView({ user }) {
    const history = useHistory();

    const onUserClick = () => {
        history.push(`/admin/manage/users/${user.id}`);
    };

    return (
        <button className='row mt-2 user' onClick={onUserClick}>
            <div className='col-2'>{user.id}</div>
            <div className='col'>{`${user.given_name} ${user.family_name}`}</div>
        </button>
    );
}
