import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { LoadingContext } from 'react-router-loading';
import { fetchPlace } from '../../../requests/requests';
import FirebaseImage from '../../utils/FirebaseImage.jsx';
import { goToMaps } from '../../utils/GoToMaps';
import NotFound from '../../utils/NotFound';
import Spinner from '../../utils/Spinner';
import './place-view.module.scss';
import styles from './place-view.module.scss';

function PlaceView(props) {
    const [place, setPlace] = useState(undefined);
    const [isFetching, setIsFetching] = useState(true);
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        fetchPlace(props.placeId)
            .then((res) => {
                loadingContext.done();
                props.onPlaceFetch(res.content);
                setPlace(res.content);
                setIsFetching(false);
            })
            .catch(() => {
                props.onPlaceNotFound();

                loadingContext.done();
                setIsFetching(false);
            });
    }, []);

    function hasSlogan() {
        return place.slogan && place.slogan.length > 0;
    }

    if (isFetching) {
        return <Spinner />;
    }

    if (!place) {
        return <PlaceSkeleton />;
    }

    return (
        <div className={styles.placeViewContainer}>
            <div className={styles.placeView}>
                <div className={styles.placeImageWrapper}>
                    <div className={styles.placeImage}>
                        <FirebaseImage
                            id={place.id}
                            setImgUrl={props.onImageFetch}
                            imgUrl={props.imageUrl}
                        />
                    </div>
                    <div className={styles.placeImageBackground} />
                </div>

                <div className={styles.placeName}>{place.businessname}</div>
                <div className={styles.placeContact}>
                    <i className='me-2 fas fa-phone'></i>
                    <span>{place.phonenumber}</span>
                </div>
                {hasSlogan() && (
                    <em className={`col ${styles.placeSlogan} my-auto text-light text-center`}>
                        {place.slogan}
                    </em>
                )}
                {place.info && <div className={styles.placeInfo}>{place.info}</div>}
                <div className={styles.goToMapsContainer}>
                    <div
                        onClick={(e) => {
                            goToMaps(e, place.latitude, place.longitude);
                        }}
                        className={`${styles.goToMapsWrapper}`}
                    >
                        <span className={`${styles.goToMaps} btn p-0 my-auto mb-1`} />
                        <span className='my-auto ms-1'>Localizar</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(PlaceView);

function PlaceSkeleton() {
    return <NotFound />;
}
