import { useState, useContext } from "react";
import "./sidebar.scss";
import { CalendarIcon, ClientIcon, PriceIcon } from "../../../images/icons";
import { PlaceContext } from "../PlaceContextProvider";
import FirebaseImage from "../../utils/FirebaseImage";

import Dropdown from "react-bootstrap/Dropdown";
import CircleImage from "../child_components/CircleImage/circle_image";

import { GetUserPlaces } from "../../utils/swr_callbacks";

export default function Sidebar({ active, click }) {
  const { userPlaces, placeSelected, setValues } = useContext(PlaceContext);
  const [image, setImage] = useState(undefined);
  const [idImageCommerce, setIdImageCommerce] = useState(placeSelected.id);

  const onSelectMenu = (place) => {
    setIdImageCommerce(place.id);

    if (placeSelected != place) setValues(place, userPlaces);
  };

  const dropDownItems = userPlaces.map((place) => (
    <Dropdown.Item
      id={place.id}
      key={place.id}
      onClick={() => onSelectMenu(place)}
    >
      {place.businessname}
    </Dropdown.Item>
  ));

  return (
    <div className="d-flex flex-column py-3 sidebar-desktop">
      <div className="d-flex flex-column justify-content-center">
        <div className="rounded-img lg-md border-black mx-auto img-dekstop bg-black">
          <FirebaseImage
            className="img-fluid img-fluid-h"
            id={idImageCommerce}
            setImgUrl={setImage}
          />
        </div>
        <Dropdown className="flex-grow-1 justify-content-center d-flex ">
          <Dropdown.Toggle variant="black w-100 text-truncate general-border-radius">
            {placeSelected.businessname}
          </Dropdown.Toggle>

          <Dropdown.Menu className="w-100 general-border-radius">
            {dropDownItems}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="position-relative" style={{ height: "65%" }}>
        <div className="vertical-line position-absolute"></div>

        <ul className="position-absolute w-100 z-index h-100 d-flex flex-column">
          <li
            className="flex-fill d-flex flex-column justify-content-center cursor-pointer mt-4"
            onClick={() => click("calendar")}
          >
            <div className=" mx-auto img-dekstop">
              <div
                className={`circle-navitem  ${
                  active == "calendar" ? "active" : ""
                }`}
              >
                <CalendarIcon
                  fill={`${active == "calendar" ? "#fff" : "#ff8672"}`}
                  width={40}
                  height={35}
                />
              </div>
            </div>
            {active == "calendar" && (
              <div>
                <div className="flex-grow-1 justify-content-center d-flex bg-primary general-border-radius">
                  <span
                    variant="black w-100 text-truncate "
                    className="fs-5 fw-bold"
                  >
                    Calendario
                  </span>
                </div>
              </div>
            )}
          </li>

          <li
            className="flex-fill d-flex flex-column justify-content-center cursor-pointer my-4"
            onClick={() => click("cobros")}
          >
            <div className=" mx-auto img-dekstop">
              <div
                className={`circle-navitem  ${
                  active == "cobros" ? "active" : ""
                }`}
              >
                <PriceIcon
                  fill={`${active == "cobros" ? "#fff" : "#ff8672"}`}
                  width={40}
                  height={35}
                />
              </div>
            </div>
            {active == "cobros" && (
              <div>
                <div className="flex-grow-1 justify-content-center d-flex bg-primary general-border-radius">
                  <span
                    variant="black w-100 text-truncate "
                    className="fs-5 fw-bold"
                  >
                    Cobros
                  </span>
                </div>
              </div>
            )}
          </li>

          <li
            className="flex-fill d-flex flex-column justify-content-center cursor-pointer "
            onClick={() => click("clients")}
          >
            <div className=" mx-auto img-dekstop">
              <div
                className={`circle-navitem  ${
                  active == "clients" ? "active" : ""
                }`}
              >
                <ClientIcon
                  fill={`${active == "clients" ? "#fff" : "#ff8672"}`}
                  width={40}
                  height={35}
                />
              </div>
            </div>
            {active == "clients" && (
              <div>
                <div className="flex-grow-1 justify-content-center d-flex bg-primary general-border-radius">
                  <span
                    variant="black w-100 text-truncate "
                    className="fs-5 fw-bold"
                  >
                    Clientes
                  </span>
                </div>
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}
