import React from 'react';
import FirebaseImage from '../../utils/FirebaseImage';
import GoToMaps from '../../utils/GoToMaps';
import styles from './place-view.module.scss';

export default function PlaceViewCollapsed({ place, imageUrl }) {
    return (
        <div className={styles.placeViewCollapsedContainer}>
            <div className={`row p-1 ${styles.placeViewCollapsed} mx-auto`}>
                <div
                    className={`${styles.placeImageCollapsed} col-3 ms-3 text-start overflow-hidden p-0`}
                >
                    <FirebaseImage id={place.id} imgUrl={imageUrl} />
                </div>
                <div className={`col-7 fw-bold my-auto text-start ${styles.placeNameCollapsed}`}>
                    {place.businessname}
                </div>
                <div className='col-2 fw-bold my-auto'>
                    <GoToMaps lat={place.latitude} lon={place.longitude} />
                </div>
            </div>
        </div>
    );
}
