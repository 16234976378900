import { useEffect, useState } from "react"
import "./cite_detail_row.scss"

import { AiOutlineInfoCircle } from "react-icons/ai"

import { Container, Col, Row, OverlayTrigger, Popover } from "react-bootstrap"
import { fetchGetLabels } from "../../../../requests/requests"
import { getDate, toIsoStringDate, formatDate } from "../../utils/separateDate"
import { filterLabelById, getColorDefault } from "../../utils/statusCite"
import Loading from "../Loading/loading"

export default function CiteDetailRow({ data }) {
  const [isLoading, setIsLoading] = useState(false)
  const { placeID, jobID, cite, labels } = data

  function getDate() {
    const date = cite.start.split("T")[0]

    const day = date.split("-")[2]
    const month = date.split("-")[1]
    const year = date.split("-")[0]

    return `${day}/${month}/${year}`
  }

  const displayHour = () => {
    let fromDate = new Date(cite.start)
    fromDate.setHours(fromDate.getHours() - fromDate.getTimezoneOffset() / 60)

    function formatDate(dateStr) {
      let date = new Date(Date.parse(dateStr))
      date.setHours(date.getHours() + date.getTimezoneOffset() / 60)
      let hours = date.getHours()
      let minutes = date.getMinutes()

      if (hours < 10) {
        hours = "0" + hours
      }
      if (minutes < 10) {
        minutes = "0" + minutes
      }

      return `${hours}:${minutes}`
    }

    function formatStartEndDates(from, to) {
      return `${formatDate(from)}`
    }

    return formatStartEndDates(fromDate)
  }
  const [label, setLabel] = useState("#ff8672")

  useEffect(() => {
    if (!cite.label) {
      const label = getColorDefault(cite.start, labels)
      setLabel(label)
    } else {
      const label = filterLabelById(cite.label, labels)
      setLabel(label)
    }
  }, [])

  /****RENDERS */
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {!cite.observation ? <>No hay observaciones.</> : cite.observation}
      </Popover.Body>
    </Popover>
  )

  return (
    <div className="bg-gray general-border-radius d-flex flex-column">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="bg-gray-light  general-border-radius d-flex justify-content-between  px-1 ">
            <div className="d-flex align-items-center gap-2">
              <span
                className="circle-info-white d-inline"
                style={{ backgroundColor: label.color }}
              ></span>
              <span className="text-white fs-7">
                <span style={{ color: label.color }}>{label.name}</span>
              </span>
            </div>
            <OverlayTrigger key="left" placement="left" overlay={popover}>
              <span>
                <AiOutlineInfoCircle size={20} role="button" />
              </span>
            </OverlayTrigger>
          </div>
          <Container fluid>
            <Row gap={2} className="fs-7">
              <Col xs={4} style={{ borderRight: `1px solid ${label.color}` }}>
                {displayHour()}
              </Col>
              <Col xs={4} style={{ borderRight: `1px solid ${label.color}` }}>
                {getDate()}
              </Col>
              <Col xs={4} className="text-nowrap">
                {cite.service_names.length >= 20 ? (
                  <span>
                    <marquee scrolldelay="200">{cite.service_names}</marquee>
                  </span>
                ) : (
                  cite.service_names
                )}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  )
}
