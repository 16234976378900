import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { scrollYTo, Easing } from "ezscroll";

import "./home.scss";

import logo from "../../logo.svg";
import chevron from "../../images/main/chevron.svg";
import { useInView } from "react-intersection-observer";
import { fetchCategories } from "../../requests/requests";
import { useHistory } from "react-router-dom";

import Radium, { StyleRoot } from "radium";
import { fadeInDown } from "react-animations";

import homeVideoHighRes from "../../videos/main.mp4";
import homeVideoLowRes from "../../videos/main_1080.mp4";
import mainThum from "../../videos/main_thum.png";

import { useLayoutEffect } from "react";

import horizontalLogo from "../../images/main/logo_horizontal.svg";
const styles = {
  fadeInDown: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInDown, "fadeInDown"),
  },
};

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    if (size[0] === 0) {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }
  }, []);
  return size;
}

export default function HomeView(props) {
  const categoriesRef = useRef();
  const [, windowHeight] = useWindowSize();
  const [page2Position, setPage2Position] = useState(-1);
  const [triggerRef, triggerIsVisible] = useInView({
    threshold: 0.93,
  });

  useEffect(() => {
    props.showNav(!triggerIsVisible);
  }, [triggerIsVisible]);

  const getLogo = () => {
    return <img src={logo} id="logo" width="" alt="" />;
  };

  useEffect(() => {
    setPage2Position(
      categoriesRef.current.getBoundingClientRect().top + window.scrollY + 1
    );
  }, []);

  const scrollToCategories = () => {
    scrollYTo(page2Position, 300, Easing.Cubic.InOut);
  };

  return (
    <StyleRoot className="h-100">
      <div className="mb-5 h-100" id="home-page">
        <div
          id="page-1"
          style={{
            height: windowHeight,
          }}
        >
          {/* <div id='headerLogo' style={styles.fadeInDown}>
                        {getLogo()}
                    </div> */}
          <HomeVideo />

          <div style={styles.bounce} id="chevron-wrapper">
            <img id="chevron" src={chevron} onClick={scrollToCategories} />
          </div>
          <div id="logoLine" />
        </div>

        <div id="page-2">
          <span className="triggerNav" ref={triggerRef} />
          <span className="triggerNav" ref={categoriesRef} />

          <HowDoesItWork />
        </div>
        <div id="page-3" className="position-relative">
          <Categories showNav={props.showNav} />
        </div>
        <div id="page-4">
          <RegisterBusiness />
        </div>
        <div id="page-5">
          <WhoAreWe />
        </div>
      </div>
    </StyleRoot>
  );
}
function HomeVideo(props) {
  const [video, setVideo] = useState(undefined);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    const getVideo = () => {
      if (window.innerWidth >= 720) return homeVideoHighRes;
      return homeVideoLowRes;
    };

    setVideo(getVideo());
  }, []);

  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div>
      <div id="mobile-bar">
        <img src={horizontalLogo} />
      </div>
      <div id="home-video-container">
        {!isVideoLoaded && (
          <img src={mainThum} id="home-video-thumb" alt="thumb" />
        )}
        <video
          autoPlay
          playsInline
          muted
          id="home-video"
          className={isVideoLoaded ? "shown" : "not-shown"}
          src={video}
          onLoadedData={onLoadedData}
        />
      </div>
    </div>
  );
}

function HowDoesItWork() {
  return (
    <div id="how-does-it-work" className="py-4">
      <div id="header" className="pb-4">
        <span className="px-2 sub-title">
          Seguí los pasos como cliente para conseguir un turno
        </span>
        <span className="title">¿Cómo funciona?</span>
      </div>
      <Steps
        folderPath={require.context(
          "../../images/main/how_it_works/",
          false,
          /\.(svg)$/
        )}
      />
    </div>
  );
}

function Steps({ folderPath }) {
  const [steps, setSteps] = useState(undefined);

  function importAll(r) {
    const imagesAux = {};

    r.keys().forEach((item, index) => {
      imagesAux[item.replace("./", "").replace(".svg", "")] = r(item).default;
    });

    return imagesAux;
  }

  useEffect(() => {
    async function fetchImages() {
      const images = importAll(folderPath);

      setSteps(images);
    }

    fetchImages();
  }, []);

  return (
    <div className="steps-container mt-1">
      {steps && (
        <>
          {Object.keys(steps).map((key, index) => {
            return <img src={steps[key]} key={index} className="step mx-3" />;
          })}
        </>
      )}
    </div>
  );
}

function Categories({ showNav }) {
  const [categories, setCategories] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState(false);
  const [images, setImages] = useState({});
  const history = useHistory();

  useEffect(() => {
    fetchCategories()
      .then((res) => {
        setCategories(res.content);
        setIsFetching(false);
        fetchImages();
      })
      .catch((err) => {
        setError(true);
        setIsFetching(false);
      });
  }, []);

  function importAll(r) {
    const imagesAux = {};

    r.keys().forEach((item, index) => {
      imagesAux[item.replace("./", "").replace(".jpg", "")] = r(item).default;
    });

    return imagesAux;
  }

  const fetchImages = () => {
    const images = importAll(
      require.context("../../images/main/categories/", false, /\.(jpg)$/)
    );

    setImages(images);
  };

  const onCategorySelected = (cat) => {
    showNav(true);
    window.category = { name: cat.category_name, id: cat.id };
    history.push(`categories/${cat.id}/job-types`);
  };

  const returnCategoriesView = () => {
    return (
      <div className="mx-auto justify-content-center" id="container-categories">
        {categories.map((cat, i) => {
          return (
            <div
              key={i}
              className="category"
              onClick={() => onCategorySelected(cat)}
            >
              <div className="category-image-container">
                <img className="card-img-top" src={images[cat.id]} />
              </div>
              <div className="text-center category-name">
                {cat.category_name}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const returnHeader = () => {
    return (
      <div className="text-center pt-3 mb-3" id="title-categories">
        <div className="title">Sacá tu turno</div>
        <span className="sub-title">
          Elegí el rubro para conocer los establecimientos disponibles
        </span>
      </div>
    );
  };

  return (
    <section className="page-section pb-4" id="portfolio">
      <div className="containerCategories">
        {returnHeader()}
        {!isFetching && returnCategoriesView()}
        {isFetching && <SkeletonCategories />}
      </div>
    </section>
  );
}

function SkeletonCategories() {
  return null;
}

function RegisterBusiness() {
  return (
    <div id="register-business" className="my-4">
      <span className="title">¿Sos un comercio o empresa?</span>

      <span className="px-2 sub-title mb-2">
        Seguí los pasos, registrate y empeza a organizar tus turnos de
        <b> forma gratuita</b>
      </span>

      <Steps
        folderPath={require.context(
          "../../images/main/register_business/",
          false,
          /\.(svg)$/
        )}
      />
    </div>
  );
}

function WhoAreWe() {
  return (
    <div id="who-are-we" className="py-4">
      <div id="who-are-we-img" className="mt-4" />
      <span className="title">¿Quiénes somos y qué hacemos?</span>
      <div id="description" className="mt-2">
        Somos una empresa destinada a <span>brindar servicio de turnos</span>{" "}
        para locales, empresas o cualquier emprendimiento en el que dar y
        recibir los mismos, desde los clientes, es una{" "}
        <span>parte crucial en su día a día</span>.
        <br />
        Esto lo realizamos <span>
          mediante una aplicación para celulares
        </span>{" "}
        que se encuentra disponible de <span>forma gratuita</span> y que está
        destinada a los dueños de esos lugares. Su función básica es la de{" "}
        <span>actuar como un asistente virtual</span> adaptable para cualquier
        rubro o sector.
        <br />
        El mismo puede <span>administrar tu agenda</span>, generar un canal de{" "}
        <span>comunicación con los clientes</span>, ordenar tu día y
        primordialmente, tiene la <span>finalidad de descongestionar</span> esa
        ardua tarea que gestionar turnos y clientes demanda.
      </div>
    </div>
  );
}
