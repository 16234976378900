import { useState } from "react"
import { Button, Container, Row, Col, Spinner } from "react-bootstrap"

import {
  AiOutlineArrowLeft,
  AiOutlineTag,
  AiOutlineClockCircle,
} from "react-icons/ai"
import { RiUserStarLine, RiUser2Line } from "react-icons/ri"
import { IoTicketOutline } from "react-icons/io5"
import { MdLabelOutline } from "react-icons/md"

import { fetchPostCreateCite } from "../../../../requests/requests"
import Loading from "../Loading/loading"

import dateFormat from "dateformat"

export default function StepTwo({ dataPreview, className }) {
  const [isLoading, setIsLoading] = useState(false)

  if (!dataPreview.sendData) {
    return <div className={className}>Formulario incompleto!</div>
  }

  const { forSend, forPreview } = dataPreview.sendData

  const send = () => {
    setIsLoading(true)
    fetchPostCreateCite(forSend).then((res) => {
      setIsLoading(false)

      dataPreview.setResultFecth({
        result: res.result,
        message: res.message,
        setReload: dataPreview.setReload,
        daySelected: forPreview.dayFormatSelected,
        employedSelected: forPreview.employedSelected,
      })
      dataPreview.setStep(3)
    })
  }
  return (
    <>
      <Container
        className={`text-dark flex-fill d-flex flex-column gap-3 ${className} p-4`}
      >
        <div className="d-flex text-gray align-items-center gap-2 justify-content-between">
          <span
            className="d-flex align-items-center gap-2"
            role="button"
            onClick={() => dataPreview.setStep(1)}
          >
            <AiOutlineArrowLeft fill="#000" size={25} />
            <span>Retroceder</span>
          </span>
        </div>

        <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
          <Row>
            <h1>Resumen</h1>
          </Row>
          <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
            <Row className="g-2">
              <Col xs={6}>
                <article className="h-100 rounded-3 justify-content-center  text-center border border-primary">
                  <div className="bg-primary text-center text-white">
                    Servicio
                  </div>
                  <div className=" p-3 d-flex flex-column gap-3">
                    <div>
                      <AiOutlineTag fill="#000" size={40} />
                    </div>
                    <h5>{forPreview.serviceName}</h5>
                  </div>
                </article>
              </Col>
              <Col xs={6}>
                <article className="h-100 rounded-3 justify-content-center  text-center border border-primary">
                  <div className="bg-primary text-center text-white">
                    Empleado
                  </div>
                  <div className=" p-3 d-flex flex-column gap-3">
                    <div>
                      <RiUserStarLine fill="#000" size={40} />
                    </div>
                    <h5>{forPreview.employedName}</h5>
                  </div>
                </article>
              </Col>
              <Col xs={6}>
                <article className="h-100 rounded-3 justify-content-center  text-center border border-primary">
                  <div className="bg-primary text-center text-white">
                    Cliente
                  </div>
                  <div className=" p-3 d-flex flex-column gap-3">
                    <div>
                      <RiUser2Line fill="#000" size={40} />
                    </div>
                    <h5>{forPreview.clientName}</h5>
                  </div>
                </article>
              </Col>
              <Col xs={6}>
                <article className="h-100 rounded-3 justify-content-center  text-center border border-primary">
                  <div className="bg-primary text-center text-white">
                    Fecha y hora
                  </div>
                  <div className=" p-3 d-flex flex-column gap-3">
                    <div>
                      <AiOutlineClockCircle fill="#000" size={40} />
                    </div>
                    <h5>
                      {forPreview.daySelected}{" "}
                      <span className="text-dark">a las</span>{" "}
                      {forPreview.timeSelected}
                    </h5>
                  </div>
                </article>
              </Col>
              <Col xs={6}>
                <article className="h-100 rounded-3 justify-content-center  text-center border border-primary">
                  <div className="bg-primary text-center text-white">
                    Etiqueta
                  </div>
                  <div className=" p-3 d-flex flex-column gap-3">
                    <div>
                      <MdLabelOutline fill="#000" size={40} />
                    </div>
                    <h5
                      className="p-2 general-border-radius text-white"
                      style={{ backgroundColor: forPreview.label.color }}
                    >
                      {forPreview.label.name}
                    </h5>
                  </div>
                </article>
              </Col>
              <Col xs={6}>
                <article className="h-100 rounded-3 justify-content-center  text-center border border-primary">
                  <div className="bg-primary text-center text-white">
                    Descontar credito
                  </div>
                  <div className=" p-3 d-flex flex-column gap-3">
                    <div>
                      <IoTicketOutline fill="#000" size={40} />
                    </div>
                    <h5>{forPreview.discountCredit ? "Si" : "No"}</h5>
                  </div>
                </article>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <Button
        disabled={isLoading}
        variant="primary"
        className={`fs-4 fw-bold ${className} text-white`}
        onClick={send}
      >
        {isLoading ? (
          <div className="d-flex align-items-center gap-2 w-100 text-center justify-content-center">
            <Spinner size="sm" animation="border" variant="white" />
            Registrando
          </div>
        ) : (
          "Confirmar"
        )}
      </Button>
    </>
  )
}
