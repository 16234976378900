import React, { useContext, useEffect, useState } from 'react';
import NotificationMethod from '../notification_method/NotificationMethod';
import { AuthContext } from './AuthContextProvider';

export default function NewUser(props) {
    const authContext = useContext(AuthContext);
    const [finished, setFinished] = useState(false);
    const [finishTimer, setFinishTimer] = useState(8);
    const [timerId, setTimerId] = useState(undefined);

    useEffect(() => {
        if (finished) {
            if (finishTimer > 0) {
                setTimerId(setInterval(countDown, 1000));
            } else {
                clearInterval(timerId);
                setNotNewUser();
            }
        }
    }, [finished, finishTimer]);

    const setNotNewUser = () => {
        let googleUser = authContext.googleUser;
        if (googleUser.is_new_user) {
            googleUser.is_new_user = false;
            authContext.setGoogleUser(googleUser);
        }
    };

    const countDown = () => {
        setFinishTimer(finishTimer - 1);
    };

    const onFinish = () => {
        setFinished(true);
    };

    return (
        <div>
            <div className='h4 px-5 mt-5 pt-5 fw-bold'>{`¡Hola ${authContext.googleUser.givenName}!`}</div>
            <div className='px-4 text-light'>
                <em>{`Antes que nada, necesitamos saber cómo comunicarnos con vos`}</em>
            </div>
            <NotificationMethod onFinish={onFinish} />
            {finished && (
                <div className='mt-2 pt-2'>{`Se te redireccionará en ${finishTimer} segundos`}</div>
            )}
        </div>
    );
}
