import { useContext, useEffect, useState, forwardRef } from "react";
import { PlaceContext } from "../PlaceContextProvider";

import "./sidebar_mobile.scss";
import { CalendarIcon, ClientIcon, PriceIcon } from "../../../images/icons";

import { Dropdown, Spinner } from "react-bootstrap";
import CircleImage from "../child_components/CircleImage/circle_image";
import FirebaseImage from "../../utils/FirebaseImage";

import { GetUserPlaces } from "../../utils/swr_callbacks";

export default function SidebarMobile({ active, click }) {
  const { userPlaces, placeSelected, setValues } = useContext(PlaceContext);
  const [image, setImage] = useState(undefined);
  const [idImageCommerce, setIdImageCommerce] = useState(placeSelected.id);

  const onSelectMenu = (place) => {
    setIdImageCommerce(place.id);

    if (placeSelected != place) setValues(place, userPlaces);
  };

  const dropDownItems = userPlaces.map((place) => (
    <Dropdown.Item
      id={place.id}
      key={place.id}
      onClick={() => onSelectMenu(place)}
    >
      {place.businessname}
    </Dropdown.Item>
  ));

  return (
    <>
      <section className="p-3">
        <div className="d-flex align-items-center position-relative">
          <Dropdown className="flex-grow-1">
            <Dropdown.Toggle
              className={`bg-black text-white w-90 general-border-radius d-flex align-items-center justify-content-center float-end border-black`}
            >
              {placeSelected.businessname}
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-100 general-border-radius">
              {dropDownItems}
            </Dropdown.Menu>
          </Dropdown>
          <div className="position-absolute start-0" style={{ zIndex: "1000" }}>
            <div
              className="rounded-img md border-black bg-black"
              style={{ zIndex: "1000" }}
            >
              <FirebaseImage
                className="img-fluid img-fluid-h"
                id={idImageCommerce}
                setImgUrl={setImage}
              />
            </div>
          </div>
        </div>
      </section>
      <nav className="nav-mobile">
        <div className="nav-items d-flex w-100 justify-content-center">
          <span className="flex-fill d-flex justify-content-center">
            <div
              onClick={() => click("calendar")}
              className={`circle-navitem cursor-pointer ${
                active == "calendar" ? "active" : ""
              }`}
            >
              <CalendarIcon
                fill={`${active == "calendar" ? "#fff" : "#ff8672"}`}
                width={20}
                height={25}
              />
            </div>
          </span>

          <span className="flex-fill d-flex justify-content-center">
            <div
              onClick={() => click("cobros")}
              className={`circle-navitem cursor-pointer ${
                active == "cobros" ? "active" : ""
              }`}
            >
              <PriceIcon
                fill={`${active == "cobros" ? "#fff" : "#ff8672"}`}
                width={20}
                height={25}
              />
            </div>
          </span>
          <span className="flex-fill d-flex justify-content-center">
            <div
              onClick={() => click("clients")}
              className={`circle-navitem cursor-pointer ${
                active == "clients" ? "active" : ""
              }`}
            >
              <ClientIcon
                fill={`${active == "clients" ? "#fff" : "#ff8672"}`}
                width={20}
                height={25}
              />
            </div>
          </span>
        </div>
      </nav>
    </>
    //   <div className="d-flex align-items-center position-relative sidebar-mobile">
    //   <Dropdown
    //   className="flex-fill ">
    //     <Dropdown.Toggle as={CustomToggle}>
    //       <div className='rounded-img lg border-primary'>
    //         <FirebaseImage className="img-fluid img-fluid-h" id={idImageCommerce} setImgUrl={setImage} />
    //       </div>
    //     </Dropdown.Toggle>

    //     <Dropdown.Menu className="w-50">
    //       {dropDownItems}
    //     </Dropdown.Menu>
    //   </Dropdown>

    //   <span className="flex-fill d-flex justify-content-center">
    //     <div
    //     onClick={() => click('calendar')}
    //     className={`circle-navitem cursor-pointer ${active == 'calendar'? 'active':''}`}>
    //       <CalendarIcon fill={`${active == 'calendar'? '#fff':'#ff8672'}`} width={20} height={25}/>
    //     </div>
    //   </span>

    //   <span className="flex-fill d-flex justify-content-center">
    //     <div
    //     onClick={() => click('cobros')}
    //     className={`circle-navitem cursor-pointer ${active == 'cobros'? 'active':''}`}>
    //       <PriceIcon fill={`${active == 'cobros'? '#fff':'#ff8672'}`} width={20} height={25}/>
    //     </div>
    //   </span>
    //   <span className="flex-fill d-flex justify-content-center">
    //     <div
    //     onClick={() => click('clients')}
    //     className={`circle-navitem cursor-pointer ${active == 'clients'? 'active':''}`}>
    //       <ClientIcon fill={`${active == 'clients'? '#fff':'#ff8672'}`} width={20} height={25}/>
    //     </div>
    //   </span>

    // </div>
  );
}

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    className=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));
