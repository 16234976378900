import Lottie from "lottie-react"
import check from "../../../../lottie/confetti.json"
import { BiErrorCircle } from "react-icons/bi"

import { Button } from "react-bootstrap"

export default function ResultCite({ resultFetch, className, data }) {
  if (!resultFetch)
    return <div className={className}>Formulario incompleto!</div>
  const { result, message } = resultFetch

  const viewCite = () => {
    data.setIsOpen(false)
    data.setEmployedSelected(resultFetch.employedSelected)
    setTimeout(() => {
      data.setActionDay(resultFetch.daySelected)
    }, 500)
  }

  const success = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: check,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }

    return (
      <div className="d-flex justify-content-center gap-2 flex-column align-items-center">
        <div className="lottie">
          <Lottie animationData={check} loop={false} autoPlay={true} />
        </div>

        <div className="h5 mt-3">
          Su reserva fue registrada satisfactoriamente
        </div>
        <Button
          variant="outline-primary general-border-radius"
          onClick={viewCite}
        >
          VER TURNO
        </Button>
      </div>
    )
  }

  const ops = () => {
    if (message === "Appointments are no longer available")
      return (
        <div className="text-red d-flex flex-column justify-content-center text-center">
          <div className="text-red">
            <BiErrorCircle size={80} fill="#ff0000" />
          </div>
          <p className="my-3">Ops! al parecer no hay turnos disponibles.</p>
          <p className="text-light">Vuelve a intentarlo.</p>
        </div>
      )

    return (
      <div className="text-red d-flex flex-column justify-content-center text-center">
        <div>
          <BiErrorCircle size={80} />
        </div>
        <p>Ops! al pareceralgo salio mal.</p>
        <p>Vuelve a intentarlo.</p>
      </div>
    )
  }
  return (
    <div
      className={`text-dark flex-fill d-flex justify-content-center text-center align-items-center ${className} p-4`}
    >
      {result && success()}
      {!result && ops()}
    </div>
  )
}
