import { useEffect, useState } from 'react';
import haversine from 'haversine-distance';

export default function Distance({ lat, lon }) {
    const [position, setPosition] = useState(null);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setPosition(position);
        });
    }, []);

    const calcDistance = () => {
        const placeLocation = { latitude: lat, longitude: lon };
        const userLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
        };

        return haversine(placeLocation, userLocation);
    };

    const formatDistance = (distance) => {
        if (distance < 1000) {
            return `A ${Math.trunc(distance)}m`;
        }

        return `A ${Math.round(distance / 10) / 100}km`;
    };

    if (position) {
        return <div>{formatDistance(calcDistance())}</div>;
    }
    return null;
}
