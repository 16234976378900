import React, { useState } from 'react';
import { useRef } from 'react';

import './menubutton.scss';

export default function MenuButton({ expanded }) {
    const navTriggerRef = useRef(undefined);

    return (
        <div className='w-100 h-100 position-relative' id='menu-button'>
            <div className={`navTrigger ${expanded ? 'active' : ''}`} ref={navTriggerRef}>
                <i></i>
                <i></i>
                <i></i>
            </div>
        </div>
    );
}
