import { useContext, useState } from 'react';
import { LoadingContext } from 'react-router-loading';
import './notificationMethod.scss';
import { useEffect } from 'react';

import { setNotificationMethod } from '../../requests/requests';
import { Link } from 'react-router-dom';

export default function NotificationMethod(props) {
    const loadingContext = useContext(LoadingContext);
    const [method, setMethod] = useState('');
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(undefined);
    const [isValidating, setIsValidating] = useState(false);
    const [done, setDone] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        loadingContext.done();
    }, []);

    function onConfirm() {
        setIsValidating(true);
    }

    function onValidate(result) {
        setIsValidating(false);

        if (result) {
            setIsLoading(true);

            setNotificationMethod(method, data)
                .then(() => {
                    setDone(true);
                    setIsLoading(false);

                    if (props.onFinish) props.onFinish();
                })
                .catch((err) => {
                    setError(true);
                    setIsLoading(false);
                    console.log(err);
                });
        }
    }

    function setNotMethod(method) {
        setMethod(method);
        setIsValidating(false);
    }

    if (error) {
        return <div>Se produjo un error, reinténtelo nuevamente</div>;
    }

    if (done) {
        return (
            <div className='mx-auto container-sm not-method-container justify-content-center px-0 mt-5 pt-3'>
                <div className='mt-5 px-5 text-white p-2 mb-5'>{`Se ha guardado el método de contacto por ${method} con éxito.`}</div>
                <Link to='/' id='btnMainPage'>
                    <span>ir a la página principal</span>
                </Link>
            </div>
        );
    }

    return (
        <div className='mx-auto container-sm not-method-container justify-content-center px-0 mt-5 pt-3'>
            <div className='h4 labelNotMethod fw-bold text-white mx-auto text-center ps-2 mt-5'>
                MÉTODO DE CONTACTO
            </div>
            <WhatsappMethod
                setMethod={setNotMethod}
                setData={setData}
                selected={method === 'whatsapp'}
                isValidating={isValidating}
                validated={onValidate}
            />

            <EmailMethod
                setMethod={setNotMethod}
                setData={setData}
                selected={method === 'email'}
                isValidating={isValidating}
                validated={onValidate}
            />

            <button
                className='btn btn-primary mt-5 px-5 text-white'
                onClick={onConfirm}
                disabled={method === ''}
            >
                <span>
                    {isLoading && (
                        <span
                            className='spinner-border spinner-border-sm text-white me-3'
                            role='status'
                            aria-hidden='true'
                        />
                    )}
                </span>
                <span>Confirmar</span>
            </button>
        </div>
    );
}

function WhatsappMethod(props) {
    const [number, setNumber] = useState('');
    const [invalidNumber, setInvalidNumber] = useState(false);
    const [selected, setSelected] = useState(props.selected);

    useEffect(() => {
        if (props.isValidating && props.selected) {
            if (validateNumber(number)) {
                props.validated(true);
                setInvalidNumber(false);
            } else {
                props.validated(false);
                setInvalidNumber(true);
            }
        }
    }, [props.isValidating]);

    function onSelected() {
        setSelected(selected);
        props.setMethod('whatsapp');
    }

    function onChange(event) {
        setNumber(event.target.value);
        props.setData(event.target.value);
    }

    function validateNumber(num) {
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return num.match(phoneno);
    }

    return (
        <div
            className={`row not-method container-sm px-0 m-auto  py-2 text-start ${
                props.selected ? 'selected' : ''
            }`}
        >
            <div className='col-2 d-inline-block my-auto'>
                <SelectedTick setSelected={onSelected} selected={props.selected} />
            </div>
            <div className='col-2 my-auto not-method-img whatsapp' />
            {props.selected && (
                <div className='col pe-2 ps-0 my-auto'>
                    <div className='my-auto text-start ps-0'>
                        <div className='input-group has-validation'>
                            <span className='input-group-text ps-0' id='inputGroupPrepend'>
                                +54
                            </span>
                            <input
                                onChange={onChange}
                                type='tel'
                                className={'form-control ' + (invalidNumber ? 'is-invalid' : '')}
                                id='inputWhatsapp'
                                aria-describedby='inputGroupPrepend'
                                required
                            />
                            <div
                                className={
                                    'text-center invalid-feedback ' +
                                    (invalidNumber && props.selected ? 'd-block' : '')
                                }
                            >
                                Ingresá un número de teléfono válido, sin anteponer el 15
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!props.selected && <div className='col ms-4 my-auto'>Whatsapp</div>}
        </div>
    );
}

function EmailMethod(props) {
    const [selected, setSelected] = useState(props.selected);

    useEffect(() => {
        if (props.isValidating && props.selected) {
            props.setData(undefined);
            props.validated(true);
        }
    }, [props.isValidating]);

    function onSelected(selected) {
        setSelected(selected);
        props.setMethod('email');
    }

    return (
        <div
            className={`row px-0 mt-4 not-method container-sm  py-2 text-start ${
                props.selected ? 'selected' : ''
            }`}
        >
            <div className='col-2 d-inline-block my-auto'>
                <SelectedTick setSelected={onSelected} selected={props.selected} />
            </div>
            <div className='col-2 my-auto not-method-img email' />
            {props.selected && (
                <div className='col ms-4 my-auto'>Utilizaremos el mail de tu cuenta</div>
            )}
            {!props.selected && <div className='col ms-4 my-auto'>Email</div>}
        </div>
    );
}

function SelectedTick(props) {
    const [selected, setSelected] = useState(props.selected);

    function onSelected() {
        props.setSelected(!selected);
        setSelected(!selected);
    }

    return (
        <div className='selectedTick my-auto' onClick={onSelected}>
            {props.selected ? (
                <div className='tick checked' alt='uncheck' />
            ) : (
                <div className='tick unchecked' alt='check' />
            )}
        </div>
    );
}
