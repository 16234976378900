export default function getListdaysOpen(requestDays) {
  //Esta función devuelve los días que un empleado no trabaja.

  const daysOpen = [];
  for (const i in requestDays) {
    if (requestDays[i].day_schedule.day_of_week == 1)
      daysOpen.push("Sun"); //Domingo
    else if (requestDays[i].day_schedule.day_of_week == 2) daysOpen.push("Mon");
    else if (requestDays[i].day_schedule.day_of_week == 3) daysOpen.push("Tue");
    else if (requestDays[i].day_schedule.day_of_week == 4) daysOpen.push("Wed");
    else if (requestDays[i].day_schedule.day_of_week == 5) daysOpen.push("Thu");
    else if (requestDays[i].day_schedule.day_of_week == 6) daysOpen.push("Fri");
    else if (requestDays[i].day_schedule.day_of_week == 7) daysOpen.push("Sat");
  }

  return daysOpen;
}

export function convertIntDaysInStr(requestDays) {
  //Esta función devuelve los días que un empleado no trabaja.

  const daysOpen = [];
  for (const i in requestDays) {
    if (requestDays[i] == 1) daysOpen.push("Sun"); //Domingo
    else if (requestDays[i] == 2) daysOpen.push("Mon");
    else if (requestDays[i] == 3) daysOpen.push("Tue");
    else if (requestDays[i] == 4) daysOpen.push("Wed");
    else if (requestDays[i] == 5) daysOpen.push("Thu");
    else if (requestDays[i] == 6) daysOpen.push("Fri");
    else if (requestDays[i] == 7) daysOpen.push("Sat");
  }

  return daysOpen;
}
