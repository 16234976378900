import React from 'react';
import { getMonthName } from './util/calendar';
import './index.styles.scss';

import nextIcon from '../../../images/next.svg';

export default class Header extends React.PureComponent {
    returnTitle() {
        const { mode, current } = this.props;
        const { year, month, day } = current;
        const monthName = getMonthName(month);

        return (
            <div className='title mx-auto'>
                <span className='thickText monthName me-0 pe-0 my-auto text-end'>{monthName}</span>
                &nbsp;
                <span className='thinText ps-0 ms-0 my-auto text-start'>{year}</span>
            </div>
        );
    }

    returnButtonText(type) {
        const monthName = getMonthName(type.month);

        return monthName;
    }

    render() {
        return (
            <div className='calendarHeader'>
                <div className='calendarHeaderButtons row'>
                    <button
                        id='btnPrev'
                        className='col-2 m-0 p-0 row'
                        onClick={this.props.onClickPrev}
                    >
                        <img src={nextIcon} className='col p-0  my-auto' alt='next' />
                    </button>
                    <div className='col-8 mb-0'>{this.returnTitle()}</div>
                    <button
                        id='btnNext'
                        className='col-2 m-0 p-0 row'
                        onClick={this.props.onClickNext}
                    >
                        <img src={nextIcon} className='col p-0 my-auto' alt='prev' />
                    </button>
                </div>
            </div>
        );
    }
}
