import { useContext, useEffect, useState } from "react"

import "./my_shop_clients.scss"

import { BsCalendarX } from "react-icons/bs"
import { HiCursorClick } from "react-icons/hi"
import { Col, Spinner } from "react-bootstrap"

import SelectClient from "../../child_components/SelectClient/select_client"

import LayoutParent from "../../layouts/parent"
import ChangeEmployed from "../../child_components/ChangeEmployed/change_employed"
import SearchBar from "../../child_components/SearchBar/search_bar"
import ItemAccordion from "../../child_components/ItemAccordion/item_accordion"
import FilterByDate from "../../child_components/FilterByDate/filter_by_date"
import CLientInfo from "../../child_components/ClientInfo/client_info"
import CreditDetailRow from "../../child_components/CreditDetailRow/credit_detail_row"
import CiteDetailRow from "../../child_components/CiteDetailRow/cite_detail_row"
import Loading from "../../child_components/Loading/loading"

import {
  fetchGetFilterCreditsByDate,
  fetchGetLabels,
  fetchGetClientsByEmployed,
  fetchGetFilterCitesByDate,
  fetchGetCitesByClientID,
} from "../../../../requests/requests"
import { GetEmployeds } from "../../../utils/swr_callbacks"
import { PlaceContext } from "../../PlaceContextProvider"
import objectsInArray from "../../utils/objectsInArray"
import { getEmployedByService } from "../../utils/request"
import { Breakpoint } from "react-socks"
import SelectClientResponsive from "../../child_components/SelectClientResponsive/select_client_responsive"
import { AuthContext } from "../../../auth/AuthContextProvider"

export default function MyShopClients() {
  const { placeSelected } = useContext(PlaceContext)
  const authContext = useContext(AuthContext)

  const [clients, setClients] = useState([])
  const { employeds, isLoading, isError } = GetEmployeds(placeSelected.id)

  const [employedSelected, setEmployedSelected] = useState(undefined)
  const [clientSelected, setClientSelected] = useState(undefined)
  const [citesForClient, setCitesForClient] = useState([])
  const [creditsForClient, setCreditsForClient] = useState([])
  const [filterActive, setFilterActive] = useState(true)
  const [isLoadingCites, setIsLoadingCites] = useState(false)
  const [isLoadingCredits, setIsLoadingCredits] = useState(false)
  const [jobID, setJobID] = useState(undefined)
  const [isLoadingClients, setIsLoadingClients] = useState([])
  const [labels, setLabels] = useState([])
  const [isLoadingDataEmployed, setIsLoadingDataEmployed] = useState(true)
  const [filterByDate, setFilterByDate] = useState(undefined)
  const [filterByDateCredits, setFilterByDateCredits] = useState(undefined)
  const [placeEmployeds, setPlaceEmployeds] = useState([])

  useEffect(() => {
    //Change commerce reload data
    setEmployedSelected(undefined)
  }, [placeSelected])

  useEffect(() => {
    if (!isLoading) {
      if (!employedSelected) {
        const userSession = employeds.find(
          (employee) => employee.id == authContext.googleUser.user_id
        )
        setEmployedSelected(userSession)

        const isOwner = placeSelected.service_provider.id == userSession.id
        if (!isOwner) {
          setPlaceEmployeds([userSession])
        } else {
          setPlaceEmployeds(employeds)
        }
      }
    }
  }, [employeds])

  useEffect(async () => {
    //Load clients
    setClientSelected(undefined)
    if (employedSelected) {
      setIsLoadingDataEmployed(true)

      const res = await getEmployedByService(
        placeSelected.id,
        employedSelected.id
      )

      const hasServiceProdiver = res.serviceProvider

      if (!hasServiceProdiver) {
        setIsLoadingDataEmployed(false)
      }

      if (hasServiceProdiver) {
        const jobID = res.serviceProvider?.job.id
        setJobID(jobID)

        fetchGetClientsByEmployed(jobID).then((res) => {
          setClients(objectsInArray(res.users))
          setClientSelected(undefined)
          setIsLoadingDataEmployed(false)
        })

        fetchGetLabels(placeSelected.id, jobID).then((res) => {
          setLabels(res.content)
        })
      }
      setClients([])
      setClientSelected(undefined)
      setCitesForClient([])
    }
  }, [employedSelected])

  useEffect(() => {
    setIsLoadingCites(true)

    if (jobID && clientSelected && filterActive && filterByDate) {
      let { start, end } = filterByDate
      fetchGetFilterCitesByDate(jobID, clientSelected.id, start, end).then(
        (res) => {
          const sortDate = objectsInArray(res.content.appointments).sort(
            (a, b) => new Date(b.start) - new Date(a.start)
          )
          setCitesForClient(sortDate)
          setIsLoadingCites(false)
        }
      )
    }
  }, [filterByDate, clientSelected, filterActive])

  useEffect(() => {
    setIsLoadingCredits(true)
    if (jobID && clientSelected && filterActive && filterByDateCredits) {
      const { start, end } = filterByDateCredits
      fetchGetFilterCreditsByDate(
        placeSelected.id,
        clientSelected.id,
        start,
        end
      ).then((res) => {
        setCreditsForClient(objectsInArray(res.data))
        setIsLoadingCredits(false)
      })
    }
  }, [filterByDateCredits, clientSelected])

  const dataChangeEmployed = {
    placeEmployeds,
    employedSelected,
    setEmployedSelected,
  }

  const dataSelectClient = {
    clients,
    clientSelected,
    setClientSelected,
    isLoadingDataEmployed,
  }

  const dataCitesHistory = {
    placeID: placeSelected.id,
    jobID,
    clientSelected,
    citesForClient,
    isLoadingCites,
  }

  const dataCreditHistory = {
    placeSelected,
    clientSelected,
  }

  const dataFilterByDate = {
    cbk: (filters) => {
      setFilterByDate(filters)
    },
    filterActive,
    setFilterActive,
  }

  const dataFilterByDateCredits = {
    cbk: (filters) => {
      setFilterByDateCredits(filters)
    },
    filterActive,
    setFilterActive,
  }

  const dataCiteDetail = {
    labels,
  }

  /**** RENDERS */
  const citesHistory = citesForClient
    .map((cite) => (
      <CiteDetailRow key={cite.id} data={{ ...dataCiteDetail, cite }} />
    ))
    .sort((a, b) => new Date(a.start) + new Date(b.start))

  const creditsHistory = creditsForClient.map((credit) => (
    <CreditDetailRow key={credit.id} data={credit} />
  ))

  return (
    <LayoutParent>
      <Col
        sm={12}
        md={6}
        className="bg-secondary py-3 d-flex flex-column gap-2  overflow-hidden"
      >
        {placeEmployeds.length ? (
          <ChangeEmployed imgSize="md" data={dataChangeEmployed} />
        ) : (
          <Loading />
        )}

        {isLoadingDataEmployed ? (
          <div className="full-height d-flex justify-content-center align-items-center">
            <Spinner size="sm" animation="border" variant="white" />
          </div>
        ) : (
          <>
            <Breakpoint
              customQuery="(min-width: 1000px)"
              className="flex-fill "
            >
              <SelectClient data={dataSelectClient} />
            </Breakpoint>
            <Breakpoint customQuery="(max-width: 999px)">
              <SelectClientResponsive data={dataSelectClient} />
            </Breakpoint>
          </>
        )}
      </Col>

      <Col className=" py-3 gap-2 full-height ">
        {clientSelected ? (
          <div className="d-flex flex-column gap-2 full-height overflow-hidden">
            <div>
              <CLientInfo client={clientSelected} />
            </div>

            <div className="flex-fill d-flex flex-column gap-2 full-height overflow-auto">
              <ItemAccordion label="Historial de turnos" bodyHeight="h-px">
                <FilterByDate data={dataFilterByDate} />
                {!isLoadingCites && labels.length ? (
                  <div className="d-flex flex-column p-1 gap-3">
                    {citesForClient.length ? (
                      citesHistory
                    ) : (
                      <>No tiene historial de turnos en estas fechas</>
                    )}
                  </div>
                ) : (
                  <Spinner
                    size="sm"
                    animation="border"
                    variant="white"
                    className="m-2"
                  />
                )}
              </ItemAccordion>

              <ItemAccordion label="Historial de créditos" bodyHeight="h-px">
                <FilterByDate data={dataFilterByDateCredits} />
                {!isLoadingCredits ? (
                  <div className="d-flex flex-column p-1 gap-3">
                    {creditsForClient.length ? (
                      creditsHistory
                    ) : (
                      <>No tiene historial de creditos en estas fechas</>
                    )}
                  </div>
                ) : (
                  <Spinner
                    size="sm"
                    animation="border"
                    variant="white"
                    className="m-2"
                  />
                )}
              </ItemAccordion>

              {/* add other item... */}
            </div>
          </div>
        ) : (
          <div className="full-height d-flex align-items-center gap-3 justify-content-center text-white text-opacity-25">
            <HiCursorClick size={20} />
            Selecciona un cliente
          </div>
        )}
      </Col>
    </LayoutParent>
  )
}
