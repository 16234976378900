import { useEffect, useState } from "react"
import { Link, withRouter } from "react-router-dom"
import PlaceView from "./place/PlaceView"
import AppointmentPreview from "./AppointmentPreview"
import DateSelection from "./DateSelection"
import EmployeeSelection from "./EmployeeSelection"
import ServiceSelection from "./ServiceSelection"
import "react-step-progress-bar/styles.css"
import { ProgressBar, Step } from "react-step-progress-bar"
import { useIsLoggedIn } from "../auth/authHooks"
import check from "../../lottie/check.json"
import error from "../../lottie/error.json"
import Lottie from "lottie-react"
import LoginPage from "../auth/LoginPage"

import styles from "./place/place-view.module.scss"
import "./booking-main-page.scss"
import PlaceViewCollapsed from "./place/PlaceViewCollapsed"

const STEPS_COUNT = 4

function BookingMainPage(props) {
  const [inMainPage, setInMainPage] = useState(true)
  const [placeFound, setPlaceFound] = useState(true)
  const [place, setPlace] = useState(undefined)
  const [inLoginPage, setInLoginPage] = useState(false)
  const isLoggedIn = useIsLoggedIn()
  const [image, setImage] = useState(undefined)

  useEffect(() => {
    if (!isLoggedIn) {
      setInMainPage(true)
    }
  }, [isLoggedIn])

  const showAppointmentBooking = () => {
    if (isLoggedIn) {
      setInMainPage(false)
    } else {
      setInLoginPage(true)
    }
  }

  const onPlaceNotFound = () => {
    setPlaceFound(false)
  }

  const onSignedIn = () => {
    setInLoginPage(false)
    setInMainPage(false)
  }

  if (inLoginPage) {
    return <LoginPage callback={onSignedIn} />
  }

  return (
    <div className="mt-5 pt-4 h-100">
      {inMainPage ? (
        <PlaceView
          onPlaceNotFound={onPlaceNotFound}
          placeId={props.match.params.id}
          onImageFetch={setImage}
          imageUrl={setImage}
          onPlaceFetch={setPlace}
        />
      ) : (
        <PlaceViewCollapsed place={place} imageUrl={image} />
      )}
      {placeFound && (
        <div className={`position-relative`}>
          {inMainPage && (
            <button
              className={`fw-bold ${styles.btnWantMyAppointment}`}
              onClick={showAppointmentBooking}
            >
              QUIERO MI TURNO
            </button>
          )}

          {!inMainPage && (
            <AppointmentBooking placeId={props.match.params.id} />
          )}
        </div>
      )}
    </div>
  )
}

export default withRouter(BookingMainPage)

function AppointmentBooking({ placeId }) {
  const [currStep, setCurrStep] = useState(0)
  const [selectedEmployee, setSelectedEmployee] = useState(undefined)
  const [selectedServices, setSelectedServices] = useState([])
  const [selectedApps, setSelectedApps] = useState([])
  const [hasBooked, setHasBooked] = useState(false)
  const [timedOut, setTimedOut] = useState(false)
  const [error, setError] = useState(false)

  const startTimeOut = () => {
    setTimeout(() => {
      if (!hasBooked) {
        setTimedOut(true)
      }
    }, 300000)
  }

  let nextStep = () => {
    if (currStep < STEPS_COUNT - 1) {
      setCurrStep(currStep + 1)
    }
  }

  let onConfirmServices = (services) => {
    setSelectedServices(services)
    nextStep()
  }

  let onConfirmEmployee = (employee) => {
    setSelectedEmployee(employee)

    // updating credits for services of the selected employee
    let selectedServicesAux = selectedServices
    let serviceId
    for (let i = 0; i < selectedServices.length; i++) {
      serviceId = selectedServices[i].id

      if (serviceId in employee.credits) {
        selectedServicesAux[i].can_book_without_credits =
          employee.credits[serviceId].can_book_without_credits
        selectedServicesAux[i].credits = employee.credits[serviceId].credits
      } else {
        // sanity update, make sure that no credits are set in the current service
        delete selectedServicesAux[i].can_book_without_credits
        delete selectedServicesAux[i].credits
      }
    }

    setSelectedServices(selectedServicesAux)
    nextStep()
  }

  let onConfirmDate = (dates) => {
    setSelectedApps(dates)
    nextStep()
  }

  let onAppointmentsBooked = () => {
    setHasBooked(true)
  }

  let onError = () => {
    setError(true)
  }

  let getStep = () => {
    switch (currStep) {
      case 0:
        return (
          <ServiceSelection
            placeId={placeId}
            onConfirm={onConfirmServices}
            selectedServices={selectedServices}
          />
        )

      case 1:
        return (
          <EmployeeSelection
            placeId={placeId}
            services={selectedServices}
            onConfirm={onConfirmEmployee}
          />
        )

      case 2:
        return (
          <DateSelection
            emergencyToday={selectedEmployee.today_emergency}
            daysOff={selectedEmployee.day_off_list}
            placeId={placeId}
            jobId={selectedEmployee.job.id}
            services={selectedServices}
            onConfirm={onConfirmDate}
            jobDays={selectedEmployee.days}
            selectedAppsProp={selectedApps}
          />
        )
      case 3:
        return (
          <AppointmentPreview
            placeId={placeId}
            onBooked={onAppointmentsBooked}
            jobId={selectedEmployee.job.id}
            services={selectedServices}
            apps={selectedApps}
            employee={selectedEmployee}
            onError={onError}
          />
        )

      default:
        return null
    }
  }

  let goBackToStep = (index) => {
    if (index < 2) {
      setSelectedApps([])

      if (index === 0) {
        setSelectedServices([])
      }
    }
    setCurrStep(index)
  }

  const onReturnToMainScreenClick = () => {
    reset()
    goBackToStep(0)
    setTimedOut(false)
    startTimeOut()
    setError(false)
  }

  const reset = () => {
    setHasBooked(false)
    setSelectedApps([])
    setSelectedServices([])
  }

  if (error) {
    return (
      <>
        <Error />
        <button
          className={`${styles.btnMainBooking} mx-auto row justify-content-center`}
          onClick={onReturnToMainScreenClick}
        >
          VOLVER A RESERVAR
        </button>
      </>
    )
  }

  if (timedOut && !process.env.REACT_APP_DEBUG) {
    return (
      <div className="mt-4">
        <span>Ha expirado su tiempo de reserva</span>
        <button
          className={`${styles.btnMainBooking} mx-auto row justify-content-center`}
          onClick={onReturnToMainScreenClick}
        >
          VOLVER A RESERVAR
        </button>
      </div>
    )
  }

  if (hasBooked) {
    return (
      <AppointmentBooked
        onReturnToMainScreenClick={onReturnToMainScreenClick}
      />
    )
  }

  return (
    <div>
      <StepsProgress currStep={currStep} setCurrStep={goBackToStep} />
      <div>{getStep()}</div>
    </div>
  )
}

function Error() {
  return (
    <div className="pt-4 d-flex justify-content-center gap-2 flex-column align-items-center">
      <div className="lottie">
        <Lottie animationData={error} loop={false} autoPlay={true} />
      </div>
      <div className="pt-4">
        Lo sentimos, se produjo un error reservando el turno <br />
        Por favor, inténtelo nuevamente
      </div>
    </div>
  )
}

function AppointmentBooked({ onReturnToMainScreenClick }) {
  return (
    <div className="mt-4 d-flex justify-content-center gap-2 flex-column align-items-center">
      <div className="lottie">
        <Lottie animationData={check} loop={false} autoPlay={true} />
      </div>

      <div className="h5 mt-3">
        Su reserva fue registrada satisfactoriamente
      </div>
      <div className="text-light">Podés cerrar la página</div>
      <button
        className={`${styles.btnMainBooking} mx-auto row justify-content-center`}
        onClick={onReturnToMainScreenClick}
      >
        RESERVAR OTRO TURNO
      </button>
      <Link
        to="/calendar"
        className={`${styles.linkCalendar} btn btn-primary text-secondary fw-bold mx-auto row mt-3`}
      >
        VER MIS TURNOS
      </Link>
    </div>
  )
}

function StepsProgress({ currStep, setCurrStep }) {
  function getPercentage() {
    return (currStep * 100) / 3
  }

  function getDiv(index, name) {
    if (index < currStep) {
      return (
        <div
          key={index}
          className={`col ${styles.step} ${styles.stepDone}`}
          onClick={() => setCurrStep(index)}
        >
          {name}
        </div>
      )
    } else if (index === currStep) {
      return (
        <div
          key={index}
          className={`col ${styles.step} ${styles.stepCurrent} fw-bold`}
        >
          {name}
        </div>
      )
    }
    return (
      <div
        key={index}
        className={`col ${styles.step} ${styles.stepFuture} fw-light`}
      >
        {name}
      </div>
    )
  }

  return (
    <ProgressBar
      percent={getPercentage()}
      filledBackground="#ff8672"
      height="5px"
    >
      <Step>{() => getDiv(0, "Servicios")}</Step>
      <Step>{() => getDiv(1, "Empleado")}</Step>
      <Step>{() => getDiv(2, "Horarios")}</Step>
      <Step>{() => getDiv(3, "Resumen")}</Step>
    </ProgressBar>
  )
}
