import { useState } from 'react'
import './search_bar.scss';

import { AiOutlineSearch } from 'react-icons/ai';

import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

export default function SearchBar({data, className, placeholder}) {
  const [filter, setFilter] = useState('');

  const handleFilter = event => {
    setFilter(event.target.value);
    let listSearched = undefined

    const valueLowerCase = event.target.value.toLowerCase()
    
    if( data.allClients ){
      listSearched = data.allClients.filter( client => {
        return client.given_name.toLowerCase().includes( valueLowerCase ) ||
                client.family_name.toLowerCase().includes( valueLowerCase )
      }) 
    }

    else if( data.allServices ){
      listSearched = data.allServices.filter( data => {
        return data.name.toLowerCase().includes( valueLowerCase ) 
                
      }) 
    }

    data.setSearched( listSearched )
  };

  return (
    <div >
      <InputGroup size="sm"  >
        <span className="search-icon" >
          <AiOutlineSearch size="1.5em"/>
        </span>
        <FormControl
          value={filter}
          onChange={handleFilter}
          bsPrefix={`input-search flex-fill ${className}`}
          placeholder={placeholder}
          aria-label={placeholder}
          aria-describedby="basic-addon1"
        />
      </InputGroup>
    </div>
  );
}

