import {useEffect, useRef, useState} from 'react'
import './vigency.scss';

import dateFormat from 'dateformat';

import {ImFilter} from 'react-icons/im'
//Boostrap
import { Container } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export default function FilterByDate({data}) {
  const inputDateStart = useRef(null)
  const inputDateEnd = useRef(null)
  const inputSwitch = useRef(null)

  const [ isLoading, setIsloading ] = useState( false )
  const [ labels, setLabels ] = useState( {start:undefined, end:undefined} )

  const currentDate = new Date()
  let dateEnd = new Date().setMonth( currentDate.getMonth() + 1 )
  let dateStart = currentDate


  useEffect( () => {
    //First Load
    if( data.filterActive ){
      setIsloading( true )
    
      inputDateStart.current.value = dateFormat( dateStart, 'yyyy-mm-dd')
      inputDateEnd.current.value = dateFormat( dateEnd, 'yyyy-mm-dd')

      setLabels({
        start: dateFormat( dateStart, 'dd-mm-yy'),
        end: dateFormat( dateEnd, 'dd-mm-yy')
      })

      const filters = {
        start: dateFormat( dateStart, 'dd-mm-yyyy'),
        end: dateFormat( dateEnd, 'dd-mm-yyyy'),
      }

      data.cbk(filters)
      setIsloading( false )
    }
    
  }, [])

  

  

  const changeDate = (e, value) => {
    const dateSelected = e.target.value
    if( value === 'start' ) inputDateStart.current.value = dateSelected
    else inputDateEnd.current.value = dateSelected
    
    /** Esta parte cambia el label de la fecha ...
     * Por alguna extraña razon, al seleccionar una fecha, el label se muestra con un dia menos..
     *  Por ejemplo, selcciono la fecha 14/06/22 y en el label se muestra 13/06/22, pero por detras 
     * el valor esta bien.... 
     * Para solucionarlo tuve que sumarle 1 dia( solo a la vista del usuario )
     */
    const dateStart = new Date(inputDateStart.current.value)
    const dateEnd = new Date(inputDateEnd.current.value)
    setLabels({
      start: dateFormat( dateStart.setDate( dateStart.getDate() + 1 ), 'dd-mm-yy'),
      end: dateFormat( dateEnd.setDate( dateEnd.getDate() + 1 ), 'dd-mm-yy')
    })

    const filters = {
      start: dateFormat( dateStart, 'dd-mm-yyyy'),
      end: dateFormat( dateEnd, 'dd-mm-yyyy'),
    }

    data.cbk(filters)

  }

  function showDatePicker(e, value){
    if( value === 'start' ) inputDateStart.current.showPicker()
    else inputDateEnd.current.showPicker()
  }

  const handleSwitch = ()=>{
    data.setFilterActive( !data.filterActive )
  }

  return (
    <div className="sticky-top bg-light overflow-hidden">
      <Container>
        <Row>
          <Col className="d-flex p-1 text-dark fs-7 gap-3 rounded-bottom  justify-content-between">
            <div className="d-flex align-items-center gap-1 border-end border-dark pe-2">
              <ImFilter/>
              Filtrar:
              {/* <Form.Check
                ref={inputSwitch}
                checked={data.filterActive}
                type="switch"
                id="custom-switch"
                onChange={ handleSwitch }
              /> */}
            </div>
            <div className="d-flex gap-2 overflow">
              
              { data.filterActive 
                ? <>
                  <div className="d-flex gap-1 ">
                      <span>Inicio:</span>
                      <input onChange={(e) => changeDate( e, 'start' )} ref={inputDateStart} type="date" className="position-absolute invisible"/>
                      <span role="button" onClick={(e)=> showDatePicker(e, 'start')} type="date"  className="border border-1 border-dark px-1 py-0 rounded-1">{isLoading ? 'Cargando...':labels.start}</span>
                  </div>

                  <div className="d-flex gap-1 ">
                      <span>Fin:</span>
                      <input onChange={(e) => changeDate( e, 'end' )} ref={inputDateEnd} type="date" className="position-absolute invisible"/>
                      <span role="button" onClick={(e)=> showDatePicker(e, 'end')} className="border border-1 border-dark px-1 py-0 rounded-1">{isLoading ? 'Cargando...':labels.end}</span>
                  </div>
                  </>
                : 'Viendo todo'
              }
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

