import React, { useEffect } from 'react';
import { useState } from 'react';
import Switch from 'react-switch';
import { getCronTasks, haltConTask, startConTask } from '../../../requests/requests';

import './crontasks.scss';

export default function CronTaskView() {
    const [cronTasks, setCronTasks] = useState(undefined);
    const [isFetching, setIsFetching] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        getCronTasks()
            .then((res) => {
                setCronTasks(res.content);
                setIsFetching(false);
            })
            .catch((err) => {
                setError(true);
                setIsFetching(false);
            });
    }, []);

    if (isFetching) {
        return <div className='mt-2'>Loading...</div>;
    }

    if (error) {
        return <div>No tienes permiso</div>;
    }

    return (
        <div className='mt-2 mx-auto' id='cronTaskViewContainer'>
            {cronTasks.map((task) => {
                return <CronTask key={task.name} cronTaskProp={task} />;
            })}
        </div>
    );
}

function CronTask({ cronTaskProp }) {
    const [isFetching, setIsFetching] = useState(false);
    const [isActive, setIsActive] = useState(cronTaskProp.active);

    const onStartConTask = () => {
        setIsFetching(true);
        startConTask(cronTaskProp.name)
            .then((res) => {
                setIsActive(true);
                setIsFetching(false);
            })
            .catch((err) => {
                setIsFetching(false);
            });
    };

    const onHaltConTask = () => {
        setIsFetching(true);
        haltConTask(cronTaskProp.name)
            .then((res) => {
                setIsActive(false);
                setIsFetching(false);
            })
            .catch((err) => {
                setIsFetching(false);
            });
    };

    const onSwitchChange = (checked) => {
        if (checked) {
            onStartConTask();
        } else {
            onHaltConTask();
        }
    };

    return (
        <div className='cronTask mt-3'>
            {cronTaskProp.name}
            <label>
                <Switch onChange={onSwitchChange} checked={isActive} disabled={isFetching} />
            </label>
        </div>
    );
}
