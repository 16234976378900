import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { deleteMessage, fetchMessages } from '../../../requests/requests';

import './messagesview.scss';

export default function MessagesView() {
    const [isFetching, setIsFetching] = useState(true);
    const [error, setError] = useState(false);
    const [messages, setMessages] = useState(undefined);

    useEffect(() => {
        fetchMessages()
            .then((res) => {
                setMessages(res.content);
                setIsFetching(false);
            })
            .catch(() => {
                setError(true);
                setIsFetching(false);
            });
    }, []);

    if (isFetching) {
        return <span>Loading...</span>;
    }

    return (
        <div id='messagesContainer' className='mx-1 mt-3'>
            <div className='fw-bold h3 text-uppercase'>Mensajes</div>
            <div className='px-2'>
                {messages.map((message) => {
                    return <Message message={message} key={message.id} />;
                })}
            </div>
        </div>
    );
}

function Message({ message }) {
    const [hasRemoved, setHasRemoved] = useState(false);

    const onRemoveMessageClick = () => {
        deleteMessage(message.id).then(() => {
            setHasRemoved(true);
        });
    };

    const onReplyMessageClick = () => {
        window.location.href = `mailto:${message.email}?subject=${message.subject}`;
    };

    if (hasRemoved) {
        return null;
    }

    return (
        <div className='messageRow mt-3'>
            <div className='text-end'>
                <Dropdown>
                    <Dropdown.Toggle id='dropdown-basic' size='sm' variant=''>
                        <i className='fas fa-ellipsis-v text-white'></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={onReplyMessageClick}>Responder</Dropdown.Item>
                        <Dropdown.Item onClick={onRemoveMessageClick}>
                            <span className='text-danger text-capitalize fw-normal'>Eliminar</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className='row-sm  text-start'>
                <div className='col row mx-1'>
                    <span className='col-4'>Id </span>
                    {message.id}
                </div>
                <div className='col row mx-1'>
                    <span className='col-4'>Nombre </span>
                    {message.name}
                </div>
                <div className='col row mx-1'>
                    <span className='col-4'>Email </span>
                    {message.email}
                </div>
                <div className='col row mx-1'>
                    <span className='col-4'>Motivo </span>
                    {message.subject}
                </div>
                <div className='col row mx-1'>
                    <span className='col-4'>Mensaje </span>
                    {message.message}
                </div>
            </div>
        </div>
    );
}
