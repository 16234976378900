import "./nav-main.scss";

import horizontalLogo from "../../images/main/logo_horizontal.svg";
import { Link, NavLink } from "react-router-dom";

import { useEffect, useState, useContext } from "react";
import NavProfile from "./NavProfile";
import {
  OnlyIfLoggedIn,
  OnlyIfNotLoggedIn,
  OnlyIfUserHavePlaces,
} from "../auth/AuthUtils";

import { Nav, Navbar } from "react-bootstrap";

import "bootstrap";
import MenuButton from "./MenuButton";
import { useIsLoggedIn } from "../auth/authHooks";

//MyCommerce
import { PlaceContext } from "../my_commerce/PlaceContextProvider";

export default function NavMain({ show, disableWait }) {
  const { userPlaces } = useContext(PlaceContext);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const loggedIn = useIsLoggedIn();

  useEffect(() => {
    if (!disableWait) {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      fixed="top"
      id="mainNav"
      onToggle={setExpanded}
      className={!show || loading ? "" : "visible"}
    >
      <Nav.Link as={Link} to="/">
        <img
          src={horizontalLogo}
          id="navbarLogo"
          className={expanded ? "expanded" : ""}
          alt=""
        />
      </Nav.Link>

      <OnlyIfLoggedIn>
        <NavProfile />
      </OnlyIfLoggedIn>
      <Navbar.Toggle aria-controls="collapseNavMain">
        <MenuButton expanded={expanded} />
      </Navbar.Toggle>
      <Navbar.Collapse
        id="collapseNavMain"
        className={loggedIn ? "logged" : "not-logged"}
      >
        <Nav className="ms-auto">
          <OnlyIfLoggedIn>
            {userPlaces.length > 0 && (
              <Nav.Link
                eventKey="1"
                as={NavLink}
                to="/my-commerce"
                className="text-uppercase"
              >
                Mi negocio
              </Nav.Link>
            )}
          </OnlyIfLoggedIn>
          <Nav.Link
            eventKey="2"
            as={NavLink}
            to="/join"
            className="text-uppercase"
          >
            Adherí tu local
          </Nav.Link>
          <Nav.Link
            eventKey="3"
            as={NavLink}
            to="/contact-us"
            className="text-uppercase"
          >
            Contactanos
          </Nav.Link>

          <OnlyIfNotLoggedIn>
            <Nav.Link
              eventKey="4"
              as={NavLink}
              to="/login"
              className="text-uppercase"
            >
              Iniciar sesión
            </Nav.Link>
          </OnlyIfNotLoggedIn>
        </Nav>
      </Navbar.Collapse>
      <div id="navbarLine"></div>
    </Navbar>
  );
}
