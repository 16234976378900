import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FETCH } from '../../../../requests/http';
import { fetchUserDetails, postWhatsappCredits } from '../../../../requests/requests';

function UserDetailsView(props) {
    const [isFetching, setIsFetching] = useState(true);
    const [error, setError] = useState(false);
    const [details, setDetails] = useState(undefined);

    useEffect(() => {
        fetchUserDetails(props.match.params.user_id)
            .then((res) => {
                setDetails(res.content);
                setIsFetching(false);
            })
            .catch((err) => {
                setError(true);
                setIsFetching(false);
            });
    }, []);

    if (isFetching) {
        return <div>cargando...</div>;
    }
    if (error) {
        return <div>se produjo un error</div>;
    }

    return (
        <div className='userDetails'>
            <UserDetails user={details.user} />
            <UserTrial user={details.user} trialProp={details.trial} />
            <UserJobs user={details.user} jobs={details.jobs} />
        </div>
    );
}

export default withRouter(UserDetailsView);

function UserDetails({ user }) {
    return (
        <div>
            <div className='fw-bold'>ID: {user.id} </div>
            <div className='fw-bold h4 text-uppercase'>{`${user.given_name} ${user.family_name}`}</div>
        </div>
    );
}

function UserJobs({ user, jobs }) {
    return (
        <div className='jobsContainer'>
            {Object.keys(jobs).map((key) => {
                return (
                    <div className='job' key={key}>
                        <div className='fw-bold text-uppercase text-start ms-2 pt-2'>
                            {jobs[key].job.id}
                            {'  ' + jobs[key].place.businessname}
                        </div>
                        <WhatsappCredits job={jobs[key].job} credits={jobs[key].credits} />
                    </div>
                );
            })}
        </div>
    );
}

function WhatsappCredits({ job, credits }) {
    const [selectedCredits, setSelectedCredits] = useState(credits.credits);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState(false);

    const onSaveClick = () => {
        setIsFetching(true);
        postWhatsappCredits(job.id, selectedCredits)
            .then(() => {
                setError(false);
                setIsFetching(false);
            })
            .catch(() => {
                setError(true);
            });
    };

    return (
        <div className='row mt-3 wspCredits mx-1'>
            <span className='col-3 fw-bold text-uppercase text-start'>Créditos Whatsapp</span>
            <input
                className='col-4'
                value={selectedCredits}
                type='number'
                onChange={(e) => setSelectedCredits(e.target.value)}
            />
            <div className='col text-end my-auto'>
                <button
                    className='btn btn-primary text-uppercase text-white fw-bold'
                    onClick={onSaveClick}
                    disabled={isFetching || error}
                >
                    {!error && 'guardar'}
                    {error && 'error'}
                </button>
            </div>
        </div>
    );
}

function UserTrial({ user, trialProp }) {
    const [trial, setTrial] = useState(trialProp);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectedDate, setSelectedDate] = useState(!!trial ? trial.valid_until : undefined);

    const onSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        let body = {
            trial: {
                valid_until: moment(selectedDate).format('YYYY-MM-DD'),
            },
        };

        FETCH(`/admin/users/${user.id}`, 'PUT', body).then(() => {
            setLoading(false);
            setTrial({ valid_until: selectedDate });
        });
    };

    const deleteTrial = (e) => {
        e.preventDefault();

        FETCH(`/admin/users/${user.id}/trial`, 'DELETE').then(() => {
            setLoading(false);
            setSelectedDate('');
            setTrial(undefined);
        });
    };

    return (
        <div className={`${!!trial ? '' : 'noTrial'} userTrial`}>
            {!!trial ? 'Modo prueba vigente' : 'Modo prueba no vigente'}
            <form onSubmit={onSubmit}>
                <input
                    className='validUntil'
                    type='date'
                    value={selectedDate}
                    onChange={(e) => {
                        setSelectedDate(e.target.value);
                    }}
                    required
                    min={new moment().format('YYYY-MM-DD')}
                />
                <input
                    className='btn btn-primary text-uppercase text-white fw-bold'
                    disabled={loading || error}
                    type='submit'
                    value={error ? 'se produjo error' : 'guardar'}
                />
            </form>
            <form onSubmit={deleteTrial}>
                <input type='submit' value='Eliminar' className='btn btn-danger text-white' />
            </form>
        </div>
    );
}
