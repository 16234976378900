import { useEffect, useState } from "react"
import firebase from "firebase"
import Skeleton from "./Skeleton"

export default function FirebaseImage(props) {
  const [isFetching, setIsFetching] = useState(!props.imageUrl)
  const [imageUrl, setImageUrl] = useState(props.imageUrl)
  const [error, setError] = useState(false)

  useEffect(() => {
    start()
    async function start() {
      var imageRef = firebase.storage().ref("" + props.id)
      await imageRef
        .getDownloadURL()
        .then((url) => {
          setImageUrl(url)
          if (props.setImgUrl) {
            props.setImgUrl(url)
          }
          setIsFetching(false)
        })
        .catch(() => {
          setError(true)
          setIsFetching(false)
        })
    }
  }, [props.id])

  if (isFetching || error) {
    return <Skeleton />
  }

  return <img className={props.className} src={imageUrl} alt="" />
}
