
import moment from 'moment';

export function parseAppointmentData(appData) {
    let keys = Object.keys(appData);
    let first_service = keys[0];

    let dateUTC = moment(appData[first_service].start, 'YYYY-MM-DD HH:mm:ss').toDate();

    let offset = new Date().getTimezoneOffset();
    let date = new Date(dateUTC.getTime() - offset * 60000);

    return date;
}