import { Redirect } from "react-router"

import { useIsLoggedIn, useIsNewUser } from "./authHooks"

export function RedirectIfLoggedIn(props) {
  const isLoggedIn = useIsLoggedIn()

  return isLoggedIn ? <Redirect to="/calendar" /> : <> {props.children} </>
}

export function RedirectIfNotLoggedIn(props) {
  const isLoggedIn = useIsLoggedIn()
  const redirect = props.redirectLogin ? `?redirect=${props.redirectLogin}` : ""

  return !isLoggedIn ? (
    <Redirect to={`/login${redirect}`} />
  ) : (
    <> {props.children} </>
  )
}

export function OnlyIfLoggedIn(props) {
  const isLoggedIn = useIsLoggedIn()

  return isLoggedIn ? <> {props.children} </> : null
}

export function OnlyIfNotLoggedIn(props) {
  const isLoggedIn = useIsLoggedIn()

  return isLoggedIn ? null : <> {props.children} </>
}

export function OnlyIfNewUser(props) {
  const isNewUser = useIsNewUser()

  return isNewUser ? <> {props.children} </> : null
}

export function OnlyIfNotNewUser(props) {
  const isNewUser = useIsNewUser()

  return !isNewUser ? <> {props.children} </> : null
}
