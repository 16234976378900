import React, { useContext } from 'react';
import { LoadingContext } from 'react-router-loading';
import './style.scss';
import { useEffect } from 'react';

export default function PrivacyPolicy() {
    const loadingContext = useContext(LoadingContext);
    useEffect(() => {
        window.scrollTo(0, 0);
        loadingContext.done();
    }, []);

    return (
        <div className='legal card p-0 bg-secondary mt-5 pt-5'>
            <div className='card-body'>
                <div className='container card-text text-center'>
                    <div className='text-center fw-bold h3'>
                        Declaración de privacidad y confidencialidad de la información de Ya tenés
                        turno
                    </div>
                </div>

                <section className='container'>
                    En Ya tenés turno entendemos a la protección de los datos personales como una
                    oportunidad para generar valor para nuestros usuarios. Haciendo un uso
                    responsable de la información personal, no sólo protegemos la privacidad de
                    quienes nos confiaron sus datos, sino que les permitimos operar con seguridad y
                    confianza en nuestro sistema. Por ello, tu privacidad es muy importante para
                    nosotros y nos esforzamos para protegerla.
                </section>

                <section className='container'>
                    Ya tenés turno, con el fin de poder brindarte sus servicios, trata, recolecta, y
                    en algunos casos, revela información sobre las personas que, como tú, son
                    usuarios y visitantes de los sitios web y/o aplicaciones móviles, conforme se
                    detalla aquí (las "Plataformas" y los "Servicios", respectivamente). Esta
                    Declaración de Privacidad y Confidencialidad de la Información de Ya tenés turno
                    (la "Declaración de Privacidad") describen la información que Ya tenés turno
                    recolecta y trata sobre ti y lo que puede hacer con esa información.
                </section>

                <section className='container'>
                    Esta Declaración de Privacidad es parte integrante de los{' '}
                    <a href='https://www.yatenesturno.com.ar/terms-conditions'>
                        Términos y Condiciones Generales
                    </a>{' '}
                    de Ya tenés turno. Prestar tu consentimiento voluntario, expreso e informado a
                    esta Declaración de Privacidad es un requisito esencial para poder contratar y/o
                    tener cualquier tipo de relación con Ya tenés turno, dependiendo de la
                    legislación aplicable en cada país.
                </section>

                <section className='container'>
                    1) ¿Cómo se integra Ya tenés turno y cómo aplica esta Declaración de Privacidad?
                    El objetivo de Ya tenés turno es generar un sistema virtual de prestaciones de
                    servicios, en el cual, un Usuario vendedor pueda ofrecer los turnos disponibles
                    de sus servicios ofrecidos y un Usuario comprador pueda tener acceso a ellos de
                    manera rápida, eficiente y segura. Para ello ha generado un sistema de servicios
                    integrados a través de sus plataformas: ¿Ya tenés turno? (Android: PlayStore), y
                    plataforma online, www.yatenesturno.com.ar. Todas estas plataformas conforman un
                    todo y la interfuncionalidad entre ellas es tanto necesaria como indispensable
                    para la prestación de los servicios que te brinda Ya tenés turno. Por esta
                    razón, es importante que tengas en cuenta que cuando te registras en cualquiera
                    de estas plataformas, estás generando un usuario que te permitirá operar en
                    todas las demás. Para ello es necesario que tus datos sean compartidos entre
                    todas las plataformas de Ya tenés turno. En la sección 5. “¿Cómo compartimos la
                    Información Personal?” podrás encontrar más información respecto a esto último.
                </section>

                <section className='container'>
                    2) ¿Quién es el responsable del tratamiento de la Información Personal? El
                    responsable del tratamiento es quien decide sobre el tratamiento de los datos
                    personales. Para ello determina los fines o usos para los que se utilizará la
                    información personal y los medios que serán utilizados para ese tratamiento
                    (consulta la Sección 4. “¿Para qué se utilizará la Información Personal?”). Ya
                    tenés turno es el responsable del tratamiento de los datos de los usuarios y de
                    los visitantes de sus plataformas.
                </section>

                <section className='container'>
                    3) ¿Qué información recolectamos y tratamos? Ya tenés turno recaba tu
                    información personal para que puedas disfrutar de nuestros servicios, y poder
                    mejorarlos de manera continua. En algunos casos, la información la facilitas tú
                    mismo, al registrarte o al proveer información cuando utilizas alguno de
                    nuestros servicios. En otros los recolectamos automáticamente, como cuando
                    navegas por nuestras páginas, utilizas nuestros servicios. También podemos
                    recabar información acerca tuyo de otras fuentes confiables. Queremos que sepas
                    que no tienes la obligación de proporcionarnos la información personal que se
                    muestra a continuación, sin embargo, este es un requisito esencial para poder
                    contratar y/o tener cualquier tipo de relación con Ya tenés turno y, si no
                    proporcionas esta información, no podremos brindarte nuestros servicios o
                    nuestra capacidad para hacerlo puede verse significativamente obstaculizada. La
                    inexactitud o falsedad de los datos personales que proporciones podría causar la
                    suspensión de los Servicios. Asimismo, Ya tenés turno podrá suspender o
                    inhabilitar definitivamente a aquellos usuarios que violen esta Declaración de
                    Privacidad. Estos son los tipos de datos que recolectamos: a) Información que
                    nos proporcionas directamente al registrarte o utilizar nuestros servicios: i.
                    apodo o seudónimo para operar en las plataformas, ii. nombre, imagen personal
                    (foto personal o foto del documento), iii. información de contacto (como número
                    de teléfono, domicilio, dirección de correo electrónico).
                </section>

                <section className='container'>
                    4) ¿Qué hacemos con la Información Personal? La recolección y tratamiento de tu
                    Información Personal nos permite prestarte un excelente servicio para que puedas
                    realizar operaciones en forma rápida y segura y ofrecerte funcionalidades que se
                    adaptan mejor a tus necesidades. Salvo en aquellos casos en los que la normativa
                    aplicable lo prohíba, Ya tenés turno podrá utilizar tu Información Personal para
                    las siguientes finalidades: a) Brindar los productos, servicios y/o beneficios
                    que ofrece las plataformas de Ya tenés turno. <br />
                    ▪ Identificarte y contactarte. <br />
                    ▪ Registrarte en nuestros sistemas. <br />
                    ▪ Verificar tu identidad en cumplimiento de exigencias legales. <br />
                    ▪ Brindarte los productos, servicios y/o beneficios que solicitas o contratas
                    con nosotros. <br />
                    ▪ Facilitarte entrar en contacto directo con el vendedor o comprador para
                    efectos de la transacción que deseas realizar. <br />
                    ▪ Elaborar y mantener un registro de las operaciones que realices, así como
                    informarte acerca de las mismas y darle seguimiento correspondiente. <br />▪
                    Atender tus comentarios, quejas y sugerencias, así como brindarte soporte.{' '}
                    <br />
                    ▪ Ofrecerte servicios y funcionalidades que se adecuen mejor a tus necesidades,
                    y personalizar nuestros servicios para hacer que tus experiencias con Ya tenés
                    turno sean lo más cómodas posible. <br />
                    ▪ Contribuir a la seguridad las relaciones, comunicaciones y transacciones entre
                    los usuarios de nuestra plataforma. <br />
                    ▪ Elaborar un sistema de reputación de usuarios, para beneficio de los
                    consumidores. b) Mejorar nuestros servicios, desarrollar nuevos y ofrecerte una
                    mejor experiencia con las plataformas de Ya tenés turno. <br />
                    ▪ Desarrollar estudios internos sobre tus intereses y comportamientos, para
                    ofrecerte mejores servicios y productos, así como realizar estudios
                    estadísticos. <br />
                    ▪ Ofrecerte servicios y funcionalidades que se adecuen mejor a tus necesidades y
                    personalizar nuestros servicios para hacer que tus experiencias con Ya tenés
                    turno sean lo más cómodas posible. <br />
                    ▪ Brindarte información a través de diferentes canales (por correo electrónico,
                    mensajes cortos de texto (SMS), mensajes push, llamada telefónica o cualquier
                    otro medio) sobre mejoras o nuevas funciones o servicios de la plataforma. c)
                    Efectuar anuncios y contactos publicitarios y promocionales. <br />
                    ▪ Contactarte a través de diferentes canales (por correo electrónico, mensajes
                    cortos de texto (SMS), mensajes push, llamada telefónica o cualquier otro medio)
                    para finalidades publicitarias y/o promocionales de productos y servicios de Ya
                    tenés turno y/o de terceros. <br />▪ Efectuar todo tipo de actividades de
                    mercadotecnia, publicidad, prospección comercial y/o estudios de mercado.
                </section>

                <section className='container'>
                    5) ¿Cómo compartimos la Información Personal? El resguardo de tu privacidad es
                    muy importante para Ya tenés turno. Por ello no vendemos ni comercializamos
                    información que identifique a nuestros usuarios. Tampoco compartimos o
                    transferimos de ningún otro modo tu Información Personal a terceros, salvo de la
                    manera indicada a continuación: Ya tenés turno podrá ceder, transmitir y/o
                    transferir tu Información Personal a: (i) “Autoridades públicas”: las
                    autoridades administrativas y judiciales que en ejercicio de su competencia
                    requieran información, aunque no exista una orden ni una citación ejecutiva o
                    judicial al efecto, con la finalidades de: (a) colaborar en la investigación y
                    denunciar fraudes, piratería, violaciones de propiedad intelectual o industrial
                    o cualquier otro ilícito, así como cualquier actividad o circunstancia que
                    pudiera generarle responsabilidad legal a Ya tenés turno y/o a sus usuarios; (b)
                    salvaguarda de un interés público, la procuración o administración de justicia,
                    el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial o
                    administrativo, y/o la resolución de controversias; y (c) dar cumplimiento a
                    alguna ley, reglamento o disposición legal aplicable, o a algún mandato de
                    autoridad competente debidamente fundado y motivado. (ii) “Usuarios y/o
                    visitantes en general”: otros usuarios y/o visitantes de nuestra plataforma, con
                    la finalidad de calidad del servicio al consumidor final, brindándole
                    información para que tome una decisión de compra informada. Se publicará en la
                    plataforma la reputación, la atención brindada, y opiniones de los usuarios
                    (cuyo contenido no es responsabilidad de Ya tenés turno). Asimismo, Ya tenés
                    turno podrá divulgar tu Información Personal discrecionalmente a otros usuarios
                    de los Sitios Web y/o los Servicios, entidades o terceros cuando haya motivos
                    suficientes para considerar que tu actividad sea sospechosa de intentar o
                    cometer un delito o intentar perjudicar a otras personas. Si Ya tenés turno
                    decidiese compartir tu Información Personal con terceros distintos a los
                    mencionados, solicitaremos tu consentimiento previo y expreso, siempre y cuando
                    no exista una autorización u obligación legal que permita realizarlo sin ese
                    consentimiento. Asimismo, prestas tu consentimiento expreso e informado para que
                    Ya tenés turno ceda, transmita o transfiera tu Información Personal a los
                    destinatarios detallados en esta Declaración de Privacidad. Finalmente, Ya tenés
                    turno no será responsable por el uso indebido de tu Información Personal que
                    haga cualquier tercero cuando sean estos terceros quienes directamente
                    recolecten y/o traten tu Información Personal.
                </section>

                <section className='container'>
                    6) El uso de la Información Personal por otros Usuarios Ya tenés turno permite a
                    los usuarios un acceso limitado a ciertos datos (como nombre, apodo, correo
                    electrónico, otros datos de contacto) del resto de los usuarios para facilitar
                    la interacción entre ellos. Bajo ninguna circunstancia otros usuarios podrán
                    comunicar tu Información Personal a terceros sin tu consentimiento y/o el
                    consentimiento de Ya tenés turno, según corresponda. Cuando un usuario vendedor
                    recibe información personal de sus compradores, se constituye en responsable por
                    el tratamiento de esos datos y adquiere las obligaciones que ello implica. Por
                    ello, no puede usar los datos para una finalidad distinta o incompatible a la
                    que originó la recolección: completar una transacción en la plataforma de Ya
                    tenés turno. Por esta razón, sólo podrán utilizar la Información Personal de
                    otros usuarios obtenida en el sitio para: (a) fines relacionados con
                    transacciones en la plataforma de Ya tenés turno, (b) utilizar servicios
                    ofrecidos en Ya tenés turno (cancelación de turnos por reputación) y (c)
                    cualquier otra finalidad a la que el usuario correspondiente consienta
                    expresamente una vez le haya sido comunicada previamente la información
                    legalmente requerida. Ya tenés turno no controla, almacena ni tiene acceso a los
                    mensajes y contenidos enviados por los usuarios a través de medios diferentes a
                    los Sitios Web, por lo que no asume ninguna responsabilidad respecto a los daños
                    que pudiera ocasionar su uso. Las negociaciones llevadas a cabo por los usuarios
                    a través de dichos medios quedan bajo la propia responsabilidad de dichos
                    usuarios. YA TENÉS TURNO NO ES RESPONSABLE DEL USO QUE CUALQUIER USUARIO U OTRA
                    PERSONA PUDIERAN HACER DE LA INFORMACIÓN PUBLICADA EN LOS SITIOS WEB. LOS
                    USUARIOS ACEPTAN QUE YA TENÉS TURNO NO SERÁ RESPONSABLE DE LAS PÉRDIDAS NI DAÑOS
                    QUE SE PUEDAN GENERAR COMO RESULTADO DE LAS NEGOCIACIONES ENTRE USUARIOS. EN
                    CONSECUENCIA, LIBERAS A YA TENÉS TURNO DE TODO TIPO DE RESPONSABILIDAD EN CASO
                    DE QUE TENGAS UN CONFLICTO CON UNO O MÁS USUARIOS.
                </section>

                <section className='container'>
                    7) Publicidad, Cookies y Otras Tecnologías El usuario reconoce y acepta
                    expresamente que Ya tenés turno podrá utilizar un sistema de seguimiento de
                    conducta mediante la utilización de "cookies", y/u otras tecnologías similares
                    de seguimiento. Estas tecnologías se utilizan con el fin de conocer los
                    intereses y el comportamiento de quienes visitan o son usuarios de nuestro sitio
                    web y, de esa forma, darles un mejor servicio o proveerles información
                    relacionada. También usamos la información obtenida a través de cookies para
                    analizar las páginas navegadas por el visitante o usuario, las búsquedas
                    realizadas, mejorar nuestras iniciativas comerciales y promocionales, mostrar
                    publicidad o promociones, banners de interés, noticias sobre Ya tenés turno,
                    perfeccionar nuestra oferta de contenidos y artículos, personalizar dichos
                    contenidos, presentación y servicios, así como promover y hacer cumplir las
                    reglas y seguridad del sitio; también las utilizamos para para que el usuario no
                    tenga que introducir su clave tan frecuentemente durante una sesión de
                    navegación, también para contabilizar y corroborar las inscripciones, la
                    actividad del usuario y otros conceptos para acuerdos comerciales, siempre
                    teniendo como objetivo de la instalación de las cookies, el beneficio del
                    usuario que la recibe, y las cuales no serán usadas con fines ajenos a Ya tenés
                    turno. Asimismo, almacenamos cookies para poder ofrecer una experiencia más
                    interactiva en el sitio, basada en las acciones del usuario. Sus datos
                    personales obtenidos a través de estas tecnologías no serán transferidos a
                    terceros de una manera diferente a las descritas en esta Declaración de
                    Privacidad. Adicionalmente, se pueden encontrar "cookies" u otros sistemas
                    similares instalados por terceros en ciertas páginas de nuestros Sitios Web o
                    utilizados por anunciantes ajenos a Ya tenés turno. Estas Políticas de
                    Privacidad cubren la utilización de "cookies" por Ya tenés turno y no incluyen
                    el uso de "cookies" instaladas por terceros. Debes saber que la instalación,
                    permanencia y existencia de las cookies en tu computadora o dispositivo depende
                    de tu exclusiva voluntad y pueden ser eliminadas cuando usted así lo desees.
                    Para saber cómo quitar las Cookies del sistema es necesario revisar la sección
                    Ayuda (Help) del navegador.
                </section>

                <section className='container'>
                    8) Cambios en la Declaración de Privacidad Si Ya tenés turno realizara cambios
                    en la forma en que la Información Personal sea administrada, notificaremos a los
                    usuarios por nuestros canales habituales, como el correo electrónico o mensajes
                    a través de las aplicaciones. En los términos permitidos por la normativa de
                    cada país, para el caso de usuarios que no acepten esos nuevos términos, el
                    vínculo contractual con Ya tenés turno quedará disuelto y la Información
                    Personal de dicho usuario no será usada de otra forma que la que fue informada
                    al momento de recabarse.
                </section>

                <section className='container'>
                    9) Ley Aplicable y Jurisdicción La Declaración de Privacidad se regirá por las
                    leyes aplicables conforme se detalla en los anexos de cada país incluidos
                    debajo. Ante cualquier controversia o divergencia relacionada con la
                    interpretación, validez, celebración o cumplimiento de la presente Declaración
                    de Privacidad, se resolverá por los tribunales competentes indicados los
                    siguientes. Lo dispuesto en este anexo es de aplicación exclusiva para los
                    usuarios y visitantes domiciliados en la República Argentina. En caso de
                    contradicción entre lo dispuesto en el texto principal de la Declaración de
                    Privacidad y este anexo, regirá para los usuarios y visitantes domiciliados en
                    la República Argentina lo dispuesto en este anexo. ¿Quién es el responsable del
                    tratamiento de la Información Personal? En Argentina, los Servicios de Ya tenés
                    turno son prestados por Ya tenés turno con domicilio en Bernardo Quiroga 913,
                    San Carlos, Mendoza, República Argentina. Dichos Servicios de Ya tenés turno son
                    ofrecidos a través las Plataformas www.yatenesturno.com.ar. El responsable de la
                    base de datos recolectados en la República Argentina es Ya tenés turno. ¿Cómo
                    puedes ejercer tus derechos para controlar tu Información Personal? En
                    cumplimiento de lo dispuesto por las disposiciones de la Ley N° 25.326, el
                    Decreto Reglamentario N° 1558/2001 y las disposiciones y/o resoluciones
                    vinculantes emitidas por la Agencia de Acceso a la Información Pública se
                    comunica que: "el titular de los datos personales tiene la facultad de ejercer
                    el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a
                    seis meses, salvo que se acredite un interés legítimo al efecto conforme lo
                    establecido en el artículo 14, inciso 3 de la Ley Nº 25.326. LA AGENCIA DE
                    ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de Control de la Ley
                    N° 25.326, tiene la atribución de atender las denuncias y reclamos que
                    interpongan quienes resulten afectados en sus derechos por incumplimiento de las
                    normas vigentes en materia de protección de datos personales. El titular podrá
                    en cualquier momento solicitar el retiro o bloqueo de su nombre de los bancos de
                    datos a los que se refiere el presente artículo. En toda comunicación con fines
                    de publicidad que se realice por correo, teléfono, correo electrónico, Internet
                    u otro medio a distancia a conocer, se deberá indicar, en forma expresa y
                    destacada, la posibilidad del titular del dato de solicitar el retiro o bloqueo,
                    total o parcial, de su nombre de la base de datos. A pedido del interesado, se
                    deberá informar el nombre del responsable o usuario del banco de datos que
                    proveyó la información." Sin perjuicio de lo dispuesto en las Políticas de
                    Privacidad, también podrás realizar consultas y/o ejercer los derechos de
                    acceso, rectificación y supresión de tu Información Personal por correo
                    eléctrico a yatenesturno@gmail.com. Ley Aplicable y Jurisdicción Las Políticas
                    de Privacidad se regirán por las leyes de la República Argentina. Ante cualquier
                    controversia o divergencia relacionada con la interpretación, validez,
                    celebración o cumplimiento de las mismas, tú y Ya tenés turno declaran que se
                    someten a la jurisdicción exclusiva de los Tribunales Nacionales Ordinarios con
                    asiento en Mendoza, renunciando expresamente a cualquier otro fuero y/o
                    jurisdicción que pudiera corresponderles.
                </section>
            </div>
        </div>
    );
}
