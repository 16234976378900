import React from "react";
import "./button_modal.scss";

import Modal from "react-modal";
import { Button } from "react-bootstrap";

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    width: "95%",
    height: "90%",
    border: "none",
    borderRadius: "7px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0 , 0.66)",
    zIndex: 2000,
  },
};

export default function ButtonModal({
  label,
  children,
  variant,
  className,
  bsPrefix,
  modalIsOpen,
  setIsOpen,
}) {
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <Button
        onClick={openModal}
        variant={variant}
        className={className}
        bsPrefix={bsPrefix}
      >
        {label}
      </Button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
        contentLabel="Example Modal"
      >
        {children}
      </Modal>
    </>
  );
}
