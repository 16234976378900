function handleRes(res) {
  if (!res.ok) throw Error(res.statusText)

  return res.json().then((json) => {
    if (!json.result) {
      throw Error(json.message)
    }
    return json
  })
}

export const FETCH = (relativeUrl, method, body) => {
  let url = process.env.REACT_APP_API_URL + relativeUrl

  if (method === "GET" && body) {
    let searchParams = new URLSearchParams()
    Object.keys(body).forEach((key) => {
      searchParams.append(key, body[key])
    })
    searchParams = searchParams.toString()
    url = `${url}?${searchParams}`
    body = undefined
  }

  if (window.access_token) {
    return fetch(url, {
      method: method,
      body: JSON.stringify(body),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: "Token " + window.access_token,
      }),
    }).then((res) => res.json())
  }

  return false
}

export const FETCH_UNAUTHORIZED = (relativeUrl, method, body) => {
  let url = process.env.REACT_APP_API_URL + relativeUrl

  if (method === "GET" && body) {
    let searchParams = new URLSearchParams()
    Object.keys(body).forEach((key) => {
      searchParams.append(key, body[key])
    })
    searchParams = searchParams.toString()
    url = `${url}?${searchParams}`
    body = undefined
  }

  return fetch(url, {
    method: method,
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-type": "application/json",
    }),
  }).then(handleRes)
}
