import { useContext, useEffect, useRef, useState } from "react";
import { AiOutlinePlusCircle, AiOutlineArrowLeft } from "react-icons/ai";
import { BsCalendarX } from "react-icons/bs";
import { HiCursorClick } from "react-icons/hi";
import { Col, Spinner } from "react-bootstrap";

import SelectClient from "../../../child_components/SelectClient/select_client";
import FilterByDate from "../../../child_components/FilterByDate/filter_by_date";

import LayoutParent from "../../../layouts/parent";
import CircleImage from "../../../child_components/CircleImage/circle_image";
import CLientInfo from "../../../child_components/ClientInfo/client_info";
import ItemAccordion from "../../../child_components/ItemAccordion/item_accordion";
import CreditDetailRow from "../../../child_components/CreditDetailRow/credit_detail_row";
import SearchBar from "../../../child_components/SearchBar/search_bar";
import ButtonModal from "../../../child_components/ButtonModal/button_modal";
import ModalAddCredits from "../../../child_components/ModalAddCredits/modal_addcredits";

import { fetchGetFilterCreditsByDate } from "../../../../../requests/requests";
import { GetAllPlaceClients } from "../../../../utils/swr_callbacks";
import { PlaceContext } from "../../../PlaceContextProvider";
import objectsInArray from "../../../utils/objectsInArray";
import { Breakpoint } from "react-socks";
import SelectClientResponsive from "../../../child_components/SelectClientResponsive/select_client_responsive";

export default function CreditView({ handleSelect }) {
  const refContainerPopover = useRef();
  const { placeSelected } = useContext(PlaceContext);
  const { clients, isLoading, isError } = GetAllPlaceClients(placeSelected.id);

  const [clientSelected, setClientSelected] = useState(undefined);
  const [labelDaySelected, setLabelDaySelected] = useState(
    "Selecciona un cliente"
  );
  const [filterByDate, setFilterByDate] = useState(undefined);
  const [filterActive, setFilterActive] = useState(true);
  const [creditsForClient, setCreditsForClient] = useState([]);
  const [isLoadingCredits, setIsLoadingCredits] = useState(false);
  const [reload, setReload] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    //Change commerce
    setClientSelected(undefined);
  }, [placeSelected]);

  useEffect(() => {
    setIsLoadingCredits(true);
    if (clientSelected && filterActive && filterByDate) {
      const { start, end } = filterByDate;
      fetchGetFilterCreditsByDate(
        placeSelected.id,
        clientSelected.id,
        start,
        end
      ).then((res) => {
        setCreditsForClient(objectsInArray(res.data));
        setIsLoadingCredits(false);
        setReload(false);
      });
    }
  }, [filterByDate, clientSelected, reload]);

  const dataSelectClient = {
    clients,
    clientSelected,
    setClientSelected,
    setReload,
  };

  const dataModalAddCredits = {
    clients,
    clientSelected,
    setClientSelected,
    setReload,
    modalIsOpen,
    setIsOpen,
  };

  const dataCreditHistory = {
    placeSelected,
    clientSelected,
    setReload,
  };

  const dataFilterByDate = {
    cbk: (filters) => {
      setFilterByDate(filters);
    },
    filterActive,
    setFilterActive,
  };

  /**** RENDERS */
  const creditsHistory = creditsForClient.map((credit, key) => (
    <CreditDetailRow
      refContainerPopover={refContainerPopover}
      key={key}
      data={{ ...dataCreditHistory, ...credit }}
      edit
    />
  ));

  const labelButtonModal = (
    <span className="text-white fw-bold d-flex align-items-center justify-content-center gap-2">
      <AiOutlinePlusCircle />
      Cargar nuevos créditos
    </span>
  );
  return (
    <LayoutParent>
      <Col
        sm={12}
        md={6}
        className="bg-secondary  py-3 d-flex flex-column gap-3  overflow-hidden"
      >
        <div className="text-white  d-flex bg-primary p-2 general-border-radius justify-content-center fw-bold align-items-center position-relative fs-5">
          <button
            onClick={() => handleSelect("")}
            className="position-absolute start-0 mx-1"
          >
            <AiOutlineArrowLeft fill="#fff" size={25} />
          </button>
          <span>Sistema de créditos</span>
          <div className="position-absolute end-0 mx-1">
            <CircleImage
              size="lg"
              className="bg-white"
              borderColor="primary"
              src="credits.jpg"
            />
          </div>
        </div>
        {isLoading ? (
          <div className="full-height d-flex justify-content-center align-items-center">
            <Spinner size="sm" animation="border" variant="white" />
          </div>
        ) : (
          <>
            <Breakpoint
              customQuery="(min-width: 1000px)"
              className="flex-fill "
            >
              <SelectClient data={dataSelectClient} />
            </Breakpoint>
            <Breakpoint customQuery="(max-width: 999px)">
              <SelectClientResponsive data={dataSelectClient} />
            </Breakpoint>
          </>
        )}
      </Col>

      <Col className=" py-3 gap-2 ">
        {clientSelected ? (
          <div className="d-flex flex-column align-content-between gap-2 full-height ">
            <div>
              <CLientInfo client={clientSelected} />
            </div>

            <div
              ref={refContainerPopover}
              className="flex-fill  overflow-hidden gap-2"
            >
              <ItemAccordion
                active
                label="Historial de créditos"
                className="full-height"
              >
                <FilterByDate data={dataFilterByDate} />
                {!isLoadingCredits ? (
                  <div className="d-flex flex-column p-1 gap-3">
                    {creditsForClient.length ? (
                      creditsHistory
                    ) : (
                      <>No tiene historial de creditos en estas fechas</>
                    )}
                  </div>
                ) : (
                  <Spinner
                    size="sm"
                    animation="border"
                    variant="white"
                    className="m-2"
                  />
                )}
              </ItemAccordion>

              {/* add other item... */}
            </div>

            <ButtonModal
              label={labelButtonModal}
              modalIsOpen={modalIsOpen}
              setIsOpen={setIsOpen}
              className="p-2 general-border-radius"
              variant="primary"
            >
              <ModalAddCredits data={dataModalAddCredits} />
            </ButtonModal>
          </div>
        ) : (
          <div className="full-height d-flex align-items-center gap-3 justify-content-center text-white text-opacity-25">
            {!clientSelected ? (
              <HiCursorClick size={20} />
            ) : (
              <BsCalendarX size={20} />
            )}
            {labelDaySelected}
          </div>
        )}
      </Col>
    </LayoutParent>
  );
}
