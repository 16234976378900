import { useContext, useEffect, useState } from "react"
import Modal from "react-modal"
import { LoadingContext } from "react-router-loading"
import {
  cancelAppointment,
  fetchUserAppointments,
} from "../../requests/requests"
import Spinner from "../utils/Spinner"
import Calendar from "./Calendar/index"

import { isPastEvent } from "../utils/EventUtils"
import "./calendar.scss"

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0 , 0.66)",
  },
}
Modal.setAppElement("#root")

export default function CalendarView(props) {
  const [events, setEvents] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const [error, setError] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [selectedApp, setSelectedApp] = useState(undefined)
  const loadingContext = useContext(LoadingContext)
  const [isCancelling, setIsCancelling] = useState(false)
  const [canCancelApp, setCanCancelApp] = useState(true)
  const [hasCancelled, setHasCancelled] = useState(false)

  useEffect(() => {
    fetchUserAppointments()
      .then((res) => {
        window.scrollTo(0, 0)
        loadingContext.done()
        setEvents(buildEvents(res.content.appointments))
        setIsFetching(false)
      })
      .catch((err) => {
        console.log(err)
        setError(true)
        setIsFetching(false)
      })
  }, [])

  let buildEvents = (apps) => {
    let eventsObj = []
    apps.forEach((value) => {
      let fromDate = new Date(value.start)
      let toDate = new Date(value.end)

      fromDate.setHours(fromDate.getHours() - fromDate.getTimezoneOffset() / 60)
      toDate.setHours(toDate.getHours() - toDate.getTimezoneOffset() / 60)

      eventsObj.push({
        color: "#ff8672",
        from: fromDate.toString(),
        to: fromDate.toString(),
        id: value.id,
        title: value.title,
        service_names: value.service_names,
        contact: value.contact,
        employee: value.employee,
      })
    })

    return eventsObj
  }

  let onEventClicked = (event) => {
    setSelectedApp(event)
    setIsCancelling(false)
    setHasCancelled(false)
    setCanCancelApp(true)
    openModal()
  }

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function formatStartEndDates(from, to) {
    return `${formatDate(from)}`
  }

  function formatDate(dateStr) {
    let date = new Date(Date.parse(dateStr))
    date.setHours(date.getHours() + date.getTimezoneOffset() / 60)
    let hours = date.getHours()
    let minutes = date.getMinutes()

    if (hours < 10) {
      hours = "0" + hours
    }
    if (minutes < 10) {
      minutes = "0" + minutes
    }

    return `${hours}:${minutes}`
  }

  function onCancelAppointment() {
    setIsCancelling(true)
    cancelAppointment(selectedApp.id)
      .then(() => {
        setIsCancelling(false)
        setHasCancelled(true)
        removeApp(selectedApp.id)
      })
      .catch((err) => {
        setIsCancelling(false)
        setHasCancelled(false)
        setCanCancelApp(false)
      })
  }

  function removeApp(id) {
    let aux = events.slice()
    aux = aux.filter((element) => {
      return element.id !== id
    })
    setEvents(aux)
  }

  if (isFetching) {
    return <Spinner />
  }

  if (error) {
    return "Se produjo un error, reinténtelo mas tarde"
  }

  return (
    <div id="calendarContainer">
      <Calendar color="#ff8672" events={events} onClickEvent={onEventClicked} />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
        contentLabel="Modal Turno"
      >
        {selectedApp && (
          <div className="text-dark p-4">
            <div className="h3 fw-bold">{selectedApp.title} </div>
            <br />

            <div>
              <i className="fas fa-clock me-3 icon"></i>
              {formatStartEndDates(selectedApp.from, selectedApp.to)}
            </div>

            <div className="mt-2">
              <i className="fas fa-suitcase me-3 icon"></i>
              {selectedApp.service_names}
            </div>

            <div className="mt-2">
              <i className="fas fa-user me-3 icon"></i>
              {selectedApp.employee}
            </div>
            <div className="mt-2">
              <i className="fas fa-phone me-3  icon"></i>
              {selectedApp.contact}
            </div>

            <div>
              {!isPastEvent(selectedApp) && (
                <CancelButton
                  onCancelAppointment={onCancelAppointment}
                  isCancelling={isCancelling}
                  hasCancelled={hasCancelled}
                  canCancelApp={canCancelApp}
                  appointment={selectedApp}
                />
              )}
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

function CancelButton({
  onCancelAppointment,
  isCancelling,
  hasCancelled,
  canCancelApp,
}) {
  if (!hasCancelled && canCancelApp) {
    return (
      <button
        onClick={onCancelAppointment}
        className="btnCancelApp btn btn-danger mt-4 text-white"
      >
        {isCancelling && (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        )}
        Cancelar Turno
      </button>
    )
  }

  if (hasCancelled) {
    return (
      <button className="btnCancelApp btn btn-danger mt-4 text-white" disabled>
        Turno cancelado
      </button>
    )
  }

  return (
    <div className="btnCancelApp mt-4 text-black" disabled>
      Este turno no puede ser cancelado por este medio. Por favor, comuníquese
      con el lugar para realizarlo.
      <br />
    </div>
  )
}
