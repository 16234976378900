import React, { useContext, useEffect, useState } from 'react';
import { LoadingContext } from 'react-router-loading';
import Logo from '../../nav/Logo';

export default function PaymentSuccessful() {
    const loadingContext = useContext(LoadingContext);
    const [countDown, setCountDown] = useState(10);

    const tick = () => {
        setCountDown(countDown - 1);
    };

    useEffect(() => {
        loadingContext.done();

        if (countDown > 0) {
            const timerId = setInterval(() => tick(), 1000);
            return () => clearInterval(timerId);
        }
    }, [countDown]);

    return (
        <div className='mt-5 pt-5'>
            <Logo className='h-25 w-25' />
            <div className='m-3 pt-2 fw-bold h3 text-uppercase'>
                Pago realizado con éxito
            </div>
            <div className='text-light'>¡Gracias! Los beneficios están disponibles inmediatamente</div>
            <i className='text-light'>Se te redireccionará en {countDown} segundos.</i>
        </div>
    );
}
