import { useContext, useEffect, useState } from "react"
import { Breakpoint } from "react-socks"
import "./my_commerce.scss"

import { LoadingContext } from "react-router-loading"
import { PlaceContext } from "./PlaceContextProvider"

//Boostrap
import { IoBuildOutline } from "react-icons/io5"
import { BiRefresh } from "react-icons/bi"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

//Components
import Sidebar from "./Sidebar/sidebar"
import MyShopCalendar from "./parents_components/MyShopCalendar/my_shop_calendar"
import MyShopClients from "./parents_components/MyShopClients/my_shop_clients"
import MyShopPayments from "./parents_components/MyShopPayments/my_shop_payments"
import SidebarMobile from "./SidebarMobile/sidebar_mobile"
import { fetchServices } from "../../requests/requests"
import Loading from "./child_components/Loading/loading"
import objectsInArray from "./utils/objectsInArray"

export default function MyCommerce() {
  const [menuItemActive, setMenuItemActive] = useState("calendar")

  const loadingContext = useContext(LoadingContext)
  const { placeSelected, userPlaces } = useContext(PlaceContext)
  const [services, setServices] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (placeSelected) {
      fetchServices(placeSelected.id).then((res) => {
        if (res.result) {
          setServices(objectsInArray(res.content.services).length)
          setLoading(false)
        }
      })
    }
    setReload(false)
  }, [placeSelected, reload])

  useEffect(async () => {
    loadingContext.done()
    window.scrollTo(0, 0)
  }, [])

  //Menu
  const handleMenu = (item) => {
    setMenuItemActive(item)
  }
  if (!loading) {
    if (userPlaces.length == 0) {
      return (
        <Container className="my-commerce-root ">
          <Row className="full-height overflow-hidden py-2 d-flex flex-column align-items-center justify-content-center text-center">
            Oh! no tienes comercios registrados
          </Row>
        </Container>
      )
    } else {
      return (
        <>
          <Breakpoint customQuery="(min-width: 1000px)">
            <Container className="my-commerce-root ">
              <Row className="full-height overflow-hidden py-2">
                <Col md={12} lg={2}>
                  <Row className="full-height">
                    <Sidebar active={menuItemActive} click={handleMenu} />
                  </Row>
                </Col>
                {services ? (
                  <Col
                    md={12}
                    lg={10}
                    className="full-height overflow-auto p-0"
                  >
                    {menuItemActive == "calendar" && <MyShopCalendar />}
                    {menuItemActive == "clients" && <MyShopClients />}
                    {menuItemActive == "cobros" && <MyShopPayments />}
                  </Col>
                ) : (
                  <Col
                    md={12}
                    lg={10}
                    className="full-height overflow-auto p-0 d-flex flex-column align-items-center justify-content-center align-items-center text-center"
                  >
                    <>
                      <IoBuildOutline size={100} />
                      <span className="fw-bold text-primary">
                        Debes configurar tus servicios
                      </span>
                      <span
                        role="button"
                        className="fw-bold text-white"
                        onClick={() => setReload(true)}
                      >
                        <BiRefresh size={30} /> <u>Cargar de nuevo</u>
                      </span>
                    </>
                  </Col>
                  // <Row className="h-100 overflow-hidden text-white text-opacity-50 gap-3 py-2 flex-column align-items-center justify-content-center align-items-center text-center">
                  //   <IoBuildOutline size={100} />
                  //   <span className="fw-bold text-primary">
                  //     Debes configurar tus servicios
                  //   </span>
                  // </Row>
                )}
              </Row>
            </Container>
            <div className="vh-100 d-flex justify-content-center align-items-center text-white message-mobile fixed-top bg-dark">
              Recarga la pagina para ver la version movil.
            </div>
          </Breakpoint>

          <Breakpoint
            customQuery="(max-width: 999px)"
            className="d-flex justify-content-center align-items-center vh-100 active-mobile"
          >
            <div className="my-commerce-root">
              <div className="h-100 py-2 d-flex flex-column">
                <section className="sticky-top">
                  <SidebarMobile active={menuItemActive} click={handleMenu} />
                </section>
                {services ? (
                  <section className=" flex-fill p-0 overflow-auto">
                    {menuItemActive == "calendar" && <MyShopCalendar />}
                    {menuItemActive == "clients" && <MyShopClients />}
                    {menuItemActive == "cobros" && <MyShopPayments />}
                  </section>
                ) : (
                  <div className="h-100 overflow-hidden text-white text-opacity-50 gap-3 py-2 flex-column align-items-center justify-content-center align-items-center text-center">
                    <>
                      <IoBuildOutline size={100} />
                      <span className="fw-bold text-primary">
                        Debes configurar tus servicios
                      </span>
                      <span
                        role="button"
                        className="fw-bold text-white"
                        onClick={() => setReload(true)}
                      >
                        <BiRefresh size={30} /> <u>Cargar de nuevo</u>
                      </span>
                    </>
                  </div>
                )}
              </div>
            </div>
            <div className="vh-100 d-flex justify-content-center align-items-center text-white message-desktop fixed-top bg-dark">
              Recarga la pagina para ver la version de escritorio.
            </div>
          </Breakpoint>
        </>
      )
    }
  } else {
    return <Loading />
  }
}
