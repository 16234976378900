import useSWR from "swr"
import objectsInArray from "../my_commerce/utils/objectsInArray"

import {
  fetchGetShops,
  fetchGetCites,
  fetchGetCEmployeds,
  fetchGetFilterCitesByDate,
  fetchGetClientsByEmployed,
  fetchGetUserContactMethod,
  fetchGetClientCredits,
  fetchGetCitesEmployed,
  fetchGetAllPlaceClients,
  fetchGetPlaceCredits,
  fetchGetServices,
  fetchGetJobDayOff,
  fetchGetCitesByClientID,
  fetchGetLabels,
} from "../../requests/requests"

export const GetUserPlaces = () => {
  const url = `${process.env.REACT_APP_API_URL}/android/profile/places/`
  const { data, error } = useSWR(url, fetchGetShops)
  const isLoading = !error && !data

  let places = []

  if (!isLoading) {
    for (const place in data.places) {
      places.push(data.places[place])
    }
  }

  return {
    places,
    isLoading,
    isError: error,
  }
}

export const GetEmployeds = (placeId) => {
  const url = `${process.env.REACT_APP_API_URL}/android/jobs-in-place/?place_id=${placeId}`
  const { data, error } = useSWR(url, () => fetchGetCEmployeds(placeId))
  const isLoading = !error && !data

  let employeds = []

  if (!isLoading) {
    for (const employed in data.jobs) {
      employeds.push(data.jobs[employed])
    }
  }

  return {
    employeds,
    isLoading,
    isError: error,
  }
}

export const GetAllPlaceClients = (placeId) => {
  const url = `${process.env.REACT_APP_API_URL}/android/place/${placeId}/clients`
  const { data, error } = useSWR(url, () => fetchGetAllPlaceClients(placeId))
  const isLoading = !error && !data

  let clients = []

  if (!isLoading) {
    for (const client in data.users) {
      clients.push(data.users[client])
    }
  }

  return {
    clients,
    isLoading,
    isError: error,
  }
}

export const GetUserNotification = (userID) => {
  const url = `${process.env.REACT_APP_API_URL}/android/ notification-method/?client_id=${userID}`
  const { data, error } = useSWR(url, () => fetchGetUserContactMethod(userID))
  const isLoading = !error && !data

  return {
    data,
    isLoading,
    isError: error,
  }
}

export const GetCreditsClient = (placeID, clientID) => {
  const url = `${process.env.REACT_APP_API_URL}/place-credits/places/${placeID}/clients/${clientID}/credits`
  const { data: res, error } = useSWR(url, () =>
    fetchGetClientCredits(placeID, clientID)
  )
  const isLoading = !error && !res

  let credits = []

  if (!isLoading) {
    for (const credit in res.data) {
      credits.push(res.data[credit])
    }
  }

  return {
    credits,
    isLoading,
    isError: error,
  }
}

export const GetPlaceServices = (placeID) => {
  const url = `${process.env.REACT_APP_API_URL}/api/place/${placeID}/services`
  const { data: res, error } = useSWR(url, () => fetchGetPlaceCredits(placeID))
  const isLoading = !error && !res

  let services = []
  if (!isLoading) {
    for (const service in res.content.services) {
      res.content.services[service].forEach((item) => {
        services.push(item)
      })
    }
  }

  return {
    services,
    isLoading,
    isError: error,
  }
}

export const GetServices = (placeID) => {
  const url = `${process.env.REACT_APP_API_URL}/api/place/${placeID}/services`
  const { data: res, error } = useSWR(url, () => fetchGetServices(placeID))
  const isLoading = !error && !res

  let services = []

  if (!isLoading) {
    for (const service in res.content.services) {
      services.push({
        name: service,
        job_types: res.content.services[service],
      })
    }
  }

  return {
    services,
    isLoading,
    isError: error,
  }
}

export const GetJobDaysOff = (employedID) => {
  const url = `${process.env.REACT_APP_API_URL}/android/job/${employedID}/days-off`
  const { data, error } = useSWR(url, () => fetchGetJobDayOff(employedID))
  const isLoading = !error && !data

  return {
    data,
    isLoading,
    isError: error,
  }
}

export const GetCitesByClientID = (employedID, clientID) => {
  const url = `${process.env.REACT_APP_API_URL}/api/appointments/${employedID}/${clientID}`
  const { data: res, error } = useSWR(url, () =>
    fetchGetCitesByClientID(employedID, clientID)
  )
  const isLoading = !error && !res

  let cites = []

  if (!isLoading) {
    for (const cite in res.content.appointments) {
      cites.push(res.content.appointments[cite])
    }
  }

  return {
    cites,
    isLoading,
    isError: error,
  }
}

export const GetClientsByEmployed = (employedID) => {
  const url = `${process.env.REACT_APP_API_URL}/android/clients-of-job/?job_id=${employedID}`
  const { data: res, error } = useSWR(url, () =>
    fetchGetClientsByEmployed(employedID)
  )
  const isLoading = !error && !res

  let clients = []

  if (!isLoading) {
    for (const client in res.users) {
      clients.push(res.users[client])
    }
  }

  return {
    clients,
    isLoading,
    isError: error,
  }
}

export const GetLabels = (placeID, jobID) => {
  const url = `${process.env.REACT_APP_API_URL}/android/place/${placeID}/job/${jobID}/labels`
  const { data, error } = useSWR(url, () => fetchGetLabels(placeID, jobID))
  const isLoading = !error && !data

  let labels = []

  if (!isLoading) {
    labels = data.content
  }

  return {
    labels,
    isLoading,
    isError: error,
  }
}

export const GetCitesClientByDate = (jobID, clientID, dateStart, dateEnd) => {
  const url = `${process.env.REACT_APP_API_URL}/api/appointments/${jobID}/${clientID}/${dateStart}/${dateEnd}`
  const { data, error } = useSWR(url, () =>
    fetchGetFilterCitesByDate(jobID, clientID, dateStart, dateEnd)
  )
  const isLoading = !error && !data

  return {
    data,
    isLoading,
    isError: error,
  }
}
