import React from "react"
import "./client_info.scss"

import { AiOutlineMail, AiOutlineWhatsApp } from "react-icons/ai"
import { FaRegUserCircle } from "react-icons/fa"

import { Row, Col, Stack, Spinner } from "react-bootstrap"

import CircleImage from "../CircleImage/circle_image"

//Request
import { GetUserNotification } from "../../../utils/swr_callbacks"

export default function CLientInfo({ client }) {
  const { data, isLoading, isError } = GetUserNotification(client.id)

  return (
    <Stack className="text-center p-2 bg-black rounded-1">
      <div className="d-flex text-center align-items-center justify-content-center gap-1">
        {client.profile_pic ? (
          <CircleImage size="sm" src={client.profile_pic} borderColor="0" />
        ) : (
          <FaRegUserCircle size={20} />
        )}
        <span className="fw-bold fs-5">{`${client.given_name} ${client.family_name}`}</span>
      </div>
      <Row className="text-center fs-7">
        <Col
          sm={12}
          className="d-flex gap-1 align-items-center justify-content-center"
        >
          <AiOutlineMail />
          <a href={`mailto:${client.email}`}>{client.email}</a>
        </Col>
        <Col
          sm={12}
          className="d-flex gap-1 align-items-center justify-content-center"
        >
          {isLoading ? (
            <Spinner size="sm" animation="border" variant="white" />
          ) : data.notification.notification_type == "whatsapp" ? (
            <>
              <AiOutlineWhatsApp />
              <a
                href={`https://wa.me/${data.notification.contact_info}`}
                target="_blank"
              >
                {data.notification.contact_info}
              </a>
            </>
          ) : (
            <>Sin whatsapp</>
          )}
        </Col>
      </Row>
    </Stack>
  )
}
