import React from 'react';

const style = {
    localize: {
        height: '20px',
        width: '20px',
        backgroundImage:
            'url("data:image/svg+xml;utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Capa_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%22466.712px%22%20height%3D%22466.712px%22%20viewBox%3D%220%200%20466.712%20466.712%22%20style%3D%22enable-background%3Anew%200%200%20466.712%20466.712%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%09%3Cg%3E%0A%09%09%3Cg%3E%0A%09%09%09%3Cpath%20fill%3D%22%23ff8672%22%20d%3D%22M233.353%2C0C178.301%2C0%2C126.625%2C31.355%2C100.94%2C80.019c-13.302%2C25.216-19.077%2C54.085-16.639%2C82.496%0A%09%09%09c3.088%2C35.802%2C19.44%2C62.606%2C40.401%2C90.566c46.11%2C61.487%2C74.454%2C134.439%2C101.735%2C205.947c3.912%2C10.251%2C10.261%2C10.242%2C14.172-0.009%0A%09%09%09c26.718-69.94%2C54.478-140.856%2C98.59-201.74c18.724-25.848%2C35.773-49.209%2C41.491-81.578c5.336-30.236%2C1.187-61.879-11.914-89.658%0A%09%09%09C344.307%2C34.138%2C290.775%2C0%2C233.353%2C0z%20M233.353%2C194.157c-25.838%2C0-47.411-21.583-47.411-47.421%0A%09%09%09c0-25.847%2C21.573-47.42%2C47.411-47.42c25.848%2C0%2C47.431%2C21.573%2C47.431%2C47.42C280.783%2C172.574%2C259.2%2C194.157%2C233.353%2C194.157z%22%20%2F%3E%0A%09%09%3C%2Fg%3E%0A%09%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%3C%2Fsvg%3E")',
        backgroundSize: '20px 20px',
        backgroundRepeat: 'no-repeat',
    },
    caption: {
        cursor: 'pointer',
    },
};

export default function GoToMaps({ lat, lon, showCaption }) {
    const goToMaps = useGoToMaps(lat, lon);

    return (
        <div onClick={goToMaps} className='h-100 row' style={style.caption}>
            <div className='col-1'>
                <button style={style.localize} className='btn p-0 my-auto mb-1' />
            </div>
            <div className='col-4'>
                {showCaption && <span className='my-auto ms-1'>Localizar</span>}
            </div>
        </div>
    );
}

export function useGoToMaps(lat, lon) {
    const getMapsLink = () => {
        return `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;
    };

    const redirectToMaps = (e) => {
        e.stopPropagation();
        e.preventDefault();

        window.open(getMapsLink(), '_blank').focus();
    };

    return redirectToMaps;
}

export function goToMaps(e, lat, lon) {
    const getMapsLink = () => {
        return `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;
    };

    e.stopPropagation();
    e.preventDefault();
    window.open(getMapsLink(), '_blank').focus();
}
