import {
  fetchGetServices,
  fetchGetEmployedByService,
} from "../../../requests/requests"
import { convertIntDaysInStr } from "./daysOpenEmployed"
import objectsInArray from "./objectsInArray"
export async function getEmployedByService(placeID, employedID) {
  //placeID = Para buscar los servicios y los empleados del servicio
  //employedID = Para comprobar que el servicio pertenece al usuario

  let serviceProvider
  let servicesEmploye = []
  let openDays = []

  await fetchGetServices(placeID).then(async (res) => {
    const services = objectsInArray(res.content.services)

    for (const service of services) {
      for (const item of service) {
        await fetchGetEmployedByService(placeID, item.id).then((res) => {
          const info_employedd = objectsInArray(res.content)

          info_employedd.forEach((obj) => {
            if (employedID === obj.job.service_provider.id) {
              serviceProvider = obj
              servicesEmploye.push(item)
              obj.days.forEach((day) => {
                if (!openDays.includes(day)) {
                  openDays.push(day)
                }
              })
            }
          })
        })
      }
    }
  })

  return {
    serviceProvider,
    servicesEmploye,
    openDays: convertIntDaysInStr(openDays),
  }
}
