import { useState, useRef } from "react";
import "./credit_detail_row.scss";
import { useForm } from "react-hook-form";
import { RiEditBoxLine, RiCoinLine, RiDownload2Line } from "react-icons/ri";
import { Col, Row, Container, Modal, Button, Form } from "react-bootstrap";

import ButtonModal from "../ButtonModal/button_modal";
import TooltipEditCredits from "../TooltipEditCredits/tooltip_edit_credits";

export default function CreditDetailRow({ edit, data, refContainerPopover }) {
  const ref = useRef(null);

  let services = [];
  data.service_credits.forEach((data) => {
    services.push(data.service.name);
  });
  const listServices = services.join(" - ");

  const fromDay = data.valid_from.split("-")[2];
  const fromMonth = data.valid_from.split("-")[1];

  const untilDay = data.valid_until.split("-")[2];
  const untilMonth = data.valid_until.split("-")[1];

  const textServices = () => {
    if (listServices.length >= 15)
      return <marquee scrolldelay={200}>{listServices}</marquee>;
    else return <>{listServices}</>;
  };

  const labelEditCite = <RiEditBoxLine size={20} />;
  return (
    <div className="bg-gray rounded-3 position-relative d-flex flex-column gap-2 fs-7 text-primary p-1">
      <Container className="px-1">
        <Row className="justify-content-between ">
          <Col xs={6} className="text-nowrap text-start">
            {textServices()}
          </Col>
          <Col xs={4} className="text-end">
            <RiCoinLine />{" "}
            <span className="fw-bold text-white">
              {data.current_credits} de {data.credits}
            </span>
          </Col>
          {edit && (
            <>
              <Col xs="auto">
                <span role="button">
                  <TooltipEditCredits
                    data={data}
                    domRef={refContainerPopover}
                  />
                </span>
              </Col>
            </>
          )}
        </Row>
        <Row>
          <div className="d-flex gap-3">
            <span className="text-start">Vigencia: </span>
            <span className="flex-fill  fw-bold text-white text-start">
              {fromDay} / {fromMonth} al {untilDay} / {untilMonth}
            </span>
          </div>
        </Row>
      </Container>
    </div>
  );
}
