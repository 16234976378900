export function statusCite(dateCite) {
  const currentDate = new Date().getTime();
  const citeDate = new Date(dateCite).getTime();
  return citeDate > currentDate;
}

export function filterLabelByName(searchByName, arrayLabels) {
  return arrayLabels.filter((label) =>
    label.name.toLowerCase().includes(searchByName.toLowerCase())
  )[0];
}

export function filterLabelById(labelID, arrayLabels) {
  return arrayLabels.filter((label) => labelID == label.id)[0];
}

export function getColorDefault(dateCite, arrayLabels) {
  let dataLabel = undefined;
  if (statusCite(dateCite)) {
    //Si el turno es futuro
    dataLabel = {
      name: "A realizar",
      color: "#ff8672",
    };
  } else {
    //Si el turno es pasado
    dataLabel = {
      name: "Realizado",
      color: "#ff8672",
    };
  }

  return dataLabel;
}
