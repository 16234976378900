import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { fetchStats } from '../../../requests/requests';

import './stats.scss';

export default function StatsView() {
    const [isFetching, setIsFetching] = useState(true);
    const [error, setError] = useState(false);
    const [stats, setStats] = useState(undefined);

    useEffect(() => {
        fetchStats()
            .then((res) => {
                setStats(res.content);
                setIsFetching(false);
            })
            .catch((err) => {
                setError(true);
                setIsFetching(false);
            });
    }, []);

    if (isFetching) return <div>fetching</div>;

    return (
        <div id='containerStats' className='p-3'>
            <div className='container row mx-0'>
                <span className='col fw-bold '>Turnos</span>
                <span className='col fw-bold text-start'>{stats.stat_apps_count}</span>
            </div>
            <div className='container row mx-0'>
                <span className='col fw-bold '>Clientes</span>
                <span className='col fw-bold text-start'>{stats.stat_clients_count}</span>
            </div>
            <div className='container row mx-0'>
                <span className='col fw-bold '>Lugares</span>
                <span className='col fw-bold text-start'>{stats.stat_places_count}</span>
            </div>
            <div className='container pt-3 pb-3' id='jobTypes'>
                {stats.job_types_count.map((job_type) => {
                    return (
                        <div className='row' key={job_type[0]}>
                            <span className='col text-end'>{job_type[0]}</span>
                            <span className='col text-start'>{job_type[1]}</span>
                        </div>
                    );
                })}
            </div>
            <div className='container pt-3 pb-3'>
                {stats.versions.map((version) => {
                    return (
                        <div className='row' key={version[0]}>
                            <span className='col text-end'>{version[0]}</span>
                            <span className='col text-start'>
                                {version[1]}
                                {' -> '}
                                {((version[1] / stats.versions_total) * 100).toFixed(2) + '%'}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
