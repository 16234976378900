import React, { useEffect } from 'react';

/**
 * Component to check and render if the suer can continue booking apps. Taking into account
 * the maximum amount of appointments a user can have with the selected services in the future
 */
export default function BookingLimitWarning({ setReachedMaxApps, maximumApps, selectedAppsCount }) {
    useEffect(() => {
        setReachedMaxApps(reachedMax);
    }, [selectedAppsCount]);

    function reachedMax() {
        return maximumApps - selectedAppsCount <= 0;
    }

    if (!reachedMax()) {
        return null;
    }

    return (
        <div className='mx-4 text-danger'>
            Llegaste al número máximo de turnos reservados en simultáneo para este lugar. A medida
            que utilices alguno, volverá a habilitarse para sacar otro.
        </div>
    );
}
