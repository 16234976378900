import { useEffect } from 'react';
import { useState } from 'react';
import Calendar from 'react-calendar';
import { fetchAvailableAppointments, fetchNearAppointments } from '../../requests/requests';
import Spinner from '../utils/Spinner';

import './date-picker.scss';
import Skeleton from '../utils/Skeleton';
import { parseAppointmentData } from '../utils/dateParser';
import moment from 'moment';
import CreditsWarning from './CreditsWarning';
import BookingLimitWarning from './BookingLimitWarning';
import styles from './place/place-view.module.scss';

export default function DateSelection({
    jobId,
    daysOff,
    placeId,
    services,
    jobDays,
    onConfirm,
    selectedAppsProp,
    emergencyToday,
}) {
    const [currSelectedDate, setCurrSelectedDate] = useState(null);
    const [selectedApps, setSelectedApps] = useState(selectedAppsProp);
    const [showCalendar, setShowCalendar] = useState(false);
    const [reachedMaxApps, setReachedMaxApps] = useState(false);
    const [reachedMinCredits, setReachedMinCredits] = useState(false);
    const [maximumApps, setMaximumApps] = useState(undefined);

    const onDateSelected = (date) => {
        setCurrSelectedDate(date);
    };

    const addCalendarAppointment = (app, local) => {
        let localDateStr =
            currSelectedDate.getDate() +
            '-' +
            ((currSelectedDate.getMonth() + 1) % 13) +
            ' ' +
            local;

        addAppointment(app, localDateStr);
    };

    const addNearAppointment = (date, app, local) => {
        let localDateStr = date.getDate() + '-' + ((date.getMonth() + 1) % 13) + ' ' + local;

        addAppointment(app, localDateStr);
    };

    const addAppointment = (app, localDateStr) => {
        let aux = selectedApps.slice();

        if (isValid(app)) {
            aux.push({
                appointment: app,
                local_time: localDateStr,
            });

            setSelectedApps(aux);
            setCurrSelectedDate(null);
        }
    };

    const isValid = (app) => {
        let date = parseAppointmentData(app[0]);

        return new Date() < date;
    };

    const removeAppointment = (index) => {
        let aux = selectedApps.slice();
        aux.splice(index, 1);
        setSelectedApps(aux);
    };

    const disableDay = ({ activeStartDate, date, view }) => {
        const isToday = () => {
            return (
                date.getMonth() === moment().get('month') && date.getDate() === moment().get('date')
            );
        };

        const isDayOff = () => {
            if (daysOff) {
                for (let i = 0; i < daysOff.length; i++) {
                    let parsedDayOff = moment(daysOff[i].date, 'YYYY-MM-DD');
                    let parsedDate = moment(date);

                    if (parsedDate.isSame(parsedDayOff, 'date')) {
                        return true;
                    }
                }
            }

            return false;
        };

        if (emergencyToday && isToday()) {
            return false;
        }

        return !jobDays.includes((date.getDay() + 1) % 8) || isDayOff();
    };

    function toggleCalendar() {
        setShowCalendar(true);
    }

    return (
        <div className='pb-5' id='date-picker'>
            <div className='mt-2 mb-2'>
                <button
                    className={
                        `${styles.btnContinue} ` +
                        (selectedApps.length > 0 ? '' : `${styles.hidden}`)
                    }
                    onClick={() => onConfirm(selectedApps)}
                >
                    Continuar
                </button>
            </div>

            <CreditsWarning
                setReachedMinCredits={setReachedMinCredits}
                selectedServices={services}
                selectedAppsCount={selectedApps.length}
            />

            <SelectedAppList selectedApps={selectedApps} onRemove={removeAppointment} />

            {!reachedMaxApps && !reachedMinCredits && (
                <>
                    {!showCalendar && !currSelectedDate && (
                        <>
                            <NearApps
                                placeId={placeId}
                                jobId={jobId}
                                services={services}
                                selectedApps={selectedApps}
                                show={!currSelectedDate && selectedApps.length === 0}
                                onSelected={addNearAppointment}
                                onSelectedMore={onDateSelected}
                                onMaximumFetched={setMaximumApps}
                            />
                            <div className='mx-auto my-auto btnOpenCalendarContainer'>
                                <div onClick={toggleCalendar} className='p-2 btnOpenCalendar'>
                                    Abrir calendario
                                </div>
                            </div>
                        </>
                    )}

                    {(showCalendar || currSelectedDate) && (
                        <>
                            <Calendar
                                minDetail='year'
                                locale='es'
                                minDate={new Date()}
                                tileDisabled={disableDay}
                                onChange={onDateSelected}
                                value={currSelectedDate}
                            />
                            {currSelectedDate && (
                                <>
                                    <div className='fw-bold text-light freeLabel text-start mx-auto ps-4 mt-3'>
                                        TURNOS LIBRES
                                    </div>
                                    <TimePicker
                                        key={currSelectedDate}
                                        onSelected={addCalendarAppointment}
                                        jobId={jobId}
                                        services={services}
                                        placeId={placeId}
                                        selectedDate={currSelectedDate}
                                        selectedApps={selectedApps}
                                    />
                                </>
                            )}
                        </>
                    )}
                </>
            )}
            {Number.isInteger(maximumApps) && (
                <BookingLimitWarning
                    setReachedMaxApps={setReachedMaxApps}
                    selectedServices={services}
                    maximumApps={maximumApps}
                    selectedAppsCount={selectedApps.length}
                />
            )}
        </div>
    );
}

function SelectedAppList(props) {
    function onRemove(index) {
        props.onRemove(index);
    }

    if (props.selectedApps.length === 0) {
        return null;
    }

    return (
        <div className='mb-3'>
            <div className='text-start fw-bold ps-2 text-light selectedAppsLabel mx-auto'>
                TURNOS SELECCIONADOS
            </div>
            <div className='mx-auto selectedAppsContainer'>
                {props.selectedApps.map((app, index) => {
                    return (
                        <SelectedAppView key={index} index={index} app={app} onRemove={onRemove} />
                    );
                })}
            </div>
        </div>
    );
}

function SelectedAppView({ app, index, onRemove }) {
    const [expanded, setExpanded] = useState(false);

    function onCloseClick() {
        onRemove(index);
    }

    function toggleExpand() {
        setExpanded(!expanded);
    }

    return (
        <div
            key={index}
            className={`selectedApp m-2 p-1 ${expanded ? 'expanded' : ''}`}
            onClick={toggleExpand}
        >
            <div className=' m-0 p-0 selectedAppText'>{app.local_time}</div>
            {expanded && (
                <div className='selectedAppRemove my-auto ms-2'>
                    <div onClick={onCloseClick} />
                </div>
            )}
        </div>
    );
}

function TimePicker(props) {
    const [isFetching, setIsFetching] = useState(true);
    const [availableAppointments, setAvailableAppointments] = useState([]);
    const [error, setError] = useState(false);
    const [maximumAppsInDay, setMaximumAppsInDay] = useState(false);

    useEffect(() => {
        let servicesIds = [];
        props.services.map((v) => servicesIds.push(v.id));
        fetchAvailableAppointments(
            props.placeId,
            props.jobId,
            servicesIds,
            props.selectedDate,
            props.selectedApps,
        )
            .then((res) => {
                setAvailableAppointments(res.content.divisions);
                setMaximumAppsInDay(res.content.remaining_apps_in_day);
                setIsFetching(false);
            })
            .catch((err) => {
                setError(true);
                setIsFetching(false);
                console.log(err);
            });
    }, []);

    /**
     * Check whether the user can continue booking in the selected Date
     * Takes into account the already selected appointments
     * @returns true if can continue booking, false otherwise
     */
    const reachedMaxAppsInDay = () => {
        let sameDayAsSelected = 0;
        props.selectedApps.forEach((app, i) => {
            let startString = app.appointment[0][Object.keys(app.appointment[0])].start;
            let appDate = moment(startString, 'YYYY-MM-DD h:mm:ss').toDate();

            if (appDate.getDate() === props.selectedDate.getDate()) {
                sameDayAsSelected++;
            }
        });
        return maximumAppsInDay - sameDayAsSelected <= 0;
    };

    if (isFetching) {
        return <Spinner />;
    }

    if (!error) {
        return (
            <div className='row container-sm m-auto justify-content-center mt-2 containerApps mb-5 pb-5'>
                {reachedMaxAppsInDay() ? (
                    <div className='mx-3 text-danger'>
                        Alcanzaste el límite de turnos para este servicio en este día.
                    </div>
                ) : availableAppointments.length > 0 ? (
                    availableAppointments.map((division, index) => {
                        return (
                            <Division key={index} data={division} onSelected={props.onSelected} />
                        );
                    })
                ) : (
                    'No hay turnos disponibles en la fecha seleccionada'
                )}
            </div>
        );
    }
    return null;
}

function stringFormatApp(appointmentData) {
    let date = parseAppointmentData(appointmentData);
    let dateStr = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
    return dateStr;
}

function Division({ data, onSelected }) {
    let dateStr = stringFormatApp(data[0]);
    return (
        <div className='division col-2' onClick={() => onSelected(data, dateStr)}>
            {dateStr}
        </div>
    );
}

function NearApps({
    placeId,
    jobId,
    services,
    selectedApps,
    show,
    onSelected,
    onSelectedMore,
    onMaximumFetched,
}) {
    const [nearAppointments, setNearAppointments] = useState(undefined);
    const [isFetching, setIsFetching] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        let servicesIds = [];
        services.map((v) => servicesIds.push(v.id));
        fetchNearAppointments(placeId, jobId, servicesIds, selectedApps)
            .then((res) => {
                setNearAppointments(res.content.near_apps);

                if (Number.isInteger(res.content.remaining_apps_count)) {
                    onMaximumFetched(res.content.remaining_apps_count);
                }

                setIsFetching(false);
            })
            .catch(() => {
                setError(true);
                setIsFetching(false);
            });
    }, [services]);

    if (!show) {
        return null;
    }

    if (isFetching || error) {
        return <SkeletonNearApps />;
    }

    return (
        <div>
            <div className='fw-bold text-light labelNearApps text-start mx-auto ps-3'>
                TURNOS LIBRES EN LOS PRÓXIMOS DÍAS
            </div>
            <div className='nearAppsContainer mx-auto mb-4'>
                {Object.keys(nearAppointments).map((date, index) => {
                    return (
                        <DayNearApp
                            today={index === 0}
                            key={date}
                            date={date}
                            apps={nearAppointments[date]}
                            onSelected={onSelected}
                            onSelectedMore={onSelectedMore}
                        />
                    );
                })}
            </div>
        </div>
    );
}

const days = ['DOM', 'LUN', 'MAR', 'MIÉ', 'JUE', 'VIE', 'SÁB'];
function DayNearApp({ today, date, apps, onSelected, onSelectedMore }) {
    let parsedDate = moment(date, 'MM/DD/YYYY').toDate();

    return (
        <div className='text-start p-2 text-light '>
            <div className='h6 dateName ms-2'>
                {today ? <>HOY</> : <>{`${days[parsedDate.getDay()]} ${parsedDate.getDate()}`}</>}
            </div>
            <div className='dateNearAppContainer justify-content-center d-flex'>
                {apps.remaining_apps_in_day > 0 && apps.divisions && apps.divisions.length > 0 && (
                    <>
                        {apps.divisions.map((app, index) => {
                            return (
                                <NearApp
                                    key={index}
                                    app={app}
                                    date={parsedDate}
                                    onSelected={onSelected}
                                />
                            );
                        })}
                        <button
                            className='d-inline-block mx-2 p-2 text-white moreNearApp'
                            onClick={() => onSelectedMore(parsedDate)}
                        >
                            ver más
                        </button>
                    </>
                )}
                {(!apps.divisions ||
                    apps.divisions.length === 0) &&
                    apps.remaining_apps_in_day > 0 && (
                        <div className='text-light ms-2 noApps my-auto'>Sin turnos este día</div>
                    )}
                {apps.remaining_apps_in_day <= 0 && (
                    <div className='noApps text-danger my-auto justify-content-center d-flex mx-2 text-center'>
                        Alcanzaste el límite de turnos para este servicio en este día.
                    </div>
                )}
            </div>
        </div>
    );
}

function NearApp({ date, app, onSelected }) {
    let parsedDate = stringFormatApp(app[0]);

    const select = () => {
        setTimeout(() => {
            onSelected(date, app, parsedDate);
        }, 50);
    };

    return (
        <button className='nearApp d-inline-block mx-2 p-2 fw-normal' onClick={select}>
            {parsedDate}
        </button>
    );
}

function SkeletonNearApps() {
    let currDate = new Date();
    let currDayIndex = currDate.getDay();
    return (
        <div>
            <div className='fw-bold text-light labelNearApps text-start mx-auto ps-3'>
                TURNOS LIBRES EN LOS PRÓXIMOS DÍAS
            </div>
            <div className='nearAppsContainer mx-auto mb-4'>
                {[...Array(3)].map((e, i) => (
                    <div className='text-start p-2 text-light ' key={i}>
                        <div className='h6 dateName ms-2'>
                            {i === 0 ? (
                                <>HOY</>
                            ) : (
                                <>{`${days[(currDayIndex + i) % 7]} ${currDate.getDate() + i}`}</>
                            )}
                        </div>
                        <div className='dateNearAppContainer justify-content-center d-flex'>
                            {[...Array(3)].map((e, i) => (
                                <div
                                    className='nearAppSkeleton d-inline-block mx-2 fw-normal'
                                    key={i}
                                >
                                    <Skeleton />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
