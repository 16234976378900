import React, { useState } from 'react';

export const AuthContext = React.createContext({
    googleUser: undefined,
    setGoogleUser: () => {},
});

window.globalGoogleUser = undefined;

export const AuthContextProvider = (props) => {
    const setGoogleUser = (user) => {
        setState({ ...state, googleUser: user });
    };

    const initState = {
        googleUser: undefined,
        setGoogleUser: setGoogleUser,
    };

    const [state, setState] = useState(initState);

    return <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>;
};
