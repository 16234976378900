import React from "react"
import Day from "./Day"
import "./Week.styles.scss"

export default class Week extends React.PureComponent {
  onClickDay(day) {
    const { current, date } = day
    if (!current) {
      if (date.getMonth() < this.props.current.month) {
        this.props.onClickPrev()
      } else {
        this.props.onClickNext()
      }
    } else {
      if (this.props.propsMyCommerce) {
        if (
          this.dayInactiveAndHasCite(day.date) ||
          (this.isActive(day.date) && this.citesInDay(day.date))
        )
          //Si el empleado atiende en este dia... Se puede clickear
          this.props.onClickDay(day.date)
      } else {
        this.props.onClickDay(day.date)
      }
    }
  }

  dayInactiveAndHasCite(dateDayOfWeek) {
    return (
      !this.isActive(dateDayOfWeek) && this.citesInDay(dateDayOfWeek).length
    )
  }

  isActive(dateDayOfWeek) {
    //Esta funcion decide si el dia se encuentra activo/inactivo
    const openDaysEmployed = this.props.propsMyCommerce.openDays
    const dayOfWeek = String(dateDayOfWeek).split(" ")[0]

    return openDaysEmployed.includes(dayOfWeek)
  }

  citesInDay(dateDayOfWeek) {
    const datesCites = this.props.propsMyCommerce.datesCites
    const dayOfWeek = String(dateDayOfWeek.toISOString().slice(0, 10))

    if (datesCites.includes(dayOfWeek)) {
      const dataCite = this.props.propsMyCommerce.cites.filter((cite) => {
        return cite.date == dayOfWeek
      })

      return dataCite
    } else return []
  }

  matchDaysOff(dateDayOfWeek) {
    const daysOff = this.props.propsMyCommerce.daysOff
    const dayOfWeek = String(dateDayOfWeek.toISOString().slice(0, 10))

    return daysOff.includes(dayOfWeek)
  }

  returnWeeks(week) {
    if (Array.isArray(week) && week.length) {
      if (this.props.propsMyCommerce) {
        return week.map((day, i) => {
          return (
            <Day
              selectedDate={this.props.current}
              key={i}
              date={day.date}
              current={day.current}
              events={day.events}
              // onClickEvent={this.props.onClickEvent}
              onClickDay={() => this.onClickDay(day)}
              //New porps
              propsMyCommerce={this.props.propsMyCommerce}
              isActive={this.isActive(day.date)}
              dataCite={this.citesInDay(day.date)}
              isDayOff={this.matchDaysOff(day.date)}
            />
          )
        })
      }

      return week.map((day, i) => {
        return (
          <Day
            selectedDate={this.props.current}
            key={i}
            date={day.date}
            current={day.current}
            events={day.events}
            // onClickEvent={this.props.onClickEvent}
            onClickDay={() => this.onClickDay(day)}

            // //New porps
            // isActive={ this.isActive( day.date ) }
            // dataCite={ this.citesInDay( day.date )}
          />
        )
      })
    }
  }

  render() {
    return <div className={"weekRow"}>{this.returnWeeks(this.props.week)}</div>
  }
}
