import { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { bookAppointments } from "../../requests/requests"

import "./app-preview.scss"

export default function AppointmentPreview(props) {
  const [recaptchaValue, setRecaptchaValue] = useState(
    process.env.REACT_APP_DEBUG ? "" : undefined
  )
  const [isBooking, setIsBooking] = useState(false)
  const [error, setError] = useState(false)

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value)
  }

  const onConfirm = () => {
    setIsBooking(true)
    bookAppointments(props.placeId, props.jobId, props.apps, recaptchaValue)
      .then(() => {
        setIsBooking(false)
        setError(false)
        props.onBooked()
      })
      .catch(() => {
        setIsBooking(false)
        setError(true)
        props.onError()
      })
  }

  const formatDate = (date) => {
    return date.replace("-", "/")
  }

  // const shouldShowRecaptcha = () => {
  //     return !recaptchaValue && !process.env.REACT_APP_DEBUG;
  // };

  const shouldShowRecaptcha = () => {
    return !recaptchaValue
  }

  return (
    <div className="pt-3 preview">
      <div className="containerPreview mx-auto">
        <div className="previewServices row p-3 mx-auto">
          <i className="col-1 text-light fas fa-suitcase my-auto"></i>
          <div className="col-5 my-auto text-start text-light">SERVICIOS</div>
          <div className="col text-start">
            {props.services.map((service) => {
              return <div key={service.id}>{service.name}</div>
            })}
          </div>
        </div>
        <div className="previewServices row p-3 mx-auto">
          <i className="col-1 text-light fas fa-user my-auto"></i>
          <div className="col-5 my-auto text-start text-light">EMPLEADO</div>
          <div className="col employee-preview row">
            <div className="col-3 text-start">
              <img
                src={props.employee.job.service_provider.profile_pic}
                alt="empleado"
              ></img>
            </div>
            <div className="col my-auto text-start">
              {props.employee.job.service_provider.given_name}
            </div>
          </div>
        </div>
        <div className="previewServices row p-3 mx-auto">
          <i className="col-1 text-light fas fa-clock my-auto"></i>
          <div className="col-5 my-auto text-start text-light">HORARIOS</div>
          <div className="col text-start">
            {props.apps.map((app) => {
              return (
                <div key={app.local_time}>{formatDate(app.local_time)}</div>
              )
            })}
          </div>
        </div>
      </div>
      {shouldShowRecaptcha() && (
        <div className="m-auto container-sm recaptchaContainer mt-3">
          <div className="recaptcha mx-auto">
            <ReCAPTCHA
              theme="dark"
              sitekey="6LfMOzUaAAAAALklb4dcBXCfKrmHiEZQZKuieaN-"
              onChange={onRecaptchaChange}
            />
          </div>
          <div id="loadingCaptcha">
            <span>Cargando recaptcha</span>
            <span
              className="spinner-border spinner-border-sm ms-2"
              role="status"
              aria-hidden="true"
            />
          </div>
        </div>
      )}
      {!shouldShowRecaptcha() && (
        <>
          {!isBooking ? (
            <button
              id="btnConfirmAppointments"
              className="mt-3"
              onClick={onConfirm}
            >
              CONFIRMAR
            </button>
          ) : (
            <button id="btnConfirmAppointments" className="mt-3 booking">
              <span>RESERVANDO</span>
              <ProgressBar />
            </button>
          )}
        </>
      )}
    </div>
  )
}

function ProgressBar() {
  return (
    <div className="progress">
      <div className="progress-bar"></div>
    </div>
  )
}
