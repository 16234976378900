import { useEffect, useState } from "react"
import "./cite_class_mode_detail.scss"

import { AiOutlineInfoCircle, AiOutlineDelete } from "react-icons/ai"

import {
  Container,
  Row,
  Col,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Popover,
} from "react-bootstrap"

import { GrUser, GrUserExpert } from "react-icons/gr"

import objectsInArray from "../../utils/objectsInArray"
import capitalize from "../../utils/capitalizeFirstLetter"
import dateFormat from "dateformat"

import { fetchGetLabels, fetchDeleteCite } from "../../../../requests/requests"
import {
  filterLabelById,
  filterLabel,
  getColorDefault,
  statusCite,
} from "../../utils/statusCite"

import moment from "moment"

export default function CiteClassModeDetail({ data }) {
  const { appointments, service_instance } = data.cite
  const clientsInCiteClass = objectsInArray(appointments)
  const appointment = objectsInArray(appointments)[0].appointment
  const services_appointemnt = objectsInArray(
    objectsInArray(appointments)[0].services_appointment
  )[0]?.service_instance

  const appointmentsAvailable =
    services_appointemnt.concurrency - clientsInCiteClass.length
  const [label, setLabel] = useState("red")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    if (data.daySelected) {
      fetchGetLabels(data.placeID, data.jobID).then((res) => {
        if (!appointment.label) {
          const label = getColorDefault(appointment.timestamp_start)
          setLabel(label)
        } else {
          const label = filterLabelById(appointment.label.id, res.content)
          setLabel(label)
        }

        setIsLoading(false)
      })
    }
  }, [data.daySelected])

  const deleteCite = async (value) => {
    const messageAll = `¿Está seguro que quiere cancelar todos los turnos de esta clase?`
    const messageAppointment =
      "¿Está seguro que desea cancelar el turno de este cliente?"
    const message = value != "all" ? messageAppointment : messageAll

    if (window.confirm(message)) {
      if (value != "all") {
        await fetchDeleteCite(value)
      } else {
        for (let client of clientsInCiteClass) {
          await fetchDeleteCite(client.appointment.id)
        }
      }

      data.setReload(true)
      data.setActionDay(data.daySelected)
    }
  }

  /**** RENDERS */

  const textName = () => {
    const fullName = `${capitalize(appointment.client.given_name)} ${capitalize(
      appointment.client.family_name
    )}`
    if (fullName.length >= 20)
      return <marquee scrolldelay={200}>{fullName}</marquee>
    else return <>{fullName}</>
  }

  const textService = () => {
    if (services_appointemnt.service.name.length >= 20)
      return (
        <marquee scrolldelay={200}>{services_appointemnt.service.name}</marquee>
      )
    else return <>{services_appointemnt.service.name}</>
  }
  const displayHour = () => {
    let fromDate = new Date(appointment.timestamp_start)
    fromDate.setHours(fromDate.getHours() - fromDate.getTimezoneOffset() / 60)

    function formatDate(dateStr) {
      let date = new Date(Date.parse(dateStr))
      date.setHours(date.getHours() + date.getTimezoneOffset() / 60)
      let hours = date.getHours()
      let minutes = date.getMinutes()

      if (hours < 10) {
        hours = "0" + hours
      }
      if (minutes < 10) {
        minutes = "0" + minutes
      }

      return `${hours}:${minutes}`
    }

    function formatStartEndDates(from, to) {
      return `${formatDate(from)}`
    }

    return formatStartEndDates(fromDate)
  }

  const popoverUser = (user) => {
    const labelColor =
      user.appointment.label == null ? "#ff8672" : user.appointment.label.color
    const labelName = !statusCite(user.appointment.timestamp_start)
      ? "Realizado"
      : user.appointment.label == null
      ? "A Realizar"
      : user.appointment.label.name
    return (
      <Popover id="popover-basic">
        <Popover.Header as="h3">
          <div className="d-flex align-items-center gap-2">
            <span
              className="circle-info-white d-inline"
              style={{ backgroundColor: labelColor }}
            ></span>
            <div className="text-white fs-7 d-flex gap-3 align-items-center ">
              <span style={{ color: labelColor }}>{labelName}</span>
              {statusCite(user.appointment.timestamp_start) && (
                <span className="text-dark">
                  <AiOutlineDelete
                    className="cursor-pointer"
                    size={20}
                    onClick={() => deleteCite(user.appointment.id)}
                  />
                </span>
              )}
            </div>
          </div>
        </Popover.Header>
        <Popover.Body>
          {`${capitalize(user.appointment.client.given_name)} ${capitalize(
            user.appointment.client.family_name
          )}`}
        </Popover.Body>
      </Popover>
    )
  }

  const clientsInClass = clientsInCiteClass.map((client, key) => (
    <span
      key={key}
      className="p-1 bg-white general-border-radius cursor-pointer"
    >
      <OverlayTrigger
        trigger={["click"]}
        key="right"
        placement={key > 4 ? "left" : "right"}
        overlay={popoverUser(client)}
      >
        <span>
          <GrUserExpert size={20} />
        </span>
      </OverlayTrigger>
    </span>
  ))

  const citesAvailable = []

  for (let i = 0; i < appointmentsAvailable; i++) {
    citesAvailable.push(
      <span key={i} className="available p-1 general-border-radius">
        <OverlayTrigger
          key="right"
          placement="bottom"
          overlay={<Tooltip>Lugar disponible</Tooltip>}
        >
          <span>
            <GrUser size={20} />
          </span>
        </OverlayTrigger>
      </span>
    )
  }

  if (isLoading) {
    return (
      <Container className="px-2 rounded overflow-hidden text-center text-dark">
        <Spinner size="sm" animation="border" variant="white" />
      </Container>
    )
  }
  return (
    <Container className="p-0 general-border-radius overflow-hidden text-dark">
      <Row
        className="fs-7 justify-content-between rounded-top px-2"
        style={{ backgroundColor: "#ff8672" }}
      >
        <Col xs={6} className="text-start">
          <span>{textService()}</span>
        </Col>
        <Col xs="auto" className="d-flex gap-2">
          <span className="text-white">
            Clase: {clientsInCiteClass.length} de{" "}
            {services_appointemnt.concurrency}
          </span>
          {displayHour()}
        </Col>
      </Row>
      <Row className="bg-light justify-content-between p-2 rounded-bottom">
        <Col>
          <div className="d-flex justify-items-start align-items-center gap-2">
            <span className="fw-bold flex-fill d-flex flex-nowrap gap-3 overflow-auto py-1">
              {clientsInClass}
              {citesAvailable}
            </span>
            <div className="d-flex gap-3 text-end align-items-center">
              {/* <OverlayTrigger key="left" placement="left" overlay={popover}>
                <span>
                  <AiOutlineInfoCircle size={20} />
                </span>
              </OverlayTrigger> */}
              {statusCite(appointment.timestamp_start) && (
                //Si el turno es futuro
                <AiOutlineDelete
                  size={20}
                  onClick={() => deleteCite("all")}
                  className="cursor-pointer"
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )

  // return (
  //   <Container className="bg-light px-2  rounded overflow-hidden text-center text-dark">
  //     <Row>
  //       <Col sm="auto" style={{backgroundColor: label.color}} className=" text-white d-flex align-items-center ">
  //         <span className="fw-bold">{formatTime( services_appointemnt.start_time )}</span>
  //       </Col>
  //       <Col className="text-truncate border-2 my-1" style={{borderRight: `1px solid ${label.color}`}}>
  //         <span className="fw-bold ">{`${capitalize( appointment.client.given_name )} ${capitalize( appointment.client.family_name )}`}</span>
  //       </Col>
  //       <Col className="text-truncate border-2 my-1" style={{borderRight: `1px solid ${label.color}`}}>
  //         <span className="fw-bold ">{services_appointemnt.service.name}</span>
  //       </Col>
  //       <Col className="d-flex justify-content-center gap-2 d-flex align-items-center">
  //         <span className="circle-info-primary-sm" style={{backgroundColor: label.color}}></span>
  //         <span className="fw-bold ">
  //           <AiOutlineInfoCircle/>
  //         </span>
  //         <span className="fw-bold ">
  //           <AiOutlineDelete/>
  //         </span>
  //       </Col>
  //     </Row>
  //   </Container>
  // );
}
