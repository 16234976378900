import Lottie from "lottie-react"

import error from "../../../lottie/error.json"
import check from "../../../lottie/check.json"
import { BiErrorCircle } from "react-icons/bi"
export const success = (message) => {
  return (
    <div className="d-flex justify-content-center gap-2 flex-column align-items-center">
      <div className="lottie">
        <Lottie animationData={check} loop={false} autoPlay={true} />
      </div>

      <div className="h5 mt-3">{message}</div>
    </div>
  )
}

export const ops = (message) => {
  if (message === "Appointments are no longer available")
    return (
      <div className="text-red d-flex flex-column justify-content-center text-center">
        <div className="text-red">
          <BiErrorCircle size={80} fill="#ff0000" />
        </div>
        <p className="my-3">Ops! al parecer no hay turnos disponibles.</p>
        <p className="text-light">Vuelve a intentarlo.</p>
      </div>
    )

  return (
    <div className="text-red d-flex flex-column justify-content-center text-center">
      <div>
        <BiErrorCircle size={80} />
      </div>
      <p>{message}</p>
      <p>Vuelve a intentarlo.</p>
    </div>
  )
}
