import { useContext, useState } from "react"

import "./login.scss"
import { AuthContext } from "./AuthContextProvider"
import { PlaceContext } from "../my_commerce/PlaceContextProvider"
import { googleLogout } from "@react-oauth/google"

export function useLogOut() {
  const authContext = useContext(AuthContext)
  authContext.setGoogleUser(undefined)

  googleLogout()
}

export function useIsLoggedIn() {
  const authContext = useContext(AuthContext)
  const isLoggedIn = authContext.googleUser

  return isLoggedIn !== undefined
}

export function useIsNewUser() {
  const authContext = useContext(AuthContext)

  return authContext.googleUser && authContext.googleUser.is_new_user
}
