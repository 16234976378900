import { LoadingContext } from "react-router-loading"
import { useContext, useState } from "react"
import { fetchDeleteUser } from "../../requests/requests"
import { AuthContext } from "../auth/AuthContextProvider"
import { googleLogout } from "@react-oauth/google"

function DeleteAccount() {
  const authContext = useContext(AuthContext)
  const [value, setValue] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const loadingContext = useContext(LoadingContext)
  loadingContext.done()

  const deleteAccount = async () => {
    setLoading(true)
    try {
      await fetchDeleteUser(authContext.googleUser.user_id)
      authContext.setGoogleUser(undefined)
      localStorage.removeItem("token")
      googleLogout()
      window.location.href = "/"
    } catch (e) {
      setError(e)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div style={{ marginTop: 130 }}>
      <div className="col-12 col-md-6 m-auto px-3">
        <h1 className="text-danger">Importante</h1>
        <br />
        <span className="fw-bold h5">
          Antes de eliminar su cuenta, considere lo siguiente:
        </span>
        <p>
          Eliminar su cuenta implica la pérdida permanente de todos los datos
          asociados a ella, incluyendo su perfil, historial de actividad y
          cualquier información almacenada en nuestra plataforma. Tenga en
          cuenta que esta acción no se puede deshacer y no se podrá recuperar la
          información eliminada. Antes de proceder con la eliminación de su
          cuenta, le recomendamos que realice copias de seguridad de cualquier
          información importante que desee conservar. Además, al eliminar su
          cuenta, perderá acceso a todas las funciones y servicios ofrecidos por
          nuestra plataforma. Si está seguro de que desea proceder con esta
          acción, por favor, confirme su decisión para continuar con el proceso
          de eliminación de la cuenta.
        </p>
        <br />
        <br />

        {loading ? (
          <i>
            Eliminando cuenta
            <div class="spinner-border spinner-border-sm ms-2" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </i>
        ) : (
          <>
            <div class="d-flex flex-column m-auto mb-3">
              <label htmlFor="delete">
                Escriba
                <span className="text-primary"> Confirmar eliminación</span>
              </label>
              <input
                type="text"
                class="form-control"
                id="delete"
                onInput={(e) => setValue(e.target.value)}
              />
            </div>
            {value == "Confirmar eliminación" && (
              <button
                className="btn btn-outline-primary mb-3"
                onClick={deleteAccount}
              >
                Eliminar
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DeleteAccount
