import { useEffect, useState } from "react"
import "./cite_detail.scss"

import { AiOutlineInfoCircle, AiOutlineDelete } from "react-icons/ai"

import {
  Container,
  Row,
  Col,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Popover,
} from "react-bootstrap"

import objectsInArray from "../../utils/objectsInArray"
import capitalize from "../../utils/capitalizeFirstLetter"
import dateFormat from "dateformat"

import { fetchGetLabels, fetchDeleteCite } from "../../../../requests/requests"
import {
  filterLabelById,
  filterLabel,
  getColorDefault,
  statusCite,
} from "../../utils/statusCite"

import moment from "moment"

export default function CiteDetail({ data }) {
  const { appointment, services_appointment } = data.cite
  const service_info = objectsInArray(services_appointment)[0].service_instance
  const [label, setLabel] = useState("red")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    if (data.daySelected) {
      fetchGetLabels(data.placeID, data.jobID).then((res) => {
        if (!appointment.label) {
          const label = getColorDefault(appointment.timestamp_start)
          setLabel(label)
        } else {
          const label = filterLabelById(appointment.label.id, res.content)
          setLabel(label)
        }

        setIsLoading(false)
      })
    }
  }, [data.daySelected])

  const deleteCite = () => {
    if (window.confirm("Esta seguro que quiere cancelar este turno?")) {
      fetchDeleteCite(appointment.id).then((res) => {
        data.setReload(true)
        data.setActionDay(data.daySelected)
      })
    }
  }

  /**** RENDERS */

  const textName = () => {
    const fullName = `${capitalize(appointment.client.given_name)} ${capitalize(
      appointment.client.family_name
    )}`
    if (fullName.length >= 20)
      return <marquee scrolldelay={200}>{fullName}</marquee>
    else return <>{fullName}</>
  }

  const textService = () => {
    if (service_info.service.name.length >= 20)
      return <marquee SCROLLDELAY={200}>{service_info.service.name}</marquee>
    else return <>{service_info.service.name}</>
  }
  const displayHour = () => {
    let fromDate = new Date(appointment.timestamp_start)
    fromDate.setHours(fromDate.getHours() - fromDate.getTimezoneOffset() / 60)

    function formatDate(dateStr) {
      let date = new Date(Date.parse(dateStr))
      date.setHours(date.getHours() + date.getTimezoneOffset() / 60)
      let hours = date.getHours()
      let minutes = date.getMinutes()

      if (hours < 10) {
        hours = "0" + hours
      }
      if (minutes < 10) {
        minutes = "0" + minutes
      }

      return `${hours}:${minutes}`
    }

    function formatStartEndDates(from, to) {
      return `${formatDate(from)}`
    }

    return formatStartEndDates(fromDate)
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">
        <div className="d-flex align-items-center gap-2">
          <span
            className="circle-info-white d-inline"
            style={{ backgroundColor: label.color }}
          ></span>
          <span className="text-white fs-7">
            <span style={{ color: label.color }}>{label.name}</span>
          </span>
        </div>
      </Popover.Header>
      <Popover.Body>
        {!appointment.observation ? (
          <>No hay observaciones.</>
        ) : (
          appointment.observation
        )}
      </Popover.Body>
    </Popover>
  )

  if (isLoading) {
    return (
      <Container className="px-2 rounded overflow-hidden text-center text-dark">
        <Spinner size="sm" animation="border" variant="white" />
      </Container>
    )
  }
  return (
    <Container className="p-0 general-border-radius overflow-hidden text-dark">
      <Row
        className="fs-7 justify-content-between rounded-top px-2"
        style={{ backgroundColor: label.color }}
      >
        <Col xs={9} className="text-start">
          <span>{textService()}</span>
        </Col>
        <Col xs="auto">{displayHour()}</Col>
      </Row>
      <Row className="bg-light justify-content-between p-2 rounded-bottom">
        <Col>
          <div className="d-flex align-items-center">
            <span className="fw-bold flex-fill text-start">{textName()}</span>
            <div className="d-flex gap-3 text-end align-items-center">
              <OverlayTrigger key="left" placement="left" overlay={popover}>
                <span>
                  <AiOutlineInfoCircle size={20} />
                </span>
              </OverlayTrigger>
              {statusCite(appointment.timestamp_start) && (
                //Si el turno es futuro
                <AiOutlineDelete
                  size={20}
                  onClick={deleteCite}
                  className="cursor-pointer"
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
