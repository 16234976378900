import { useContext, useEffect, useState } from "react"
import "./my_shop_calendar.scss"
import { BsCalendarX } from "react-icons/bs"
import {
  AiOutlinePlusCircle,
  AiOutlineWarning,
  AiOutlineReload,
} from "react-icons/ai"
import { HiCursorClick } from "react-icons/hi"

//Boostrap
import { Row, Col, Spinner } from "react-bootstrap"
import Button from "react-bootstrap/Button"

//Components
import Calendar from "../../../calendar/Calendar/index"
import CitesTable from "../../child_components/CitesTable/cites_table"
import CiteDetail from "../../child_components/CiteDetail/cite_detail"
import CiteClassModeDetail from "../../child_components/CiteClassMode/cite_class_mode"
import ChangeEmployed from "../../child_components/ChangeEmployed/change_employed"
import LayoutParent from "../../layouts/parent"
import DaySelectLabel from "../../child_components/DaySelectLabel/day_select_label"
import ButtonModal from "../../child_components/ButtonModal/button_modal"
import ModalAddCite from "../../child_components/ModalAddCite/modal_addcite"

import objectsInArray from "../../utils/objectsInArray"
import getListdaysOpen, {
  convertIntDaysInStr,
} from "../../utils/daysOpenEmployed"
//Requests
import { PlaceContext } from "../../PlaceContextProvider"
import {
  fetchGetServices,
  fetchGetEmployedByService,
  fetchGetCiteByDate,
  fetchGetDaySchedules,
  fetchGetAllCitesCalendar,
} from "../../../../requests/requests"
import { GetAllPlaceClients, GetEmployeds } from "../../../utils/swr_callbacks"
import { getDatesOfMonth } from "../../utils/getDatesOfMonth"
import { getEmployedByService } from "../../utils/request"
import { Breakpoint } from "react-socks"
import moment from "moment"
import "moment/locale/es"
import { AuthContext } from "../../../auth/AuthContextProvider"
moment.locale("es")

export default function MyShopCalendar() {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCite, setIsLoadingCite] = useState(false)
  const [isLoadingCites, setIsLoadingCites] = useState(false)
  const { placeSelected } = useContext(PlaceContext)
  const authContext = useContext(AuthContext)
  const [reload, setReload] = useState(false)
  let {
    employeds,
    isLoading: isLoadingEmployeds,
    isErrorEmployeds,
  } = GetEmployeds(placeSelected.id)

  const [employedSelected, setEmployedSelected] = useState(undefined)
  const [openDays, setOpenDays] = useState([])
  const [datesCites, setDatesCites] = useState([])
  const [cites, setCites] = useState([])
  const [daySelected, setDaySelected] = useState("")
  const [citeSelected, setCiteSelected] = useState([])
  const [citeClassMode, setCiteClassMode] = useState([])
  const [infoEmployedCalendar, setInfoEmployedCalendar] = useState(undefined)
  const [daysOff, setDaysOff] = useState([])
  const [jobID, setJobID] = useState(undefined)
  const [hasServices, setHasServices] = useState(true)
  const [openDaysCommerce, setOpenDaysCommerce] = useState([])
  const [modalIsOpen, setIsOpen] = useState(false)
  const [actionDay, setActionDay] = useState(undefined)
  const [employedNoServices, setEmployedNoServices] = useState(false)
  const [placeEmployeds, setPlaceEmployeds] = useState([])

  let days = []

  useEffect(() => {
    //Change commerce
    setEmployedSelected(undefined)
    setDaySelected("")
    setInfoEmployedCalendar(undefined)
    setCiteSelected([])
    setDatesCites([])
    setCites([])
    setIsLoading(true)
    setReload(false)
  }, [placeSelected])

  useEffect(() => {
    let cancel = false
    if (!isLoadingEmployeds && employeds.length) {
      if (!employedSelected) {
        if (cancel) return
        const userSession = employeds.find(
          (employee) => employee.id == authContext.googleUser.user_id
        )

        setEmployedSelected(userSession)

        const isOwner = placeSelected.service_provider.id == userSession?.id
        if (!isOwner) {
          setPlaceEmployeds([userSession])
        } else {
          setPlaceEmployeds(employeds)
        }
      }
    }

    return () => {
      cancel = true
    }
  }, [isLoadingEmployeds])

  //Get Services
  useEffect(() => {
    start()
    async function start() {
      setIsLoadingCites(true)
      setDaySelected(undefined)
      if (reload) {
        setCiteSelected([])
        setDaySelected(undefined)
      }
      if (employedSelected) {
        const res = await getEmployedByService(
          placeSelected.id,
          employedSelected.id
        )
        setHasServices(res.servicesEmploye.length > 0)
        if (!res.serviceProvider) {
          setEmployedNoServices(true)
          setIsLoadingCites(false)
          return
        }

        setOpenDaysCommerce(res.openDays)
        setJobID(res.serviceProvider.job.id)
        setInfoEmployedCalendar(res.serviceProvider)
        setOpenDays(convertIntDaysInStr(res.serviceProvider.days))
        setDaysOff(res.serviceProvider.day_off_list.map((item) => item.date))
        fetchGetAllCitesCalendar(res.serviceProvider.job.id).then((res) => {
          setDatesCites(res.data.dates)
          setCites(res.data.cites)
          setIsLoadingCites(false)
        })

        if (actionDay) {
          setDaySelected(actionDay)
        } else {
          setReload(false)
        }
      }
      setCites([])
      setEmployedNoServices(false)
    }
  }, [employedSelected, reload])

  useEffect(() => {
    setIsLoadingCite(true)

    if (daySelected) {
      fetchGetCiteByDate(infoEmployedCalendar.job.id, daySelected).then(
        (res) => {
          const appointments = objectsInArray(res.appointments)
          const classAppointments = objectsInArray(res.class_appointments)
          setCiteSelected(appointments)
          setCiteClassMode(classAppointments)

          setIsLoadingCite(false)
          setActionDay(undefined)

          setReload(false)
        }
      )
    }
  }, [daySelected])

  const handleReload = () => {
    setReload(true)
  }

  const dataChangeEmployed = {
    placeEmployeds,
    employedSelected,
    setEmployedSelected,
  }

  const dataCalendarMyCommerce = {
    datesCites,
    cites,
    openDays: openDaysCommerce,
    setDaySelected,
    actionDay,
    setActionDay,
    daysOff,
  }

  const dataModalAddCite = {
    employeds,
    employedSelected,
    setEmployedSelected,
    setReload,
    setIsOpen,
    setActionDay,
  }

  const dataCiteDetail = {
    placeID: placeSelected.id,
    jobID,
    daySelected,
    setReload,
    setActionDay,
  }

  /****** RENDERS */
  const citeDetailItems = citeSelected.map((cite, key) => (
    <CiteDetail key={key} data={{ ...dataCiteDetail, cite }}></CiteDetail>
  ))

  const citeClassModeDetailItems = citeClassMode.map((cite, key) => (
    <CiteClassModeDetail
      key={key}
      data={{ ...dataCiteDetail, cite }}
    ></CiteClassModeDetail>
  ))
  const labelAddCite = (
    <>
      <AiOutlinePlusCircle size={20} /> Agendar turno para HOY
    </>
  )

  return (
    <LayoutParent>
      <Col md={12} lg={6} className="bg-secondary py-3">
        {!isLoadingEmployeds ? (
          <div className="d-flex gap-3">
            <div className="flex-fill">
              {placeEmployeds.length ? (
                <ChangeEmployed imgSize="md" data={dataChangeEmployed} />
              ) : (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </div>
            <Button
              variant="outline-primary"
              className="general-border-radius"
              onClick={handleReload}
            >
              <AiOutlineReload />
            </Button>
            <Breakpoint customQuery="(max-width: 999px)">
              <ButtonModal
                label={<AiOutlinePlusCircle size={20} />}
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                className="flex-fill p-2 fw-bold text-white general-border-radius"
                variant="primary"
              >
                <ModalAddCite data={dataModalAddCite} />
              </ButtonModal>
            </Breakpoint>
          </div>
        ) : (
          <Spinner animation="border" variant="primary" className="m-5" />
        )}

        {!hasServices && !isLoadingCites ? (
          <div className="py-4 d-flex align-items-center justify-content-center">
            <div className="d-flex flex-column text-center text-white text-opacity-25 gap-3">
              <span>
                <AiOutlineWarning size="60" />
              </span>
              <h3>!{employedSelected.given_name} no registró un servicio!</h3>
              <span>Crea uno desde la aplicación</span>
            </div>
          </div>
        ) : !isLoadingCites ? (
          <Calendar
            color="#ff8672"
            propsMyCommerce={dataCalendarMyCommerce}
            datesCites={datesCites}
            cites={cites}
            daysOpen={openDays}
            events={undefined}
          />
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="primary" className="m-5" />
          </div>
        )}
      </Col>

      {hasServices && (
        <Col className=" col-breakpoint overflow-hidden py-3">
          <div className="d-flex flex-column align-content-between gap-2 full-height ">
            {reload && isLoadingCite ? (
              <div className="full-height d-flex align-items-center justify-content-center">
                <Spinner size="sm" animation="border" variant="white" />
              </div>
            ) : !daySelected ? (
              <div className="full-height d-flex align-items-center gap-3 justify-content-center text-white text-opacity-25">
                <HiCursorClick size={20} />
                Seleccioná un día
              </div>
            ) : isLoadingCite ? (
              <div className="full-height d-flex align-items-center justify-content-center w-100">
                <Spinner size="sm" animation="border" variant="white" />
              </div>
            ) : citeSelected.length > 0 || citeClassMode.length > 0 ? (
              <>
                <DaySelectLabel day={daySelected} />
                <CitesTable className="flex-fill">
                  {citeClassModeDetailItems}
                  {citeDetailItems}
                </CitesTable>
              </>
            ) : (
              <div className="full-height d-flex flex-column align-items-center gap-3 justify-content-center text-white text-opacity-25">
                <div className="text-primary d-flex align-items-center justify-content-center gap-1">
                  <BsCalendarX size={25} /> {moment(daySelected).format("LL")}
                </div>
                <div>No hay turnos este día</div>
              </div>
            )}

            <Breakpoint customQuery="(min-width: 1000px)">
              <div className="d-flex gap-2">
                <ButtonModal
                  label={labelAddCite}
                  modalIsOpen={modalIsOpen}
                  setIsOpen={setIsOpen}
                  className="flex-fill p-2 fw-bold text-white general-border-radius"
                  variant="primary"
                >
                  <ModalAddCite data={dataModalAddCite} />
                </ButtonModal>
              </div>
            </Breakpoint>
          </div>
        </Col>
      )}
    </LayoutParent>
    //
  )
}
