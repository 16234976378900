import React, { useEffect, useState } from 'react';
import { FETCH } from '../../../../requests/http';
import './manage-places.scss';

export default function ManagePlaces() {
    const [places, setPlaces] = useState(undefined);
    const [error, setError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const fetchPlaces = () => {
            return FETCH(`/admin/places`, 'GET');
        };

        setIsFetching(true);
        fetchPlaces()
            .then((res) => {
                setPlaces(res.content);
                setIsFetching(false);
            })
            .catch((err) => {
                setError(true);
                setIsFetching(false);
            });
    }, []);

    if (error) {
        return <div>error</div>;
    }

    return (
        <div className='mt-2' id='manage-places'>
            <div className='page-title'>Lugares</div>

            {!isFetching && places && (
                <table>
                    <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>telefono</th>
                        <th>Slogan</th>
                        <th>Coords</th>
                    </tr>

                    {places.map((place) => {
                        return <SinglePlaceView key={place.id} place={place} />;
                    })}
                </table>
            )}
        </div>
    );
}

function SinglePlaceView({ place }) {
    const style = {
        width: '100%',
        backgroundColor: 'black',
        borderRadius: '10px',
        margin: '10px',
        minWidth: '100px',
        border: '1px solid',
        // height: '300px',
    };

    return (
        <tr>
            <td style={style}>{place.id}</td>
            <td style={style}>{place.businessname}</td>
            <td style={style}>{place.phonenumber}</td>
            <td style={style}>{place.slogan}</td>
            <td style={style}>{place.address}</td>
        </tr>
    );
}
