import { useContext, useState, useEffect, useRef } from "react"
import "./modal_addcite.scss"

import { AiOutlineCalendar, AiOutlineClockCircle } from "react-icons/ai"
import { BsChevronDown } from "react-icons/bs"
import { Form, Row, Col, Container, Button, Dropdown } from "react-bootstrap"
import Calendar from "react-calendar"

import { PlaceContext } from "../../PlaceContextProvider"
import {
  fetchPostCreateCite,
  bookAppointments,
  fetchGetServices,
  fetchGetEmployedByService,
  fetchGetClientsByEmployed,
  fetchAvailableAppointments,
  fetchGetLabels,
  fetchGetServiceInstances,
  fetchGetClientCredits,
} from "../../../../requests/requests"
import { GetAllPlaceClients, GetEmployeds } from "../../../utils/swr_callbacks"
import objectsInArray, {
  objectsInArrayServices,
} from "../../utils/objectsInArray"

// import Calendar from '../../../calendar/Calendar';
import {
  formatDate,
  toIsoStringDate,
  stringFormatApp,
} from "../../utils/separateDate"
import capitalize from "../../utils/capitalizeFirstLetter"
import dateFormat from "dateformat"
import TooltipAddClient from "../TooltipAddClient/tooltip_add_client"

import moment from "moment"
import "moment/locale/es"
import { AuthContext } from "../../../auth/AuthContextProvider"
moment.locale("es")

export default function StepOne({ data, dataPreview, className }) {
  const { placeSelected } = useContext(PlaceContext)
  const authContext = useContext(AuthContext)

  const ref = useRef(null)

  const [isLoading, setIsLoading] = useState(false)

  const { employeds: allEmployeds, isLoading: isLoadingEmployeds } =
    GetEmployeds(placeSelected.id)
  const { clients: allClients, isLoading: isLoadingClients } =
    GetAllPlaceClients(placeSelected.id)

  //Services
  const [services, setServices] = useState([])
  const [serviceSelected, setServiceSelected] = useState(undefined)
  const selectService = (e) => {
    if (e.target.value != 0) setServiceSelected(e.target.value)
    else {
      setServiceSelected(undefined)
    }

    setEmployedSelected(undefined)
    setClientSelected(undefined)
    setObjEmployedSelected([])
    setLabelSelected("")
    setDaySelected("")
    setAvailableAppointments([])
    setIsSelected(undefined)
  }
  useEffect(() => {
    fetchGetServices(placeSelected.id).then((res) => {
      if (res.result) {
        const allServices = objectsInArrayServices(res.content.services)
        const isOwner =
          placeSelected.service_provider.id == authContext.googleUser.user_id

        if (isOwner) {
          setServices(allServices)
        } else {
          let servicesEmployed = []

          allServices[0].job_types.forEach((service) => {
            if (service.provider_id == authContext.googleUser.user_id) {
              servicesEmployed.push(service)
            }
          })

          setServices([{ job_types: servicesEmployed }])
        }
      }
    })
  }, [])

  //Employed
  const [objEmployedSelected, setObjEmployedSelected] = useState([])
  const [employedSelected, setEmployedSelected] = useState(undefined)
  const [openDays, setOpenDays] = useState([])
  const [daysOff, setDaysOff] = useState([])
  const [serviceUseCredits, setServiceUseCredits] = useState(false)
  const selectEmployed = (e) => {
    if (e.target.value != 0) {
      setEmployedSelected(e.target.value)
    } else {
      setEmployedSelected(undefined)
      setClientSelected(undefined)
      setLabelSelected("")
      setDaySelected("")
      setAvailableAppointments([])
      setIsSelected(undefined)
    }
  }
  useEffect(() => {
    if (serviceSelected) {
      fetchGetEmployedByService(placeSelected.id, serviceSelected).then(
        (res) => {
          setObjEmployedSelected(objectsInArray(res.content))
          const resOpenDays = objectsInArray(res.content)[0].days
          setOpenDays(resOpenDays)

          const resDaysOff = objectsInArray(res.content)[0].day_off_list.map(
            (obj) => obj.date
          )
          fetchGetServiceInstances(objectsInArray(res.content)[0].job.id).then(
            (res) => {
              const instaceServiceSelected = res.service_instance.find(
                (instance) => instance.service.id == serviceSelected
              )
              setServiceUseCredits(instaceServiceSelected.uses_credits)
            }
          )

          setDaysOff(resDaysOff)
        }
      )
    }
  }, [serviceSelected])

  //Clients
  const [clientSelected, setClientSelected] = useState(undefined)
  const [clientHasCredits, setClientHasHCredits] = useState(false)
  const [loadingClientCredits, setLoadingClientCredits] = useState(false)
  const selectClient = (e) => {
    if (e.target.value != 0) {
      setClientSelected(e.target.value)
      setCustomClient(undefined)
    } else {
      setClientSelected("")
      setLabelSelected(undefined)
      setDaySelected("")
      setAvailableAppointments([])
      setIsSelected(undefined)
    }
  }
  useEffect(() => {
    if (customClient) return

    if (clientSelected) {
      setLoadingClientCredits(true)
      fetchGetClientCredits(placeSelected.id, clientSelected).then((res) => {
        const listCredits = objectsInArray(res.data)

        //Filtramos los creditos que son usables en el servicio seleccionado
        let creditsAccepted = []
        listCredits.forEach((obj) => {
          obj.service_credits.forEach((instance) => {
            if (instance.service.id == serviceSelected) {
              creditsAccepted.push(obj)
            }
          })
        })

        //Filtramos los creditos que estan activos
        const currentDay = new Date().getTime()
        creditsAccepted = creditsAccepted.filter((obj) => {
          const validUntil = new Date(obj.valid_until).getTime()

          return currentDay < validUntil
        })

        //Retorna si el cliente posee creditos o no
        setClientHasHCredits(
          creditsAccepted.length && creditsAccepted[0].current_credits > 0
        )
        setLoadingClientCredits(false)
      })
    }
  }, [clientSelected])

  //Labels
  const [labelSelected, setLabelSelected] = useState("")
  const [objLabel, setObjLabel] = useState({})
  const [labels, setLabels] = useState([])
  const selectLabel = (e) => {
    if (e != 0) {
      let label
      if (e != null) {
        //Si el label no es "A realizar"
        setLabelSelected(e)
        label = labels.filter((label) => label.id == e)[0]
      } else {
        setLabelSelected("null")

        label = {
          name: "A realizar",
          color: "#ff8672",
        }
      }

      setObjLabel(label)
    } else {
      setLabelSelected("")
      setDaySelected("")
      setAvailableAppointments([])
    }
  }

  useEffect(() => {
    if (employedSelected) {
      fetchGetLabels(placeSelected.id, employedSelected).then((res) => {
        setLabels(res.content)
      })
    }
  }, [employedSelected])

  //Credits
  const [switchCredit, setSwitchCredit] = useState(false)

  const discountCredits = (e) => {
    setSwitchCredit(e.target.checked)
  }

  //Calendar
  const [daySelected, setDaySelected] = useState("")
  const [availableAppointments, setAvailableAppointments] = useState([])
  const onDateSelected = (date) => {
    setDaySelected(dateFormat(date, "fullDate"))
  }

  const disableDay = ({ date }) => {
    function isDayOff() {
      if (daysOff) {
        for (let i = 0; i < daysOff.length; i++) {
          let parsedDayOff = moment(daysOff[i].date, "YYYY-MM-DD")
          let parsedDate = moment(date)

          if (parsedDate.isSame(parsedDayOff, "date")) {
            return true
          }
        }
      }

      return false
    }
    return !openDays.includes((date.getDay() + 1) % 8) || isDayOff()
  }

  useEffect(() => {
    setIsLoading(true)
    if (daySelected) {
      setAvailableAppointments([])
      fetchAvailableAppointments(
        placeSelected.id,
        employedSelected,
        [serviceSelected],
        daySelected
      ).then((res) => {
        setAvailableAppointments(res.content.divisions)
        setIsLoading(false)
      })
    }
  }, [daySelected]) //Select day in calendar

  //Select Hour
  const [timeSelected, setTimeSelected] = useState(undefined)
  const [isSelected, setIsSelected] = useState("")
  const selectTime = (date, dateStr) => {
    setIsSelected(dateStr)
    setTimeSelected({
      date: objectsInArray(date)[0],
      dateStr,
    })
  }

  //Set data
  const nextStep = () => {
    //Data for create cite
    function dataCreateCite() {
      let client
      const start = timeSelected.date.start

      if (!customClient)
        client = allClients.filter((client) => clientSelected == client.id)[0]
      else client = customClient
      const body = {
        job_id: employedSelected,
        client,
        appointments: [
          {
            appointment: [
              {
                [serviceSelected]: {
                  start,
                },
              },
            ],
          },
        ],
        notify_client: true,
        discount_credits: switchCredit,
      }

      if (labelSelected != "null") body["label"] = labelSelected

      return body
    }

    function reviewCite() {
      //Search service and return name
      let serviceName = ""
      services.forEach((service) => {
        service.job_types.forEach((job_type) => {
          if (job_type.id == serviceSelected) serviceName = job_type.name
        })
      })
      let client
      if (!customClient)
        client = allClients.filter((client) => clientSelected == client.id)[0]
      else client = customClient

      const day = dateFormat(daySelected, "dd/mm/yyyy")
      const dayFormatSelected = dateFormat(daySelected, "yyyy-mm-dd")

      const preview = {
        serviceName,
        employedSelected: objEmployedSelected[0].job.service_provider,
        employedName: `${capitalize(
          objEmployedSelected[0].job.service_provider.given_name
        )} ${capitalize(
          objEmployedSelected[0].job.service_provider.family_name
        )}`,
        clientName: `${capitalize(client.given_name)} ${
          client.family_name ? capitalize(client.family_name) : ""
        }`,
        daySelected: day,
        dayFormatSelected,
        timeSelected: timeSelected.dateStr,
        discountCredit: switchCredit,
        label: objLabel,
      }

      return preview
    }

    const data = dataCreateCite()
    const preview = reviewCite()
    dataPreview.setStep(2)
    dataPreview.setSendData({
      forSend: data,
      forPreview: preview,
    })
  }

  const [customClient, setCustomClient] = useState(undefined)
  const dataTooltip = {
    setCustomClient,
    setClientSelected,
    allClients,
  }

  /******* UTILS */
  function isFormCompleted() {
    return (
      serviceSelected &&
      employedSelected &&
      clientSelected &&
      labelSelected &&
      daySelected &&
      isSelected
    )
  }

  /***** RENDERS */
  const servicesItems = services.map((item) => {
    return item.job_types.map((job_type) => (
      <option value={job_type.id} key={job_type.id}>
        {job_type.name}
      </option>
    ))
  })

  const employedsItems = objEmployedSelected.map((employed) => (
    <option value={employed.job.id} key={employed.job.id}>
      {`${capitalize(employed.job.service_provider.given_name)} ${capitalize(
        employed.job.service_provider.family_name
      )}`}
    </option>
  ))

  const clientsItems = allClients.map((client) => (
    <option value={client.id} key={client.id}>
      {`${capitalize(client.given_name)} ${capitalize(client.family_name)}`}
    </option>
  ))

  const labelItems = labels.map((label) => (
    <Dropdown.Item
      key={label.id}
      eventKey={label.id}
      className="d-flex align-items-center justify-content-center gap-2"
    >
      <span
        className="circle-info-primary"
        style={{ backgroundColor: label.color }}
      ></span>
      {label.name}
    </Dropdown.Item>
  ))

  const textLabelSelected = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="flex-fill d-flex align-items-center justify-content-center gap-2">
          <span
            className="circle-info-primary"
            style={{ backgroundColor: objLabel.color }}
          ></span>
          {objLabel.name}
        </div>
        <BsChevronDown fill="#000" />
      </div>
    )
  }

  const selectTimeItems = availableAppointments.map((division, index) => {
    const dateStr = stringFormatApp(division[0])
    return (
      <Col xs={3} className="p-1" key={index}>
        <div
          role="button"
          onClick={() => selectTime(division[0], dateStr)}
          value={dateStr}
          className={`px-2 py-1 general-border-radius text-center border border-dark ${
            isSelected == dateStr ? "active-hour" : ""
          }`}
        >
          {dateStr}
        </div>
      </Col>
    )
  })

  return (
    <>
      <Container
        ref={ref}
        className={`text-dark flex-fill d-flex flex-column gap-3 ${className} p-4 text-center`}
      >
        <Row className="justify-content-center">
          <Col sm="7">
            <Form>
              <Form.Group
                as={Row}
                className="mb-1 "
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="3">
                  Servicio
                </Form.Label>
                <Col sm="9">
                  <Form.Select
                    onChange={(e) => selectService(e)}
                    size="sm"
                    aria-label="Default select example"
                    className="general-border-radius select text-center fw-light "
                  >
                    {services.length > 0 ? (
                      <>
                        <option value={0} key={0}>
                          Selecciona un servicio
                        </option>
                        {servicesItems}
                      </>
                    ) : (
                      <>Cargando...</>
                    )}
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-1 "
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="3">
                  Empleado
                </Form.Label>
                <Col sm="9">
                  <Form.Select
                    disabled={!serviceSelected || !objEmployedSelected.length}
                    onChange={(e) => selectEmployed(e)}
                    size="sm"
                    aria-label="Default select example"
                    className="select text-center fw-light general-border-radius "
                  >
                    {objEmployedSelected.length > 0 ? (
                      <>
                        <option value={0} key={0}>
                          Selecciona un empleado
                        </option>
                        {employedsItems}
                      </>
                    ) : (
                      <option value={0} key={0}>
                        Selecciona un empleado
                      </option>
                    )}
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-1 d-flex align-items-center "
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="3">
                  Cliente
                </Form.Label>
                <Col xs="10" sm="8">
                  <Form.Select
                    disabled={!employedSelected}
                    onChange={(e) => selectClient(e)}
                    size="sm"
                    aria-label="Default select example"
                    className="select text-center fw-light general-border-radius  "
                  >
                    {!isLoadingClients ? (
                      employedSelected ? (
                        <>
                          {customClient ? (
                            <option
                              selected
                              value={customClient.given_name}
                              key={0}
                            >
                              {customClient.given_name + "(Nuevo cliente)"}
                            </option>
                          ) : (
                            <option value={0} key={0}>
                              Selecciona un cliente
                            </option>
                          )}

                          {clientsItems}
                        </>
                      ) : (
                        <option value={0} key={0}>
                          Selecciona un cliente
                        </option>
                      )
                    ) : (
                      <option value={0} key={0}>
                        Cargando...
                      </option>
                    )}
                  </Form.Select>
                </Col>
                <Col xs="1">
                  <TooltipAddClient
                    data={dataTooltip}
                    domRef={ref}
                    disabled={!employedSelected}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-1 "
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="3">
                  Tipo de turno
                </Form.Label>
                <Col sm="9">
                  <Dropdown onSelect={(e) => selectLabel(e)}>
                    <Dropdown.Toggle
                      disabled={!clientSelected || !employedSelected}
                      bsPrefix="border"
                      variant="white"
                      className="border border-dark w-100 general-border-radius"
                      size="sm"
                    >
                      {labelSelected
                        ? textLabelSelected()
                        : "Selecciona una etiqueta"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100 text-center">
                      {objEmployedSelected.length ? (
                        <>
                          <Dropdown.Item
                            eventKey={null}
                            className="d-flex align-items-center justify-content-center gap-2"
                          >
                            <span className="circle-info-primary"></span>A
                            realizar
                          </Dropdown.Item>
                          {labelItems}
                        </>
                      ) : (
                        <Dropdown.Item>Selecciona una etiqueta</Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Form.Group>
              {!customClient ? (
                clientSelected ? (
                  !loadingClientCredits ? (
                    <Col
                      sm="9"
                      className="d-flex justify-content-end w-100 gap-2"
                    >
                      {clientHasCredits && serviceUseCredits ? (
                        <>
                          <span className="text-muted">Descontar credito</span>
                          <Form.Check onClick={discountCredits} type="switch" />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  ) : (
                    <span className="d-flex justify-content-end">
                      Cargando...
                    </span>
                  )
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </Form>
          </Col>
        </Row>

        <Row className="justify-content-center">
          {serviceSelected &&
            employedSelected &&
            clientSelected &&
            labelSelected && (
              <Col sm="5" className="my-2">
                <h6 className="fw-bold fs-5">
                  <AiOutlineCalendar /> Seleccioná la fecha
                </h6>

                <Calendar
                  className="w-auto"
                  minDetail="year"
                  locale="es"
                  minDate={new Date()}
                  tileDisabled={disableDay}
                  onChange={onDateSelected}
                  calendarType="Hebrew"
                />
              </Col>
            )}

          {serviceSelected &&
            employedSelected &&
            clientSelected &&
            daySelected && (
              <Col sm="7">
                <Row>
                  <h6 className="fw-bold fs-5">
                    <AiOutlineClockCircle /> Seleccioná el horario
                  </h6>
                </Row>
                <Row>
                  <div className="d-flex flex-column  general-border-radius mb-2">
                    <span className="bg-primary general-border-radius-top text-white fs-5">
                      {moment(daySelected).format("LL")}
                    </span>
                    <span className="bg-light general-border-radius-bottom">
                      {isLoading ? "Buscando..." : "Horarios disponibles"}
                    </span>
                  </div>
                </Row>

                <Row className="px-1">
                  {availableAppointments.length > 0 ? (
                    selectTimeItems
                  ) : (
                    <Col>
                      {isLoading
                        ? "Cargando..."
                        : "No hay turnos disponibles en la fecha seleccionada"}
                    </Col>
                  )}
                </Row>
              </Col>
            )}
        </Row>
      </Container>

      <Button
        disabled={!isFormCompleted()}
        variant={!isFormCompleted() ? "light" : "primary"}
        className={`fs-4 fw-bold ${className} text-white`}
        onClick={() => nextStep()}
      >
        Siguiente
      </Button>
    </>
  )
}
