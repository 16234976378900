import { useEffect, useState } from 'react';
import { fetchPlaceJobs } from '../../requests/requests';
import Skeleton from '../utils/Skeleton';
import './employee-selection.scss';

export default function EmployeeSelection(props) {
    const [jobs, setJobs] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        let servicesIds = [];
        props.services.map((v) => servicesIds.push(v.id));
        fetchPlaceJobs(props.placeId, servicesIds)
            .then((res) => {
                setJobs(res.content);

                setIsFetching(false);
            })
            .catch(() => {
                setError(true);
                setIsFetching(false);
            });
    }, []);

    if (isFetching) {
        return <EmployeeSkeleton />;
    }

    if (!jobs || jobs.length === 0) {
        return <div>No se encontraron empleados brindando los servicios seleccionados</div>;
    }

    if (!error) {
        return (
            <div className='mt-5 pt-3'>
                {Object.keys(jobs).map((key) => {
                    return <Employee key={key} employee={jobs[key]} onSelected={props.onConfirm} />;
                })}
            </div>
        );
    }

    return 'se produjo un error, por favor reintentelo mas tarde';
}

function Employee(props) {
    const onClick = () => {
        setTimeout(() => {
            props.onSelected(props.employee);
        }, 100);
    };

    const usesCredits = () => {
        return Object.keys(props.employee.credits).length > 0;
    };

    return (
        <div className='employee mx-auto mt-2 p-2 row' onClick={onClick}>
            <div className='col-2 my-auto'>
                <img src={props.employee.job.service_provider.profile_pic} alt='employee'></img>
            </div>
            <div className='text-start ps-0 employeeName col my-auto'>
                {props.employee.job.service_provider.given_name}
            </div>
            {usesCredits() > 0 && <div className='col-2 credits my-auto me-3' />}
        </div>
    );
}

function EmployeeSkeleton() {
    return (
        <div className='employeeSkeletonContainer m-auto pt-4'>
            {[...Array(2)].map((e, i) => (
                <div className='employeeSkeleton m-auto mt-2 mx-auto p-2 ' key={i}>
                    <div className='employeeSkeletonImg'>
                        <Skeleton />
                    </div>
                    <div className='employeeSkeletonText'>
                        <Skeleton />
                    </div>
                </div>
            ))}
        </div>
    );
}
