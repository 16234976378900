import { Link } from 'react-router-dom';

export default function NotFound() {
    return (
        <div className='text-white mt-5'>
            <div className='h6 pt-5 text-center'>
                <pre>
                    {'  _  _    ___  _  _   '}
                    <br />
                    {' | || |  / _ \\| || |  '}
                    <br />
                    {' | || |_| | | | || |_ '}
                    <br />
                    {' |__   _| | | |__   _|'}
                    <br />
                    {'    | | | |_| |  | |  '}
                    <br />
                    {'    |_|  \\___/   |_|  '}
                    <br />
                    {'                      '}
                    <br />
                </pre>
            </div>
            <div className='h1 fw-bold mt-3'>Oops, página no encontrada</div>
            <div className='fw-light h5 px-5 mt-4'>
                <em> Buscamos hasta en lo más profundo, pero no hallamos nada...</em>
            </div>
            <Link to='/' id='btnMainPage' className='mx-auto row justify-content-center'>
                IR A LA PÁGINA PRINCIPAL
            </Link>
            <Link
                to='/calendar'
                id='btnViewApps'
                className='mx-auto row mt-3 text-center justify-content-center'
            >
                VER MIS TURNOS
            </Link>
        </div>
    );
}
