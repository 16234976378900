import React, { useContext } from 'react';
import { LoadingContext } from 'react-router-loading';
import './style.scss';
import { useEffect } from 'react';

export default function TermsConditions() {
    const loadingContext = useContext(LoadingContext);
    useEffect(() => {
        window.scrollTo(0, 0);
        loadingContext.done();
    }, []);

    return (
        <div className='legal card p-0 bg-secondary text-start pt-5 mt-5'>
            <div className='card-body'>
                <div className='container card-text'>
                    <div className='text-center fw-bold h3'>Términos y Condiciones Generales</div>

                    <section className='container'>
                        Este contrato describe los términos y condiciones generales (los "Términos y
                        Condiciones Generales") aplicables al uso de los servicios ofrecidos por Ya
                        tenés turno, ("los Servicios") dentro del sitio{' '}
                        <a href='https://www.yatenesturno.com.ar'>www.yatenesturno.com.ar</a>.
                        Cualquier persona (en adelante "Usuario" o en plural "Usuarios") que desee
                        acceder y/o usar el sitio o los Servicios podrá hacerlo sujetándose a los
                        Términos y Condiciones Generales respectivos, junto con todas las demás
                        políticas y principios que rigen Ya tenés turno y que son incorporados al
                        presente por referencia. CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y
                        CONDICIONES GENERALES, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y
                        VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS. El
                        Usuario debe leer, entender y aceptar todas las condiciones establecidas en
                        los Términos y Condiciones Generales y en las Políticas de Privacidad, así
                        como en los demás documentos incorporados a los mismos por referencia,
                        previo a su registración como Usuario de Ya tenés turno, optando de forma
                        expresa por recibir los mismos y toda otra información por medios digitales.
                    </section>

                    <section className='container'>
                        01 – Usuarios Capacitados <br />
                        Los Servicios sólo están disponibles para personas que tengan capacidad para
                        contratar. No podrán utilizar los servicios las personas que no tengan esa
                        capacidad, los menores de edad o Usuarios de Ya tenés turno que hayan sido
                        suspendidos temporalmente o inhabilitados definitivamente.
                    </section>

                    <section className='container'>
                        02 – Registro <br />
                        Es obligatorio completar el formulario de registración en todos sus campos
                        con datos válidos para poder utilizar los servicios que brinda Ya tenés
                        turno. El futuro Usuario deberá completarlo con su información personal de
                        manera exacta, precisa y verdadera ("Datos Personales") y asume el
                        compromiso de actualizar los Datos Personales conforme resulte necesario. El
                        Usuario presta expresa conformidad con que Ya tenés turno utilice diversos
                        medios para identificar sus datos personales, asumiendo el Usuario la
                        obligación de revisarlos y mantenerlos actualizados. Ya tenés turno NO se
                        responsabiliza por la certeza de los Datos Personales de los Usuarios. Los
                        Usuarios garantizan y responden, en cualquier caso, de la veracidad,
                        exactitud, vigencia y autenticidad de sus Datos Personales. El Usuario
                        accederá a su cuenta personal ("Cuenta") mediante el ingreso de su Apodo y
                        clave de seguridad personal elegida ("Clave de Seguridad"). El Usuario se
                        obliga a mantener la confidencialidad de su Clave de Seguridad. En el caso
                        que Ya tenés turno detecte distintas Cuentas que contengan datos
                        coincidentes o relacionados, podrá cancelar, suspender o inhabilitarlas. El
                        Usuario será responsable por todas las operaciones efectuadas en su Cuenta,
                        pues el acceso a la misma está restringido al ingreso y uso de su Clave de
                        Seguridad, de conocimiento exclusivo del Usuario. El Usuario se compromete a
                        notificar a Ya tenés turno en forma inmediata y por medio idóneo y
                        fehaciente, cualquier uso no autorizado de su Cuenta, así como el ingreso
                        por terceros no autorizados a la misma. Ya tenés turno se reserva el derecho
                        de rechazar cualquier solicitud de registración o de cancelar una
                        registración previamente aceptada, sin que esté obligado a comunicar o
                        exponer las razones de su decisión y sin que ello genere algún derecho a
                        indemnización o resarcimiento.
                    </section>

                    <section className='container'>
                        03 - Modificaciones en el Acuerdo <br />
                        Ya tenés turno podrá modificar los Términos y Condiciones Generales en
                        cualquier momento haciendo públicos en el Sitio los términos modificados.
                        Todos los términos modificados entrarán en vigor inmediatamente de su
                        publicación. Todo usuario que no esté de acuerdo con las modificaciones
                        efectuadas por Ya tenés turno podrá solicitar la baja de la cuenta. El uso
                        del sitio y/o sus servicios implica la aceptación de estos Términos y
                        Condiciones generales de uso de Ya tenés turno.
                    </section>

                    <section className='container'>
                        04 - Listado de Bienes y Servicios
                        <section className='container'>
                            4.1 Publicación de bienes y/o servicios <br />
                            El Usuario deberá ofrecer a la venta los bienes y promover los servicios
                            en las categorías y subcategorías apropiadas. Las publicaciones podrán
                            incluir textos descriptivos, gráficos, fotografías y otros contenidos y
                            condiciones pertinentes para la venta del bien o la promoción del
                            servicio, siempre que no violen ninguna disposición de este acuerdo o
                            demás políticas de Ya tenés turno. El bien o servicio ofrecido por el
                            Usuario vendedor debe ser exactamente descrito en cuanto a sus
                            condiciones y características relevantes. Se entiende y presume que,
                            mediante la inclusión del bien o servicio en Ya tenés turno, el Usuario
                            acepta que tiene la intención y el derecho de vender el bien u ofrecer
                            el servicio referido, o está facultado para ello por su titular.
                            Asimismo, el Usuario acepta que tiene disponible el bien publicado para
                            su entrega inmediata o para el plazo especificado en la publicación, así
                            como el servicio para su prestación en el plazo acordado. Se establece
                            que los precios de los bienes o servicios publicados deberán ser
                            expresados con IVA incluido cuando corresponda la aplicación del mismo,
                            y en moneda de curso legal. Ya tenés turno podrá remover cualquier
                            publicación cuyo precio no sea expresado de esta forma para evitar
                            confusiones o malos entendidos en cuanto al precio final del bien o
                            servicio.
                        </section>
                        <section className='container'>
                            4.2 Licencia sobre imágenes, fotografías, marcas e Información de
                            Producto
                            <br />
                            El usuario autoriza a Ya tenés turno y sus compañías asociadas a
                            utilizar, publicar, reproducir y/o adaptar las imágenes y fotografías
                            incluidas en sus publicaciones, su nombre comercial, marcas, frases
                            publicitarias, logos, diseños, dibujos, imágenes y todo otro signo
                            distintivo que identifique al Usuario y sus productos o servicios (la
                            "Marca") e información de sus productos o servicios ("Información de
                            Producto"). Será obligación del Usuario incluir en las publicaciones las
                            imágenes, fotografías y Marca, así como la Información de Producto, de
                            manera actualizada, incluyendo aquellas advertencias que sean requeridas
                            por la legislación aplicable para la venta o publicidad de los productos
                            y servicios. Conforme a lo anterior, Ya tenés turno podrá obtener las
                            imágenes, fotografías, Marca e Información de Producto directamente del
                            Usuario, terceros autorizados por éste, o a través del sitio web del
                            Usuario. En particular, el Usuario otorga a Ya tenés turno y a sus
                            sociedades relacionadas una autorización gratuita, irrevocable, no
                            exclusiva, internacional y sin límite temporal para usar, publicar,
                            reproducir y/o adaptar las imágenes, fotografías, la Marca y la
                            Información de Producto con el fin de ser usadas en todos los sitios y
                            aplicaciones de Ya tenés turno, redes sociales y/o en cualquier medio
                            masivo y no masivo de comunicación, incluyendo sin limitación,
                            plataformas y cualquier otro medio digital o físico que Ya tenés turno
                            considere oportuno o con aquellas otras plataformas o sitios de Internet
                            con los cuales Ya tenés turno haya realizado una alianza, para
                            identificar ofertas, clasificar productos, crear catálogos, realizar
                            acciones publicitarias y de marketing vinculadas a los servicios de Ya
                            tenés turno. El Usuario declara y garantiza que es titular o
                            licenciatario de los derechos necesarios sobre las imágenes, fotografías
                            contenidas en sus publicaciones, sobre las Marcas, así como sobre la
                            Información de Producto, y que cuenta con los derechos y facultades
                            necesarias para conceder la autorización detallada en esta cláusula,
                            siendo responsable exclusivo por cualquier infracción a derechos de
                            terceros o por las inconsistencias o inexactitud en la Información de
                            Producto. Ya tenés turno podrá eliminar la publicación de las imágenes y
                            fotografías, e incluso del bien o servicio, si interpretara, a su
                            exclusivo criterio, que la imagen no cumple con los presentes Términos y
                            Condiciones.
                        </section>
                    </section>

                    <section className='container'>
                        05 - Privacidad de la Información
                        <br />
                        Para utilizar los Servicios ofrecidos por Ya tenés turno, los Usuarios
                        deberán facilitar determinados datos de carácter personal. Su información
                        personal se procesa y almacena en servidores o medios magnéticos que
                        mantienen altos estándares de seguridad y protección tanto física como
                        tecnológica. Para mayor información sobre la privacidad de los Datos
                        Personales y casos en los que será revelada la información personal, se
                        pueden consultar nuestras
                        <a href='https://www.yatenesturno.com.ar/privacy-policy'>
                            Políticas de Privacidad
                        </a>
                        .
                    </section>

                    <section className='container'>
                        06 - Obligaciones de las partes
                        <section className='container'>
                            6.1 Obligaciones del comprador
                            <br />
                            El Comprador está obligado a intentar comunicarse con el vendedor y
                            completar la operación si ha realizado una oferta por un artículo salvo
                            que la operación esté prohibida por la ley o los Términos y Condiciones
                            Generales y demás políticas de Ya tenés turno, en cuyo caso no estará
                            obligado a concretar la operación. Al ofertar por un bien o contratar un
                            servicio, el Usuario acepta quedar obligado por las condiciones de venta
                            incluidas en la publicación, en la medida en que las mismas no infrinjan
                            las leyes o los Términos y Condiciones Generales y demás políticas de Ya
                            tenés turno. La oferta de compra de un bien o contratación de un
                            servicio es irrevocable salvo en circunstancias excepcionales, tales
                            como que el vendedor cambie sustancialmente la descripción del bien o
                            servicio después de realizada alguna oferta, que exista un claro error
                            tipográfico, o que no pueda verificar la identidad del vendedor.
                            Impuestos. Tal como lo establece la normativa fiscal vigente, el
                            comprador debe exigir factura o ticket al vendedor como comprobante de
                            la operación. El vendedor no estará obligado a emitir factura o ticket
                            sólo en el caso de tratarse de una persona física que efectúa ventas
                            ocasionalmente.
                        </section>
                        <section className='container'>
                            6.2. Obligaciones del vendedor <br />
                            El usuario vendedor debe tener capacidad legal para vender el bien o
                            servicio objeto de su oferta. Asimismo, debe cumplir con todas las
                            obligaciones regulatorias pertinentes y contar con los registros,
                            habilitaciones, permisos y/o autorizaciones exigidas por la normativa
                            aplicable para la venta de los bienes y servicios ofrecidos. Si el
                            usuario vendedor ha recibido una oferta queda obligado a intentar
                            comunicarse con el comprador y completar la operación. La cancelación de
                            una venta por parte del Usuario vendedor impactará en su reputación Dado
                            que Ya tenés turno es un punto de encuentro entre comprador y vendedor y
                            no participa de las operaciones que se realizan entre ellos, el vendedor
                            será responsable por todas las obligaciones y cargas impositivas que
                            correspondan por la venta de sus bienes y/o servicios, sin que pudiera
                            imputársele a Ya tenés turno algún tipo de responsabilidad por
                            incumplimientos en tal sentido. Impuestos. Como se menciona
                            anteriormente, Ya tenés turno sólo pone a disposición de los usuarios un
                            espacio virtual que les permite comunicarse mediante Internet para
                            encontrar una forma de vender o comprar artículos y/o servicios. Ya
                            tenés turno no tiene participación alguna en el proceso de negociación y
                            perfeccionamiento del contrato definitivo entre las partes. Por eso, Ya
                            tenés turno no es responsable por el efectivo cumplimiento de las
                            obligaciones fiscales o impositivas establecidas por la ley vigente.
                        </section>
                    </section>

                    <section className='container'>
                        07 - Violaciones del Sistema o Bases de Datos <br />
                        No está permitida ninguna acción o uso de dispositivo, software, u otro
                        medio tendiente a interferir tanto en las actividades y operatoria de Ya
                        tenés turno como en las ofertas, descripciones, cuentas o bases de datos de
                        Ya tenés turno. Cualquier intromisión, tentativa o actividad violatoria o
                        contraria a las leyes sobre derecho de propiedad intelectual y/o a las
                        prohibiciones estipuladas en este contrato harán pasible a su responsable de
                        las acciones legales pertinentes, y a las sanciones previstas por este
                        acuerdo, así como lo hará responsable de indemnizar los daños ocasionados.
                    </section>

                    <section className='container'>
                        08 – Sanciones y Suspensiones
                        <br />
                        Sin perjuicio de otras medidas, Ya tenés turno podrá advertir, suspender en
                        forma temporal o definitivamente la Cuenta de un Usuario o una publicación,
                        aplicar una sanción que impacte negativamente en la reputación de un
                        Usuario, iniciar las acciones que estime pertinentes y/o suspender la
                        prestación de sus Servicios si (a) se quebrantara alguna ley, o cualquiera
                        de las estipulaciones de los Términos y Condiciones Generales y demás
                        políticas de Ya tenés turno; (b) si incumpliera sus compromisos como
                        Usuario; (c) si se incurriera a criterio de Ya tenés turno en conductas o
                        actos dolosos o fraudulentos; (d) no pudiera verificarse la identidad del
                        Usuario o cualquier información proporcionada por el mismo fuere errónea;
                        (e) Ya tenés turno entendiera que las publicaciones u otras acciones pueden
                        ser causa de responsabilidad para el Usuario que las publicó, para Ya tenés
                        turno o para los demás Usuarios en general. En el caso de la suspensión de
                        un Usuario, sea temporal o definitiva, todos los artículos que tuviera
                        publicados serán removidos del sistema.
                    </section>

                    <section className='container'>
                        09 - Responsabilidad <br />
                        Ya tenés turno pone a disposición de los Usuarios un espacio virtual que les
                        permite ponerse en comunicación para ofrecer, vender y/o comprar bienes y
                        servicios Son los Usuarios Vendedores quienes utilizan Ya tenés turno para
                        publicar sus productos y servicios, y definir las condiciones de su oferta
                        (producto o servicio ofrecido, precio, descripción, fotos, calidad,
                        cantidad, integridad, etc.). En todos los casos, al ser los Usuarios
                        Vendedores los propietarios de los productos publicados y quienes los
                        ofrecen para su venta, serán responsables por la existencia, calidad,
                        cantidad, integridad o legitimidad de los productos o servicios que ofrecen,
                        y mantendrán indemnes a Ya tenés turno por las eventuales acciones o
                        reclamaciones que pudiesen surgir con motivo de una oferta o transacción.
                    </section>

                    <section className='container'>
                        10 - Alcance de los servicios de Ya tenés turno
                        <br />
                        Este acuerdo no crea ningún contrato de sociedad, de mandato, de franquicia,
                        o relación laboral entre Ya tenés turno y el usuario. El usuario reconoce y
                        acepta que Ya tenés turno no es parte en ninguna operación, ni tiene control
                        alguno sobre la calidad, seguridad o legalidad de los bienes y servicios
                        anunciados, la veracidad o exactitud de los anuncios, la capacidad de los
                        Usuarios para vender o comprar artículos. Ya tenés turno no puede asegurar
                        que un usuario completará una operación ni podrá verificar la identidad o
                        datos personales ingresados por los usuarios. Ya tenés turno no garantiza la
                        veracidad de la publicidad de terceros que aparezca en el sitio y no será
                        responsable por la correspondencia o contratos que el usuario celebre con
                        dichos terceros o con otros usuarios.
                    </section>

                    <section className='container'>
                        11 - Fallas e interrupciones en el sistema
                        <br />
                        Ya tenés turno no se responsabiliza por cualquier daño, perjuicio o pérdida
                        al usuario causados por fallas en el sistema, en el servidor o en Internet.
                        Ya tenés turno tampoco será responsable por cualquier virus que pudiera
                        infectar el equipo del usuario como consecuencia del acceso, uso o examen de
                        su sitio web o a raíz de cualquier transferencia de datos, archivos,
                        imágenes, textos, o audios contenidos en el mismo. Los usuarios NO podrán
                        imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud
                        de perjuicios resultantes de dificultades técnicas o fallas en los sistemas
                        o en Internet. Ya tenés turno no garantiza el acceso y uso continuado o
                        ininterrumpido de su sitio. El sistema puede eventualmente no estar
                        disponible debido a dificultades técnicas o fallas de Internet, o por
                        cualquier otra circunstancia ajena a Ya tenés turno; en tales casos se
                        procurará restablecerlo con la mayor celeridad posible sin que por ello
                        pueda imputársele algún tipo de responsabilidad. Ya tenés turno no será
                        responsable por ningún error u omisión contenidos en su sitio web.
                    </section>

                    <section className='container'>
                        12 - Tarifas. Facturación y contratación <br />
                        El usuario vendedor solo deberá pagar a Ya tenés turno un costo mensual,
                        sólo en caso de adquirir la versión Premium del servicio. Este será debitado
                        de forma automática y bajo el consentimiento del Usuario vendedor. Dado el
                        caso de no efectuar los pagos en forma correcta y como se contrató, Ya tenés
                        turno se reserva el derecho de tomar las medidas judiciales y
                        extrajudiciales que estime pertinentes para obtener el pago del monto
                        debido. Ya tenés turno se reserva el derecho de modificar, cambiar, agregar,
                        o eliminar las tarifas vigentes, en cualquier momento, lo cual será
                        notificado a los Usuarios, en la forma establecida en la Cláusula 3. Sin
                        embargo, Ya tenés turno podrá modificar temporalmente la Política de Tarifas
                        y las tarifas por sus servicios por razón de promociones, siendo efectivas
                        estas modificaciones cuando se haga pública la promoción o se realice el
                        anuncio. En caso de haberse facturado cargos que no hubiesen correspondido,
                        el usuario deberá comunicarse con nuestro equipo de Atención al Cliente para
                        resolver dicha cuestión.
                    </section>

                    <section className='container'>
                        13 - Propiedad intelectual - Licencia - Enlaces <br />
                        Ya tenés turno y/o sus sociedades controlantes, controladas, filiales o
                        subsidiarias se reservan todos los derechos, incluyendo los derechos de
                        propiedad intelectual e industrial, asociados con los servicios de Ya tenés
                        turno, sus sitios web, los contenidos de sus pantallas, programas, bases de
                        datos, redes, códigos, desarrollo, software, arquitectura, hardware,
                        contenidos, información, tecnología, fases de integración, funcionalidades,
                        dominios, archivos que permiten al Usuario acceder y crear su Cuenta,
                        herramientas de venta, marcas, patentes, derechos de autor, diseños y
                        modelos industriales, nombres comerciales, entre otros, y declara que están
                        protegidos por leyes nacionales e internacionales vigentes. En ningún caso
                        se entenderá que el Usuario tendrá algún tipo de derecho sobre los mismos
                        excepto para utilizar el servicio de Ya tenés turno conforme a lo previsto
                        en estos Términos y Condiciones Generales. El uso indebido o contrario a la
                        normativa vigente de los derechos de propiedad intelectual e industrial de
                        Ya tenés turno, así como su reproducción total o parcial, queda prohibido,
                        salvo autorización expresa y por escrito de Ya tenés turno. Los Usuarios
                        tampoco podrán comunicar que los productos o servicios que ofrecen son
                        patrocinados, promovidos, producidos, ofrecidos y/o vendidos por Ya tenés
                        turno y deberán abstenerse de realizar cualquier acto que pudiera causar un
                        daño, pérdida de reputación, o disminución del valor de los derechos de
                        propiedad intelectual e industrial de Ya tenés turno. El sitio puede
                        contener enlaces a sitios web de terceros. En virtud que Ya tenés turno no
                        tiene control sobre tales sitios, no será responsable por los contenidos,
                        materiales, acciones y/o servicios prestados por los mismos, ni por daños o
                        pérdidas ocasionadas por la utilización de éstos, causados directa o
                        indirectamente. La presencia de enlaces a otros sitios web no implica una
                        sociedad, relación, aprobación, respaldo de Ya tenés turno con dichos sitios
                        y sus contenidos.
                    </section>
                </div>
            </div>
        </div>
    );
}
