import './admin.scss';

import React from 'react';

import { Link } from 'react-router-dom';

export default function AdminView() {
    return (
        <div className='mx-auto' id='admin-container'>
            <div className=' fw-bold h3 text-uppercase mt-2'>Administración</div>
            <Link to='/admin/stats' className='admin-button row mx-auto'>
                Estadísticas
            </Link>
            <Link to='/admin/messages' className='admin-button row mx-auto'>
                Mensajes
            </Link>
            <Link to='/admin/manage' className='admin-button row mx-auto'>
                Gestionar
            </Link>
            <Link to='/admin/cron' className='admin-button row mx-auto'>
                Cron Task
            </Link>
        </div>
    );
}
