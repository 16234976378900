import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./layout-parent.scss";

export default function LayoutParent({ children }) {
  return (
    <Container fluid className="bg-dark full-height overflow-auto ">
      <Row className="row-responsive">{children}</Row>
    </Container>
  );
}
