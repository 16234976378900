import { parseAppointmentData } from "../../utils/dateParser"

export function getDate( date_format ){
    //Le pasamos un formato: "2022-04-02T16:00:00Z"
    //Y retornamos solo la fecha: "02/04/2022"

    const date = date_format.split('T')[0]

    const day = date.split('-')[2]
    const month = date.split('-')[1]
    const year = date.split('-')[0]

    return `${day}/${month}/${year}`
  }

export function getTime( date_format ){
    //Le pasamos un formato: "2022-04-02T16:00:00Z"
    //Y retornamos solo las horas y minutos: "16:00"
    const date = date_format.split('T')[1]

    const hours = date.split(':')[0]
    const minutes = date.split(':')[1]
 

    return `${hours}:${minutes}`
}

export function formatTime( time ){
    //Le pasamos un formato: "08:00:00"
    //Y retornamos solo las horas y minutos: "08:00"
    const hours = time.split(':')[0]
    const minutes = time.split(':')[1]
 

    return `${hours}:${minutes}`
}

export function formatDate( date, format ){
    //Le pasamos un formato: "2022-12-28"
    const day = date.split('-')[2]
    const month = date.split('-')[1]
    const year = date.split('-')[0]
    
    switch( format ){
        default: return `${day}/${month}/${year}`;
        case 'dd-mm-yyyy': return `${day}-${month}-${year}`;
    }
}

export function toIsoStringDate( date ){
    return String(date.toISOString().slice(0, 10))
}

export function stringFormatApp(appointmentData) {
    let date = parseAppointmentData(appointmentData);
    let dateStr = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
    return dateStr;
}

