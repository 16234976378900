import React from 'react';
import { Link } from 'react-router-dom';

import './manage.scss';

export default function ManageView() {
    return (
        <div id='manage-models-links'>
            <Link to='/admin/manage/users'>Usuarios</Link>
            <Link to='/admin/manage/places'>Lugares</Link>
        </div>
    );
}
