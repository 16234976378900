export default function objectsInArray( object ){
    let array = []
    for( const i in object ){
        array.push( object[i] )
    }
    return array
}

export function objectsInArrayServices( res ){
    let services = []
    for( const service in res ){
        services.push( {
            'name': service,
            'job_types': res[service]
        } )
    }

    return services
}