import { useEffect, useRef, useState } from 'react';

import './markees.scss';

export function VerticalMarkee({ text }) {
    const parentRef = useRef();
    const childRef = useRef();
    const [shouldScroll, setShouldScroll] = useState(false);

    useEffect(() => {
        setShouldScroll(overflows());
    }, [parentRef]);

    function overflows() {
        if (childRef.current !== null) {
            return childRef.current.clientHeight > parentRef.current.clientHeight;
        }
        return false;
    }

    function getLength() {
        if (childRef.current !== null && childRef.current.clientHeight > 110) {
            return 'length8';
        }
        return 'length5';
    }

    return (
        <div ref={parentRef} className='w-100 h-100'>
            {shouldScroll && (
                <div className='containerOverflow blurVertical'>
                    <div className={'sliderVertical ' + getLength()}>{text}</div>
                </div>
            )}
            {!shouldScroll && (
                <div ref={childRef} className='ellipsis'>
                    {text}
                </div>
            )}
        </div>
    );
}

export function HorizontalMarkee({ text }) {
    const parentRef = useRef();
    const childRef = useRef();
    const [shouldScroll, setShouldScroll] = useState(false);

    useEffect(() => {
        setShouldScroll(overflows());
    }, [parentRef]);

    function overflows() {
        if (childRef.current !== null) {
            return childRef.current.clientWidth < childRef.current.scrollWidth;
        }
        return false;
    }

    function getWidth() {
        // if (childRef.current !== null && childRef.current.clientWidth > 110) {
        //     return 'width8';
        // }
        return 'width5';
    }

    return (
        <div ref={parentRef} className='w-100 h-100 p-0'>
            {shouldScroll && (
                <div className='containerOverflow blurHorizontal'>
                    <div className='sliderHorizontal'>{text}</div>
                </div>
            )}
            {!shouldScroll && (
                <div ref={childRef} className='text-start'>
                    {text}
                </div>
            )}
        </div>
    );
}
