import PropTypes from "prop-types";
import React from "react";
import Daily from "./Daily";
import Header from "./Header";
import Monthly from "./Monthly";

import { formatEvents } from "./util/calendar";
import { calendarDetails } from "./util/calendarDetails";

import { withRouter } from "react-router-dom";

class Calendar extends React.PureComponent {
  constructor(props) {
    super(props);
    const currentDate = new Date();
    this.state = {
      day: currentDate.getDate(),
      month: currentDate.getMonth(),
      year: currentDate.getFullYear(),
    };
    this.onClickDay = this.onClickDay.bind(this);
    this.onClickPrev = this.onClickPrev.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
  }

  getDetails() {
    const { mode, year, month, day } = this.state;
    return { mode, year, month, day };
  }

  getDate() {
    const { year, month, day } = this.state;
    const date = new Date(year, month, day);
    return date;
  }

  returnDailyEvents() {
    const events = formatEvents(this.props.events);
    const date = this.getDate();

    return events[date.getTime()];
  }

  returnCalendar() {
    const events = formatEvents(this.props.events);

    if (this.props.propsMyCommerce) {
      if (this.props.propsMyCommerce.actionDay) {
        const year = this.props.propsMyCommerce.actionDay.split("-")[0];
        const month = parseInt(
          this.props.propsMyCommerce.actionDay.split("-")[1]
        );
        const day = parseInt(
          this.props.propsMyCommerce.actionDay.split("-")[2]
        );

        const convertMonth = month <= 9 ? `0${month}` : month;
        const convertDay = day > 9 ? day : `0${day}`;

        let date = new Date(`${year}-${convertMonth}-${convertDay}`);
        date.setDate(date.getDate() + 1);
        this.setState({
          day: date.getDate(),
          month: date.getMonth(),
          year: date.getFullYear(),
        });
        this.props.propsMyCommerce.setDaySelected(`${year}-${month}-${day}`);
      }
      return (
        <Monthly
          month={this.state.month}
          year={this.state.year}
          day={this.state.day}
          events={events}
          propsMyCommerce={this.props.propsMyCommerce}
          onClickDay={this.onClickDay}
          onClickPrev={this.onClickPrev}
          onClickNext={this.onClickNext}
        />
      );
    }

    return (
      <Monthly
        month={this.state.month}
        year={this.state.year}
        day={this.state.day}
        events={events}
        onClickDay={this.onClickDay}
        onClickPrev={this.onClickPrev}
        onClickNext={this.onClickNext}
      />
    );
  }

  returnDailyView() {
    const dailyEvents = this.returnDailyEvents();
    return (
      <Daily
        dailyEvents={dailyEvents}
        date={this.getDate()}
        onClickEvent={this.props.onClickEvent}
      />
    );
  }

  onClickDay(date) {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    this.setState(
      {
        day,
        month,
        year,
      },
      this.onChange
    );

    if (this.props.propsMyCommerce) {
      const convertMonth = month + 1 <= 9 ? `0${month + 1}` : month + 1;
      const convertDay = day > 9 ? day : `0${day}`;
      this.props.propsMyCommerce.setDaySelected(
        `${year}-${convertMonth}-${convertDay}`
      );
    }
  }

  onClickPrev() {
    const { year, month, day } = this.state;
    const details = calendarDetails(year, month, day);
    this.setState({ ...details.prev }, this.onChange);

    if (this.props.handlePrev) this.props.handlePrev();
  }

  onClickNext() {
    const { year, month, day } = this.state;
    const details = calendarDetails(year, month, day);
    this.setState({ ...details.next }, this.onChange);

    if (this.props.handleNext) this.props.handleNext();
  }

  onChange() {
    if (this.props.onChange) {
      this.props.onChange(this.getDetails());
    }
  }

  returnHeader() {
    const { year, month, day } = this.state;
    const props = {
      ...calendarDetails(year, month, day),
      onClickPrev: this.onClickPrev,
      onClickNext: this.onClickNext,
    };
    if (this.props.header) {
      let CustomHeader = this.props.header;
      return <CustomHeader {...props} />;
    }
    return <Header {...props} />;
  }

  render() {
    return (
      <div className="calendarWrapper justify-content-center row mx-auto">
        <div className="col" id="monthlyWrapper">
          {this.returnHeader()}
          {this.returnCalendar()}
        </div>

        {this.returnDailyView()}
      </div>
    );
  }
}

Calendar.propTypes = {
  modes: PropTypes.array,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  onClickEvent: PropTypes.func,
  header: PropTypes.func,
};

export default withRouter(Calendar);
