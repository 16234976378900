import React from 'react';
import classnames from 'classnames';
import { isPastEvent } from '../../../utils/EventUtils';
import './index.styles.scss';
import moment from 'moment';

export default function Daily({ date, dailyEvents, onClickEvent }) {
    const formatDate = () => {
        return `${date.getDate()}/${(date.getMonth() + 1) % 13}`;
    };

    const formatEvent = (event) => {
        let offset = new Date().getTimezoneOffset();
        let date;
        try {
            date = new Date(event.from.getTime() + offset * 60000);
        } catch (error) {
            let dateAux = moment(event.from);
            date = new Date(dateAux + offset * 60000);
        }

        let dateStr = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);

        return `${dateStr} ${event.title}`;
    };

    const returnEventView = (event) => {
        let classes = ['dailyEvent'];

        if (isPastEvent(event)) {
            classes.push('past');
        }

        return (
            <div
                key={event.id}
                className={classnames(classes)}
                onClick={() => {
                    onClickEvent(event);
                }}
            >
                {formatEvent(event)}
            </div>
        );
    };

    const hasEvents = () => {
        return dailyEvents && dailyEvents.length > 0;
    };

    if (!hasEvents()) {
        return null;
    }

    const getClassNames = () => {
        const classList = ['fw-bold', 'text-white', 'text-start', 'mb-5', 'pb-5', 'col'];

        return classnames(classList);
    };

    return (
        <div className={getClassNames()} id='dailyWrapper'>
            {`TURNOS DEL DÍA ${formatDate()}`}

            {dailyEvents &&
                dailyEvents.map((event) => {
                    return returnEventView(event);
                })}
        </div>
    );
}
