import React, { useEffect, useState } from 'react';
import { fetchGetShops } from '../../requests/requests';
import { GetUserPlaces } from '../utils/swr_callbacks';

export const PlaceContext = React.createContext({
    userPlaces: [],
    placeSelected: undefined,
    setValues: () => {},
});

export const PlaceContextProvider = (props) => {
    const setValues = ( place, places ) => {
        if( place && places )
            setState({ ...state, placeSelected: place, userPlaces: places });
        else if( place && !places)
            setState({ ...state, placeSelected: place });
        else if( !place && places)
            setState({ ...state, userPlaces: places });
            
    };


    const initState = {
        userPlaces: [],
        placeSelected: undefined,
        setValues,
    };
    
    const [state, setState] = useState(initState);
   
    return <PlaceContext.Provider value={state}>{props.children}</PlaceContext.Provider>;
};
