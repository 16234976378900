import { useState, useEffect } from "react";
import "./select_client_responsive.scss";

import SearchBar from "../SearchBar/search_bar";
import Loading from "../Loading/loading";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Collapse } from "react-bootstrap";

export default function SelectClientResponsive({ data }) {
  const [searched, setSearched] = useState([]);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    //Load clients in array
    setSearched(data.clients);
  }, [data.clients]);

  const onSelectClient = (client) => {
    if (data.clientSelected != client) {
      data.setClientSelected(client);
      setCollapsed(false);
    }
  };

  const dataSearchBar = {
    allClients: data.clients,
    setSearched,
  };

  const liSearchedClients = searched.map((client) => (
    <li
      key={client.id}
      onClick={() => onSelectClient(client)}
      className={`list-client cursor-pointer ${
        data.clientSelected == client ? "active" : ""
      }`}
    >
      <div className="circle"></div>
      {`${client.given_name} ${client.family_name}`}
    </li>
  ));

  return (
    <div className="bg-gray general-border-radius py-2 d-flex gap-2 flex-column overflow-hidden h-100">
      <div className="d-flex align-items-center">
        <span className="w-100" onClick={() => setCollapsed(true)}>
          <SearchBar
            data={dataSearchBar}
            placeholder="Busca un cliente"
            className="mx-2 p-2 general-border-radius flex-fill"
          />
        </span>
        <span>
          <MdOutlineKeyboardArrowDown
            size={35}
            aria-expanded={collapsed}
            onClick={() => setCollapsed(!collapsed)}
            className={`collapsed-clients ${collapsed ? "active" : ""}`}
          />
        </span>
      </div>

      <Collapse in={collapsed}>
        <div className="clients-table ">
          <ul>
            {data.isLoadingClients ? (
              <Loading className="overflow-hidden full-height" />
            ) : data.clients.length > 0 ? (
              searched.length > 0 ? (
                liSearchedClients
              ) : (
                <>No se encontró el cliente</>
              )
            ) : (
              <>No posee clientes</>
            )}
          </ul>
        </div>
      </Collapse>
    </div>
  );
}
