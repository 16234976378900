import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { LoadingContext } from 'react-router-loading';
import { fetchJobTypesWithCategory } from '../../../requests/requests';
import NotFound from '../../utils/NotFound';
import SearchBar from '../../utils/SearchBar';

import './jobtypesview.scss';

let categoryId;

export default function JobTypesView(props) {
    const [error, setError] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [category, setCategory] = useState(undefined);
    const [jobTypes, setJobTypes] = useState(undefined);
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        loadingContext.done();

        categoryId = props.match.params.cat_id;
        fetchJobTypesWithCategory(props.match.params.cat_id)
            .then((res) => {
                setCategory(res.content.category);
                setJobTypes(res.content.job_types);
                setError(false);
                setIsFetching(false);
            })
            .catch((err) => {
                setError(true);
                setIsFetching(false);
            });
    }, []);

    if (error) {
        return <NotFound />;
    }

    if (isFetching) {
        return <JobTypeSkeleton />;
    }

    return (
        <div className='mt-5 pt-5' id='jobtype-container'>
            <div className='h3 fw-bold text-uppercase mt-2'>{category}</div>
            {jobTypes.length > 0 ? (
                <SearchBar
                    hintText={'Buscar tipo trabajo'}
                    elements={jobTypes}
                    keyFunc={(el) => {
                        return el.type;
                    }}
                    elementComponent={JobType}
                />
            ) :
                    <div>No encontramos lugares dentro de esta categoría</div>
            }
        </div>
    );
}

function JobType({ element }) {
    const history = useHistory();

    const onJobTypeSelected = () => {
        history.push(`/places?job-type=${element.id}`);
    };

    return (
        <div className='jobtype' onClick={onJobTypeSelected}>
            {element.type}
        </div>
    );
}

function JobTypeSkeleton() {
    return <div>Loading ...</div>;
}
