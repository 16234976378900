import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useQuery from '../utils/useQuery';

import './searchbar.scss';

function stringIsEmpty(str) {
    return !str || str.trim() === '';
}

export default function SearchBar({ elements, keyFunc, elementComponent: Component, hintText }) {
    const [filterText, setFilterText] = useState(undefined);
    const history = useHistory();
    const query = useQuery();

    useEffect(() => {
        if (!stringIsEmpty(query.get('name'))) {
            setFilterText(query.get('name'));
        }
    }, []);

    const onFilterChange = (newValue) => {
        setFilterText(newValue);

        if (!stringIsEmpty(newValue)) {
            history.replace({
                pathname: window.location.pathname,
                search: `?name=${newValue}`,
            });
        } else {
            history.replace({
                pathname: window.location.pathname,
                search: ``,
            });
        }
    };

    const hasFilter = () => {
        return !stringIsEmpty(filterText);
    };

    const matches = (el) => {
        let elementKey = keyFunc(el);
        let normalizedElementKey = elementKey.normalize('NFD').replace(/\p{Diacritic}/gu, '');

        return (
            elementKey.toLowerCase().includes(filterText.toLowerCase()) ||
            normalizedElementKey.toLowerCase().includes(filterText.toLowerCase())
        );
    };

    return (
        <div>
            <div className='form-group mx-auto' id='searchBar'>
                <span className='fa fa-search form-control-feedback' />
                <input
                    type='text'
                    className='form-control'
                    placeholder={hintText}
                    value={filterText}
                    onFocus={(e) => (e.target.placeholder = '')}
                    onBlur={(e) => (e.target.placeholder = hintText)}
                    onChange={(e) => onFilterChange(e.target.value)}
                />
            </div>

            <div className='mx-auto mt-4'>
                {elements.map((el, i) => {
                    if (!hasFilter() || matches(el)) {
                        return <Component key={i} element={el} />;
                    }
                    return null;
                })}
            </div>
        </div>
    );
}
