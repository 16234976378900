import "./App.scss"
import { BrowserRouter, Link, Redirect } from "react-router-dom"
import { Switch, Route } from "react-router-loading"

import HomeView from "./components/home/HomeView"
import firebase from "firebase"
import CalendarView from "./components/calendar/CalendarView"
import NotificationMethod from "./components/notification_method/NotificationMethod"
import { topbar } from "react-router-loading"
import BookingMainPage from "./components/booking/BookingMainPage"
import PrivacyPolicy from "./components/legal/PrivacyPolicy"
import TermsConditions from "./components/legal/TermsConditions"
import { useState } from "react"
import { useEffect } from "react"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

import Join from "./components/home/join/Join"
import ContactUs from "./components/home/contactus/ContactUs"

import PlacesView from "./components/home/placesview/PlacesView"

import { RedirectIfNotLoggedIn } from "./components/auth/AuthUtils"
import LoginPage from "./components/auth/LoginPage"
import { useGoogleAuth, login, useLogin } from "./components/auth/authHooks"
import NavMain from "./components/nav/NavMain"
import JobTypesView from "./components/home/jobtypesview/JobTypesView"

import NotFound from "./components/utils/NotFound"
import AdminView from "./components/admin/AdminView"
import StatsView from "./components/admin/stats/StatsView"
import CronTaskView from "./components/admin/crontasks/CronTaskView"
import ManageView from "./components/admin/manage/ManageView"
import AdminRoute from "./components/admin/AdminRoute"
import MessagesView from "./components/admin/messages/MessagesView"
import UsersView from "./components/admin/manage/users/UsersView"
import UserDetailsView from "./components/admin/manage/users/UserDetailsView"
import Logo from "./components/nav/Logo"
import logoBlack from "./logo_black.svg"
import PaymentSuccessful from "./components/home/payment/PaymentSuccessful"
import ManagePlaces from "./components/admin/manage/places/ManagePlaces"
import LogRocket from "logrocket"
import CreditsView from "./components/credits/CreditsView"
import MyCommerce from "./components/my_commerce/MyCommerce"
import { signInBackend } from "./components/auth/GoogleAuth"
import { AuthContext } from "./components/auth/AuthContextProvider"
import { PlaceContext } from "./components/my_commerce/PlaceContextProvider"
import { useContext } from "react"

import { NotificationContainer } from "react-notifications"
import DeleteAccount from "./components/delete_account/DeleteAccount"

var firebaseConfig = {
  apiKey: "AIzaSyDNsLCSuTFZc07teEb4UJsayuxzTF5Ju8o",
  authDomain: "yatenesturno.firebaseapp.com",
  databaseURL: "https://yatenesturno.firebaseio.com",
  projectId: "yatenesturno",
  storageBucket: "gs://yatenesturno.appspot.com",
  messagingSenderId: "485729116640",
  appId: "1:485729116640:web:8a4dda0d7f56a665f20b85",
  measurementId: "G-N6QEJ8QCGW",
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
} else {
  firebase.app()
}

topbar.config({
  autoRun: true,
  barThickness: 2,
  barColors: {
    1: "#ff8672",
  },
  shadowBlur: 0,
  className: "topbar",
})

LogRocket.init("nwurqc/yatenesturno")

export default function App() {
  const [waitingWorker, setWaitingWorker] = useState(undefined)
  const [newVersionAvailable, setNewVersionAvailable] = useState(false)
  const [showNav, setShowNav] = useState(true)

  const [waitingLogin, setWaitingLogin] = useState(false)

  const authContext = useContext(AuthContext)
  const placeContext = useContext(PlaceContext)
  const credential = localStorage.getItem("token")

  useEffect(() => {
    if (credential) {
      setWaitingLogin(true)
      signInBackend(credential, authContext, placeContext, setWaitingLogin)
    }
  }, [])

  const onServiceWorkerUpdate = (registration) => {
    setWaitingWorker(registration && registration.waiting)
    setNewVersionAvailable(true)
  }

  const updateServiceWorker = () => {
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" })
    setNewVersionAvailable(false)

    window.location.reload()
  }

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate })
  }, [])

  const inMainPage = () => {
    return window.location.pathname === "/"
  }
  const inPaymentSuccessfulPage = () => {
    return window.location.pathname === "/payment-successful"
  }

  const shouldShowNav = () => {
    return (showNav || !inMainPage()) && !inPaymentSuccessfulPage()
  }

  if (waitingLogin) {
    return <WaitingScreen />
  }

  return (
    <div className="App">
      <NotificationContainer />
      <BrowserRouter>
        <NavMain show={shouldShowNav()} />
        <div className="mainContainer">
          <Switch loadingScreen={LoadingScreen}>
            <Route exact path="/">
              <HomeView showNav={setShowNav} />
            </Route>

            <Route
              exact
              path="/categories/:cat_id/job-types"
              component={JobTypesView}
              loading
            />

            <Route exact path="/places" component={PlacesView} loading />

            <Route exact path="/my-commerce" loading>
              <RedirectIfNotLoggedIn redirectLogin="/my-commerce">
                <MyCommerce />
              </RedirectIfNotLoggedIn>
            </Route>

            <Route exact path="/join" component={Join} loading />

            <Route exact path="/contact-us" component={ContactUs} loading />

            <Route
              exact
              path="/terms-conditions"
              component={TermsConditions}
              loading
            />

            <Route
              exact
              path="/privacy-policy"
              component={PrivacyPolicy}
              loading
            />

            <Route exact path="/login" component={LoginPage} />

            <Route
              exact
              path="/place/:id"
              component={BookingMainPage}
              loading
            />

            <Route exact path="/calendar" loading>
              <RedirectIfNotLoggedIn>
                <CalendarView />
              </RedirectIfNotLoggedIn>
            </Route>

            <Route exact path="/credits" loading>
              <RedirectIfNotLoggedIn>
                <CreditsView />
              </RedirectIfNotLoggedIn>
            </Route>

            <Route exact path="/delete-account" loading >
              <RedirectIfNotLoggedIn redirectLogin="/delete-account">
                <DeleteAccount />
              </RedirectIfNotLoggedIn>
            </Route>

            <Route exact path="/notification-method" loading>
              <RedirectIfNotLoggedIn>
                <NotificationMethod />
              </RedirectIfNotLoggedIn>
            </Route>

            <Route exact path="/admin" loading>
              <AdminRoute>
                <AdminView />
              </AdminRoute>
            </Route>

            <Route exact path="/admin/stats" loading>
              <AdminRoute>
                <StatsView />
              </AdminRoute>
            </Route>

            <Route exact path="/admin/messages" loading>
              <AdminRoute>
                <MessagesView />
              </AdminRoute>
            </Route>

            <Route exact path="/admin/manage" loading>
              <AdminRoute>
                <ManageView />
              </AdminRoute>
            </Route>

            <Route exact path="/admin/manage/users" loading>
              <AdminRoute>
                <UsersView />
              </AdminRoute>
            </Route>
            <Route exact path="/admin/manage/places" loading>
              <AdminRoute>
                <ManagePlaces />
              </AdminRoute>
            </Route>

            <Route exact path="/admin/manage/users/:user_id">
              <AdminRoute>
                <UserDetailsView />
              </AdminRoute>
            </Route>

            <Route exact path="/admin/cron" loading>
              <AdminRoute>
                <CronTaskView />
              </AdminRoute>
            </Route>

            <Route
              exact
              path="/payment-successful"
              component={PaymentSuccessful}
              loading
            />

            {/* Compatibility */}
            <Route path="/web/place/" component={RedirectOldUrl} />

            <Route path="/*" component={NotFound} />
          </Switch>
        </div>

        {!window.location.pathname.includes("my-commerce") && <Footer />}
      </BrowserRouter>

      <UpdateSnackBar
        show={newVersionAvailable}
        content="Nueva actualización disponible"
        actionName="Refrescar"
        onClick={updateServiceWorker}
      />
    </div>
  )
}

function WaitingScreen() {
  return (
    <div id="waitingScreen">
      <div id="waitingScreenLogo">
        <div id="logoBlackContainer">
          <img src={logoBlack} alt="logo" />
        </div>
        <div id="logoContainer">
          <Logo />
        </div>
      </div>
    </div>
  )
}

function UpdateSnackBar({ show, content, actionName, onClick }) {
  if (!show) {
    return null
  }

  return (
    <div className="snackbarWrapper px-3">
      <div className="snackbar row m-0 p-2 mx-auto">
        <div className="col">{content}</div>
        <div className="col-5 text-end my-auto">
          <button onClick={onClick}>{actionName}</button>
        </div>
      </div>
    </div>
  )
}

function LoadingScreen() {
  return <div className="w-100 h-100 bg-secondary" />
}

function Footer() {
  return (
    <footer className="text-start row p-0 mx-auto" id="footer">
      <div className="mt-4 col seccion">
        <div className="h6 fw-bold text-white">Links útiles</div>
        <div className="row">
          <div id="iconPrivacy" className="col-1 ms-3 icon my-auto" />
          <Link to="/privacy-policy" className="ps-1 col text-white text-start">
            Política de Privacidad
          </Link>{" "}
          <br />
        </div>
        <div className="row mt-1">
          <div id="iconTerms" className="col-1 ms-3  icon my-auto" />
          <Link
            to="/terms-conditions"
            className="ps-1 col text-white text-start"
          >
            Términos y condiciones
          </Link>
        </div>
      </div>
      <br />
      <div className="mt-4 col seccion">
        <div className="h6 fw-bold text-white">Contacto</div>
        <div className="row">
          <div id="iconCode" className="col-1 ms-3 iconCode icon my-auto" />
          <a
            className="ps-1 col text-white text-start"
            href="mailto: eze.gimenez.98@gmail.com"
          >
            Ezequiel Giménez
          </a>
        </div>
      </div>
      <div className="container text-center mt-5 pb-2">
        Copyright © 2021 Ya Tenés Turno
      </div>
    </footer>
  )
}

function RedirectOldUrl() {
  if (window.location.search && window.location.search.includes("?id=")) {
    let params = new URL(document.location).searchParams
    let id = params.get("id")

    return <Redirect to={`/place/${id}`} />
  }
  return <NotFound />
}
