import React from 'react'
import './item_accordion.scss';

import Accordion from 'react-bootstrap/Accordion';


export default function ItemAccordion({label, children, active, className, bodyHeight}) {
  const isActive = active ? '0':''
  return (
    <Accordion defaultActiveKey={isActive} className={className}>
      <Accordion.Item bsPrefix="custom-item" eventKey="0">
        <Accordion.Header bsPrefix="custom-head">{label}</Accordion.Header>
        <Accordion.Body bsPrefix={`custom-body ${bodyHeight}`}  >
         {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

