import React from "react"

import logo from "../../logo.svg"
import "./login.scss"

import googleLogo from "../../images/google.svg"
import { useState } from "react"
import { useEffect } from "react"
import { useIsLoggedIn, useIsNewUser, useLogin } from "./authHooks"
import { useHistory } from "react-router-dom"
import NewUser from "../auth/NewUser"
import { Button, Modal } from "react-bootstrap"
import { useGoogleLogin } from "@react-oauth/google"
import { signInUser } from "../../requests/requests"
import { hasGrantedAnyScopeGoogle } from "@react-oauth/google"
import { GoogleAuth } from "./GoogleAuth"

export default function LoginPage({ callback }) {
  const [errorLoggingIn, setErrorLoggingIn] = useState(false)

  const isNewUser = useIsNewUser()
  const isLoggedIn = useIsLoggedIn()
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (isNewUser) {
    return <NewUser />
  } else if (isLoggedIn) {
    if (callback) {
      callback()
    } else {
      const searchRedirection = window.location.search
      const pathPush = searchRedirection?.slice(10)

      setTimeout(() => {
        history.push(pathPush)
      })
    }
  }

  return (
    <div>
      <CookiesDisabled
        show={errorLoggingIn}
        onHide={() => setErrorLoggingIn(false)}
      />
      <div className="text-center" id="loginContainer">
        <img className="row m-auto" src={logo} id="mainPageLogo" alt="logo" />
        <div className="position-absolute btn-google-login">
          <GoogleAuth />
        </div>
      </div>
    </div>
  )
}

function CookiesDisabled(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="text-dark"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Problemas al iniciar sesión
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="h6 fw-bold">Requisitos</div>
        <p>
          Si estás teniendo problemas para iniciar sesión en ¿Ya tenés turno?,
          te informamos que actualmente necesitamos de{" "}
          <i>cookies de terceros</i> para hacerlo.
        </p>
        <p>
          Desafortunadamente, nosotros no podemos habilitarlas de tu parte. Pero
          podés seguir los siguientes pasos para hacerlo
        </p>
        <p id="cookies-mobile" className="cookies">
          En la app de Chrome
          <ol>
            <li>
              En tu teléfono o tablet Android, abre la app de Chrome{" "}
              <img src="https://lh3.googleusercontent.com/-20NuyB0WC36P5OvH-HnVwgMQlIRx47n0At3ZLRZuU2UIuXpsDZVhrsFJMW5DQkQVQU=w36-h36" />{" "}
            </li>
            <li>
              En la esquina superior derecha, presiona Más{" "}
              <img src="https://lh3.googleusercontent.com/E2q6Vj9j60Dw0Z6NZFEx5vSB9yoZJp7C8suuvQXVA_2weMCXstGD7JEvNrzX3wuQrPtL=w36-h36" />{" "}
              <img src="https://lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw=w36-h36" />{" "}
              <b>Configuración</b>.
            </li>
            <li>
              Presiona <b>Configuración del sitio</b>{" "}
              <img src="https://lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw=w36-h36" />{" "}
              <b>Cookies</b>.
            </li>
            <li>Activá la opción permitir cookies.</li>
          </ol>
        </p>
        <p id="cookies-desktop" className="cookies">
          En Chrome
          <ol>
            <li>En la computadora, abre Chrome.</li>
            <li>
              En la esquina superior derecha, haz clic en{" "}
              <img src="https://lh3.googleusercontent.com/E2q6Vj9j60Dw0Z6NZFEx5vSB9yoZJp7C8suuvQXVA_2weMCXstGD7JEvNrzX3wuQrPtL=w36-h36" />{" "}
              <img src="https://lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw=w36-h36" />{" "}
              <b>Configuración</b>.
            </li>
            <li>
              En "Privacidad y seguridad", haz clic en{" "}
              <b>Configuración del sitio</b>.
            </li>
            <li>
              Haz clic en <b>Cookies</b>.
            </li>
            <li>Activar las cookies: Junto a "Bloqueada", activá la opción.</li>
          </ol>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="text-white">
          Entendido
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export function LoginButton({ disabled, onClick }) {
  return (
    <button
      id="googleBtn"
      className="row mx-auto"
      onClick={onClick}
      disabled={disabled}
    >
      <div className="col-4 me-0 pe-0 my-auto text-end">
        <img className="googleLogo " src={googleLogo} alt="google Logo" />
      </div>
      <div className="col-5 text-center my-auto">Iniciar Sesión</div>
      <div className="col-2 my-auto">
        {disabled && (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </div>
    </button>
  )
}
