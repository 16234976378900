import { useContext, useEffect, useState } from "react"
import { PlaceContext } from "../../PlaceContextProvider"

import "./change_employed.scss"

import Dropdown from "react-bootstrap/Dropdown"
import CircleImage from "../CircleImage/circle_image"
import Skeleton from "../../../utils/Skeleton"

//Request
import { GetEmployeds } from "../../../utils/swr_callbacks"
import { FaRegUserCircle } from "react-icons/fa"

export default function ChangeEmployed({ imgSize, data }) {
  const { placeEmployeds, employedSelected, setEmployedSelected } = data
  const width = imgSize != "lg" ? "w-100" : "w-90"

  const handleEmployed = (employed) => {
    if (employedSelected.id != employed.id) setEmployedSelected(employed)
  }
  const dropdownItems = placeEmployeds?.map((employed) => (
    <Dropdown.Item key={employed?.id} onClick={() => handleEmployed(employed)}>
      {employed?.given_name}
    </Dropdown.Item>
  ))

  return (
    <div className="d-flex align-items-center position-relative">
      <Dropdown className="flex-grow-1">
        <Dropdown.Toggle
          className={`btn-primary ${width} general-border-radius`}
        >
          {!employedSelected ? (
            <>Cargando empleados....</>
          ) : (
            employedSelected.given_name
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100 general-border-radius">
          {dropdownItems}
        </Dropdown.Menu>
      </Dropdown>
      <div className="position-absolute end-0">
        {!employedSelected ? (
          <Skeleton />
        ) : (
          employedSelected.profile_pic && (
            <CircleImage size={imgSize} src={employedSelected.profile_pic} />
          )
        )}
      </div>
    </div>
  )
}
