import React, { useContext, useEffect } from 'react';

import './join.scss';

import android from '../../../images/main/join/android.svg';
import logo from '../../../logo.svg';
import { LoadingContext } from 'react-router-loading';

export default function Join() {
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        loadingContext.done();
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className='page-section' id='joinContainer'>
            <div className='container'>
                <div className='text-center'>
                    <div className='h2 section-heading text-uppercase fw-bold text-dark'>
                        Adherí tu local
                    </div>
                    <p className='h5 mx-3'>
                        Para adherir tu local segui los dos siguientes pasos, es muy sencillo!
                    </p>
                </div>
                <ul className='timeline mt-5'>
                    <li>
                        <div className='timeline-image'>
                            <img className='img-fluid m-2' src={android} alt='' />
                        </div>
                        <div className='timeline-panel'>
                            <div className='timeline-heading'>
                                <h4 className='subheading'>Descargá la aplicación</h4>
                            </div>
                            <div className='timeline-body'>
                                <p className=''>
                                    Ingresá{' '}
                                    <a
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href='https://play.google.com/store/apps/details?id=com.adminturnos'
                                    >
                                        aquí
                                    </a>{' '}
                                    para descargar la aplicación en PlayStore. Próximamente en IOS.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li className='timeline-inverted'>
                        <div className='timeline-image bg-dark'>
                            <img className='img-fluid' src={logo} alt='' id='imgUs' />
                        </div>
                        <div className='timeline-panel'>
                            <div className='timeline-heading'>
                                <h4 className='subheading'>Ingresá y registrá tu lugar</h4>
                            </div>
                            <div className='timeline-body'>
                                <p className=''>
                                    Una vez descargada la aplicación, ingresá en "Registrá tu lugar"
                                    y seguí las instrucciones.
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    );
}
