import { useEffect, useState } from 'react';

/**
 * Display a message showing the user credits status of the booking process
 */
export default function CreditsWarning({
    selectedServices,
    selectedAppsCount,
    setReachedMinCredits,
}) {
    const [showCredits, setShowCredits] = useState(false);
    const [minService, setMinService] = useState(undefined);

    useEffect(() => {
        if (selectedServices) {
            setMinService(getMinService());
            for (let i = 0; i < selectedServices.length; i++) {
                if (selectedServices[i].credits >= 0) {
                    setShowCredits(true);
                    return;
                }
            }
        }

        setShowCredits(false);
    }, [selectedServices]);

    useEffect(() => {
        let minServiceAux = minService;
        if (!minServiceAux) {
            minServiceAux = getMinService();
        }

        setReachedMinCredits(reachedMinimum() && !minServiceAux.can_book_without_credits);
    }, [selectedAppsCount]);

    /**
     * Check whether the user has any credits left by substracting the amount of appointments selected
     * times how much each appointment costs to the total ammount
     * @returns true if the user has credits lefts, false otherwise
     */
    const getCreditsLeft = (minService) => {
        return minService.credits - selectedAppsCount * getAppointmentCreditCost();
    };

    /**
     * Get how much does an appointment cost to book, credit-wise
     */
    const getAppointmentCreditCost = () => {
        let serviceWCreditsCount = 0;

        for (let i = 0; i < selectedServices.length; i++) {
            if (selectedServices[i].credits >= 0) {
                serviceWCreditsCount++;
            }
        }
        return serviceWCreditsCount;
    };

    /**
     * Get the service with the least amount of credits
     * (since it establishes the limit of how many appointmetns can be booked)
     * @returns the service that has the least amount of credits left among the selected services
     */
    function getMinService() {
        let currMin = 99999;
        let currMinService = undefined;
        let booksWithoutCredits = true;
        for (let i = 0; i < selectedServices.length; i++) {
            if (selectedServices[i].credits >= 0) {
                if (selectedServices[i].credits < currMin) {
                    currMin = selectedServices[i].credits;
                    currMinService = selectedServices[i];
                } else if (selectedServices[i].credits === currMin) {
                    if (!selectedServices[i].can_book_without_credits && booksWithoutCredits) {
                        currMin = selectedServices[i].credits;
                        currMinService = selectedServices[i];
                        booksWithoutCredits = false;
                    }
                }
            }
        }

        return currMinService;
    }

    /**
     * Check whether the user can continue booking or not
     * @returns true if yes, false otherwise
     */
    const reachedMinimum = () => {
        let minServiceAux = minService;
        if (!minServiceAux) {
            minServiceAux = getMinService();
        }

        if (minServiceAux) {
            return getCreditsLeft(minServiceAux) <= getAppointmentCreditCost() - 1;
        }
        return false;
    };

    const getPlural = () => {
        return getCreditsLeft(minService) > 1 ? 's' : '';
    };

    /**
     * No need to show credits warning since non of the selcted services
     * is proivided using credits
     */
    if (!selectedServices || !showCredits) {
        return null;
    }

    return (
        <div id='credits-warning' className='mb-3 mx-auto'>
            {reachedMinimum() ? (
                minService.can_book_without_credits ? (
                    <>
                        <span className='icon primary' />
                        <span className='text-light'>
                            No tienes créditos disponibles <br /> pero puedes seguir reservando
                        </span>
                    </>
                ) : selectedAppsCount > 0 ? (
                    <>
                        <span className='icon danger' />
                        <span className='reached-minimum'>No tienes más créditos disponibles</span>
                    </>
                ) : (
                    <>
                        <span className='icon danger' />
                        <span className='reached-minimum'>
                            Por favor, solicite al lugar una carga de créditos para poder reservar
                            este servicio
                        </span>
                    </>
                )
            ) : (
                <>
                    <span className='icon success' />
                    <span className='text-light'>
                        {`Tienes ${getCreditsLeft(
                            minService,
                        )} crédito${getPlural()} disponible${getPlural()}.`}
                    </span>
                </>
            )}
        </div>
    );
}
